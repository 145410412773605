import React, { useState, useEffect } from 'react';
import ScrollableSection from '../Dashboard/Dashbord';
import Nav from '../../../components/Nav';

import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineZoomIn } from 'react-icons/ai';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { message, Modal } from 'antd';
import { ToastContainer, toast } from 'react-toastify';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import { getEvent, delEventById, addEvent } from '../ApiEndpoint';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
	name: yup.string().required(),
	providerContact: yup.string().required(),
	serviceProvider: yup.string().required(),
	validityDate: yup.string().required(),
	eventDetails: yup.string().required(),
	status: yup.string().required(),
	image: yup.string().required(),
});

function Event() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(schema),
	});

	const [checkValue, setCheckValue] = useState(false);
	const [newEvent, setnewEvent] = useState([]);
	const [singleStock, setSingleStock] = useState({});
	const [updateEvent, setupdateEvent] = useState();
	// const [image, setImage] = useState('');

	// const handleImageChange = (e) => {
	// 	setImage(e.target.files[0]);
	// };

	const updateData = (values) => {};

	const sendData = (values) => {};

	const onDeleteStudent = (id) => {
		console.log('==========================', id);
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delEventById}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('seller deleted successful');
						toast.success('deleted successful');
					} else {
						console.log('seller deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	const onSubmit = async (data) => {
		try {
			const response = await fetch(addEvent, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added packege for QC succesfull ');
				const eventData = await response.json();
				console.log('event added', eventData);
				toast.success('Event Added');
			} else {
				console.error(' failed');
				toast.error(' Addition failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}

		// console.log('data', data);
		// reset();
	};
	useEffect(() => {
		const fetchData = async () => {
			const result = await fetch(getEvent);
			const expoData = await result.json();

			console.log('event', expoData);
			// setSellerPackage(sellerPackage);
			setnewEvent(expoData);
		};

		fetchData();
	}, []);

	const className = 'offcanvas offcanvas-end offcanvas-backdrop.show';
	const changeClassName =
		'offcanvas offcanvas-end d-none  offcanvas-backdrop.show2';

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<ToastContainer />
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage Event</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'></div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Service Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form onSubmit={handleSubmit(onSubmit)}>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='name'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Title'
																					{...register('name')}
																				/>
																				{errors.name && (
																					<p className='text-danger'>
																						{errors.name.message}
																					</p>
																				)}
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Service Provider
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='featured'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																					{...register('serviceProvider')}
																				/>
																				{errors.featured && (
																					<p className='text-danger'>
																						{errors.featured.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Provider Contact
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					name='providerContact'
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Provider Contact'
																					{...register('providerContact')}
																				/>
																				{errors.providerContact && (
																					<p className='text-danger'>
																						{errors.providerContact.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Select Validity Date
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='date'
																					name='SelectDate'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																					{...register('validityDate')}
																				/>
																				{errors.validityDate && (
																					<p className='text-danger'>
																						{errors.validityDate.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Events Details
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<textarea
																					name='eventsDetail'
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Seller detail'
																					{...register('eventDetails')}
																				></textarea>
																				{errors.eventDetails && (
																					<p className='text-danger'>
																						{errors.eventDetails.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Image
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								type='file'
																								name='image'
																								{...register('image')}
																							/>

																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					aria-label='Default select example'
																					name='status'
																					{...register('status')}
																				>
																					<option value='Active'>Active</option>
																					<option value='Inactive'>
																						Inactive
																					</option>
																				</select>

																				{errors.status && (
																					<p className='text-danger'>
																						{errors.status.message}
																					</p>
																				)}
																			</div>
																		</div>
																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																							aria-label='Close'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add Event
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Add Event
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRightupdate'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Service Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			updateData(e.target);
																		}}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='name'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Title'
																					defaultValue={singleStock?.name}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Service Provider
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='featured'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																					defaultValue={singleStock?.featured}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Provider Contact
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					name='providerContact'
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Provider Contact'
																					defaultValue={
																						singleStock?.providerContact
																					}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Select Validity Date
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='date'
																					name='SelectDate'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																					defaultValue={singleStock?.SelectDate}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Service Details
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<textarea
																					name='eventsDetail'
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Seller detail'
																					defaultValue={
																						singleStock?.eventsDetail
																					}
																				></textarea>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Image
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								accept='image/*'
																								multiple=''
																								type='file'
																								name='image'
																								defaultValue={
																									singleStock?.image
																								}
																							/>
																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					aria-label='Default select example'
																					name='status'
																				>
																					<option value='Active'>Active</option>
																					<option value='Inactive'>
																						Inactive
																					</option>
																				</select>
																			</div>
																		</div>
																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																							aria-label='Close'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Update Event
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Update Event
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>
												</div>

												{/* Modal-Del */}
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Events</span>
												</button>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>
														<input
															className='form-check-input'
															type='checkbox'
															value=''
															id='flexCheckDefault'
														/>
													</th>
													<th>#ID</th>
													<th>Image</th>
													<th>Name</th>
													<th>Service Provider</th>

													<th>Date</th>

													<th>Detail</th>

													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{newEvent?.map((item, index) => {
													return (
														<>
															<tr>
																<td>
																	{' '}
																	<input
																		className='form-check-input'
																		type='checkbox'
																		value=''
																		id='flexCheckDefault'
																	/>
																</td>
																<td>{index + 1}</td>
																<td>
																	<div className='prod-img'>
																		<img
																			src={`${ApiUrl}/${item?.image}`}
																			alt=''
																		/>
																	</div>
																</td>
																<td>{item?.name}</td>
																<td>{item?.serviceProvider}</td>

																<td>{item?.validityDate}</td>
																<td>{item?.eventDetails}</td>

																<td>{item?.status}</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setupdateEvent(item?._id);

																				axios
																					.get(
																						`${ApiUrl}/events/getById?id=` +
																							item?._id
																					)
																					.then((res) => {
																						console.log(
																							res.data,
																							'singleStock'
																						);

																						setSingleStock(res.data);
																					});
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate'
																			aria-controls='offcanvasRightupdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>
																		<span
																			style={{ fontSize: 21 }}
																			className='px-3'
																			onClick={() => {
																				onDeleteStudent(item?._id);
																			}}
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default Event;
