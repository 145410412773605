import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';

import Dashin from './components/Dashin';
import Login from './screen/Login/Login';

import Manage from './screen/Pages/Manage/Manage';
import Hmpage from './screen/Pages/Hpage/Hmpage';

import Bank from './screen/Pages/Bank/Bank';

import Expo from './screen/Pages/Expo/Expo';
import Payment from './screen/Pages/Payment/Payment';
import Ambass from './screen/Pages/Ambass/Ambass';
import Oerder from './screen/Pages/Order/Oerder';
import Dorders from './screen/Pages/Dorder/Dorders';
import Pmethode from './screen/Pages/Paymethod/Pmethode';
import Seller from './screen/Pages/Seller/Seller';
import Breand from './screen/Pages/Brand/Breand';
import Services from './screen/Pages/Servicesx/Services';
import Event from './screen/Pages/Eveent/Event';
import Business from './screen/Pages/Business/Business';
import Packages from './screen/Pages/Packages/Packages';

// import Paricipant from "./screen/Pages/Participant/Participant";

import Arrival from './screen/Pages/Arrival/Arrival';
// import Form from "./screen/Pages/Form/Form";
import Subscriber from './screen/Pages/Subscriber/Subscriber';
import Settings from './screen/Pages/Settingss/Settings';
import Propage from './screen/Pages/Product/Propage';
import Product from './screen/Pages/Prduct/Product';
import Dashboard from './screen/Pages/Dashboard/Dashbord';
import Category from './screen/Pages/Category/Category';
import SubCategory from './screen/Pages/SubCategory/SubCategory';
import ServicesPosts from './screen/Pages/Ambass/ServicesPosts';
import AmbassadorPayment from './screen/Pages/Payment/AmbassadorPayment';
import Transactions from './screen/Pages/Payment/Transactions';
import Booking from './screen/Pages/Booking/Booking';
import ProductCategory from './screen/Pages/ProductCategory/ProductCategory';
import RequestAmbassdors from './screen/Pages/RequestAmbassdors/RequestAmbassdors';
import SellerRequest from './screen/Pages/Seller/SellerRequest';
import EStock from './screen/Pages/E_Stock/EStock';
import ERacks from './screen/Pages/E-Racks/ERacks';
import CreateStore from './screen/Pages/CreateStore/CreateStore';
import AddSellerDetails from './screen/Pages/Seller/AddSellerDetails';
import Staff from './screen/Pages/Staff/Staff';
import MainStockRacks from './screen/Pages/MainStock/MainStockRacks';
import ViewProduct from './screen/Pages/ViewProduct/ViewProduct';
import SellerPayments from './screen/Pages/Payment/SellerPayments';
// import Register from './screen/Register/Register';
import Wallet from './screen/Pages/Wallet/Wallet.js';
import Charges from './screen/Pages/Charges/Charges.js';
import Banner from './screen/Pages/Banner/Banner';
import WebSettings from './screen/Pages/webSettings/WebSettings';
import All from './screen/Pages/OrderDetail/All';
import UserPayment from './components/User Payment/UserPayment';

function App() {
	return (
		<>
			<HashRouter>
				<Routes>
					<Route
						path='/'
						element={<Login />}
					/>
					{/* <Route path="/register" element={<Register />} /> */}
					<Route element={<Dashin />}>
						<Route
							path='/dashboard'
							element={<Dashboard />}
						/>
						<Route
							path='/manage'
							element={<Manage />}
						/>
						<Route
							path='/homepage'
							element={<Hmpage />}
						/>
						<Route
							path='/allOrders'
							element={<All />}
						/>
						<Route
							path='/product'
							element={<Product />}
						/>

						<Route
							path='/propage'
							element={<Propage />}
						/>

						<Route
							path='/expo'
							element={<Expo />}
						/>
						<Route
							path='/booking'
							element={<Booking />}
						/>
						<Route
							path='/payments'
							element={<Payment />}
						/>
						<Route
							path='/bank'
							element={<Bank />}
						/>
						<Route
							path='/charges'
							element={<Charges />}
						/>

						<Route
							path='/ambassador_payment'
							element={<AmbassadorPayment />}
						/>
						<Route
							path='/seller_payment'
							element={<SellerPayments />}
						/>

						<Route
							path='/transactions'
							element={<Transactions />}
						/>

						<Route
							path='/ambass'
							element={<Ambass />}
						/>
						<Route
							path='/order'
							element={<Oerder />}
						/>
						<Route
							path='/request_ambassdors'
							element={<RequestAmbassdors />}
						/>

						<Route
							path='/request_sellers'
							element={<SellerRequest />}
						/>
						<Route
							path='/e_stock/:id'
							element={<EStock />}
						/>
						<Route
							path='/e_racks/:id'
							element={<ERacks />}
						/>
						<Route
							path='/mainStockracks/:id'
							element={<MainStockRacks />}
						/>
						<Route
							path='/viewProduct/:id'
							element={<ViewProduct />}
						/>

						<Route
							path='/demand/order'
							element={<Dorders />}
						/>
						<Route
							path='/wallet'
							element={<Wallet />}
						/>
						<Route
							path='/payment/methode'
							element={<Pmethode />}
						/>
						<Route
							path='/banner'
							element={<Banner />}
						/>
						<Route
							path='/user_payment'
							element={<UserPayment />}
						/>
						<Route
							path='/sellers'
							element={<Seller />}
						/>
						<Route
							path='/brands'
							element={<Breand />}
						/>

						<Route
							path='/services'
							element={<Services />}
						/>
						<Route
							path='/services_posts'
							element={<ServicesPosts />}
						/>
						<Route
							path='/event'
							element={<Event />}
						/>

						<Route
							path='/business/opportunities'
							element={<Business />}
						/>
						<Route
							path='/packages'
							element={<Packages />}
						/>

						<Route
							path='/category'
							element={<Category />}
						/>
						<Route
							path='/product_category'
							element={<ProductCategory />}
						/>
						<Route
							path='/product_subCategory'
							element={<SubCategory />}
						/>
						<Route
							path='/staff'
							element={<Staff />}
						/>
						{/* <Route  path="/participant" element={<Paricipant />} /> */}

						<Route
							path='/new/arrival'
							element={<Arrival />}
						/>

						{/* <Route  path="/forms" element={<Form />} /> */}
						<Route
							path='/subscriber'
							element={<Subscriber />}
						/>

						<Route
							path='/settings'
							element={<Settings />}
						/>
						<Route
							path='/webSettings'
							element={<WebSettings />}
						/>

						<Route
							path='/create_store/:id'
							element={<CreateStore />}
						/>

						<Route
							path='/seller_details/:id'
							element={<AddSellerDetails />}
						/>
						<Route
							path='/seller_details/:id/:serId'
							element={<AddSellerDetails />}
						/>
					</Route>
				</Routes>
			</HashRouter>
		</>
	);
}

export default App;
