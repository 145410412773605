import React, { useState, useEffect } from 'react';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import axios from 'axios';
import ApiUrl from '../../../src/screen/Pages/BaseUrl';
import { Modal } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import withAuth from '../../hooks/withAuth';

function UserPayment() {
	const [allproduct, setAllProduct] = useState([]);
	const [topUpDetails, setTopUpDetails] = useState('');

	console.log('=========selling product========', allproduct);
	const [allrecycling, setAllRecycling] = useState([]);
	const [updateAmount, setUpdateAmount] = useState(topUpDetails?.amount);
	console.log(topUpDetails?.amount);
	console.log(updateAmount);

	console.log('allrecycling product', allrecycling);
	console.log(allproduct);

	const handleApprove = () => {
		try {
		} catch (error) {}
	};

	useEffect(() => {
		axios.get(`${ApiUrl}/topUp/getAll`).then((res) => {
			console.log(res.data);
			setAllProduct(res.data);
		});

		axios.get(`${ApiUrl}/withdraw/getAll`).then((res) => {
			console.log(res.data);
			setAllRecycling(res.data);
			console.log(allrecycling);
		});
	}, []);

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table2')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table2').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table3')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table3').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table4')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table4').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage User Payment</h5>

								<ul
									class='nav nav-pills mb-3 d-flex '
									id='pills-tab'
									role='tablist'
									style={{ gap: '12px', justifyContent: 'flex-start' }}
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link active'
											id='pills-Recycling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Recycling'
											type='button'
											role='tab'
											aria-controls='pills-Recycling'
											aria-selected='true'
										>
											Top Up
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-Selling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Selling'
											type='button'
											role='tab'
											aria-controls='pills-Selling'
											aria-selected='false'
										>
											Withdraw
										</button>
									</li>
								</ul>

								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-Recycling'
										role='tabpanel'
										aria-labelledby='pills-Recycling-tab'
									>
										<ul
											class='nav nav-pills d-flex '
											id='pills-tab'
											role='tablist'
											style={{ gap: '12px' }}
										>
											<li
												class='nav-item'
												role='presentation'
											>
												<button
													class='nav-link active'
													id='pills-recyclingPending-tab'
													data-bs-toggle='pill'
													data-bs-target='#pills-recyclingPending'
													type='button'
													role='tab'
													aria-controls='pills-recyclingPending'
													aria-selected='true'
												>
													Pending
												</button>
											</li>
											<li
												class='nav-item'
												role='presentation'
											>
												<button
													class='nav-link'
													id='pills-recyclingApproved-tab'
													data-bs-toggle='pill'
													data-bs-target='#pills-recyclingApproved'
													type='button'
													role='tab'
													aria-controls='pills-recyclingApproved'
													aria-selected='false'
												>
													Approve
												</button>
											</li>
										</ul>

										<div
											class='tab-content'
											id='pills-tabContent'
										>
											<div
												class='tab-pane fade show active'
												id='pills-recyclingPending'
												role='tabpanel'
												aria-labelledby='pills-recyclingPending-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle mt-5'
															style={{ borderColor: '#4f4f51' }}
															id='table'
														>
															<thead className='bg-light'>
																<tr>
																	<th>Transaction ID</th>
																	<th>Proof</th>
																	<th>Amount</th>
																	<th>Status</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
																{allproduct &&
																	allproduct?.map((item, index) => {
																		if (item?.status === 'pending') {
																			if (item?.userId.length > 0) {
																				return (
																					<>
																						<tr>
																							<td>{item.transactionId}</td>
																							<td>
																								<div className='prod-img'>
																									<img
																										src={`${ApiUrl}/images/${item?.image}`}
																										alt=''
																									/>
																								</div>
																							</td>
																							<td>{item?.amount}</td>

																							<td>
																								<span
																									className='text-ligh  '
																									style={{
																										color: '#278a1a',
																										fontSize: '10px',
																										backgroundColor:
																											'#41ff2848',
																										borderRadius: 20,
																										padding: '5px 10px',
																									}}
																								>
																									{item?.status}
																								</span>
																							</td>

																							<td>
																								<button
																									className='btn btn-success'
																									data-bs-toggle='modal'
																									data-bs-target='#myModal'
																									onClick={() => {
																										setTopUpDetails(item);
																									}}
																								>
																									<i className='fa fa-eye'></i>
																								</button>
																							</td>
																						</tr>
																					</>
																				);
																			}
																		}
																	})}
															</tbody>
														</table>
														{/* Model Start */}
														<div
															class='modal fade'
															id='myModal'
															tabindex='-1'
															aria-labelledby='exampleModalLabel'
															aria-hidden='true'
														>
															<div class='modal-dialog modal-lg'>
																<div class='modal-content'>
																	<div class='modal-header'>
																		<h5
																			class='modal-title'
																			id='exampleModalLabel'
																		>
																			Topup Details
																		</h5>
																		<button
																			type='button'
																			class='btn-close'
																			data-bs-dismiss='modal'
																			aria-label='Close'
																		></button>
																	</div>
																	<div class='modal-body'>
																		<div class='container'>
																			<div class='row'>
																				<div class='col-md-6'>
																					<img
																						src={`${ApiUrl}/images/${topUpDetails?.image}`}
																						alt='Image'
																						class='img-fluid'
																					/>
																				</div>
																				<div class='col-md-6 text-start'>
																					<h4>Description</h4>
																					<span className='fw-bold fs-5'>
																						Bank :
																					</span>
																					<br />
																					{topUpDetails?.bankName}
																					<br />
																					<span className='fw-bold fs-5'>
																						Bank Holder Name :{' '}
																					</span>
																					<br />
																					{topUpDetails?.bankHolderName}
																					<br />
																					<span className='fw-bold fs-5'>
																						Bank Account Number :{' '}
																					</span>
																					<br />
																					{topUpDetails?.bankAccountNumber}
																					<br />
																					<span className='fw-bold fs-5'>
																						Amount :{' '}
																					</span>
																					<input
																						type='text'
																						id='updateAmount'
																						defaultValue={topUpDetails?.amount}
																						className='form-control w-75'
																						onChange={(e) =>
																							setUpdateAmount(e.target.value)
																						}
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class='modal-footer'>
																		<button
																			type='button'
																			class='btn btn-secondary'
																			data-bs-dismiss='modal'
																		>
																			Close
																		</button>
																		<button
																			type='button'
																			class='btn btn-primary'
																			onClick={() => {
																				const params = {
																					id: topUpDetails?._id,
																					amount:
																						document.getElementById(
																							'updateAmount'
																						).value,
																					status: 'approved',
																				};
																				axios
																					.put(
																						`${ApiUrl}/topUp/updateById`,
																						params
																					)
																					.then((res) => {
																						console.log(res.data);
																						toast(
																							'status updated successfully!'
																						);
																					});
																			}}
																		>
																			Approve
																		</button>
																	</div>
																</div>
															</div>
														</div>
														{/* Model End */}
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='pills-recyclingApproved'
												role='tabpanel'
												aria-labelledby='pills-recyclingApproved-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle mt-5'
															style={{ borderColor: '#4f4f51' }}
															id='table'
														>
															<thead className='bg-light'>
																<tr>
																	<th>Transaction ID</th>
																	<th>Proof</th>
																	<th>Amount</th>
																	<th>Status</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
																{allproduct &&
																	allproduct?.map((item, index) => {
																		if (item?.status === 'approved') {
																			if (item?.userId.length > 0) {
																				return (
																					<>
																						<tr>
																							<td>{item.transactionId}</td>
																							<td>
																								<div className='prod-img'>
																									<img
																										src={`${ApiUrl}/images/${item?.image}`}
																										alt=''
																									/>
																								</div>
																							</td>
																							<td>{item?.amount}</td>

																							<td>
																								<span
																									className='text-ligh  '
																									style={{
																										color: '#278a1a',
																										fontSize: '10px',
																										backgroundColor:
																											'#41ff2848',
																										borderRadius: 20,
																										padding: '5px 10px',
																									}}
																								>
																									{item?.status}
																								</span>
																							</td>

																							<td>
																								<button
																									className='btn btn-success'
																									data-bs-toggle='modal'
																									data-bs-target='#myModal2'
																									onClick={() => {
																										setTopUpDetails(item);
																									}}
																								>
																									<i className='fa fa-eye'></i>
																								</button>
																							</td>
																						</tr>
																					</>
																				);
																			}
																		}
																	})}
															</tbody>
														</table>
														<div
															class='modal fade'
															id='myModal2'
															tabindex='-1'
															aria-labelledby='exampleModalLabel'
															aria-hidden='true'
														>
															<div class='modal-dialog modal-lg'>
																<div class='modal-content'>
																	<div class='modal-header'>
																		<h5
																			class='modal-title'
																			id='exampleModalLabel'
																		>
																			Topup Details
																		</h5>
																		<button
																			type='button'
																			class='btn-close'
																			data-bs-dismiss='modal'
																			aria-label='Close'
																		></button>
																	</div>
																	<div class='modal-body'>
																		<div class='container'>
																			<div class='row'>
																				<div class='col-md-6'>
																					<img
																						src={`${ApiUrl}/images/${topUpDetails?.image}`}
																						alt='Image'
																						class='img-fluid'
																					/>
																				</div>
																				<div class='col-md-6 text-start'>
																					<h4>Description</h4>
																					<span className='fw-bold fs-5'>
																						Bank :
																					</span>
																					<br />
																					{topUpDetails?.bankName}
																					<br />
																					<span className='fw-bold fs-5'>
																						Bank Holder Name :{' '}
																					</span>
																					<br />
																					{topUpDetails?.bankHolderName}
																					<br />
																					<span className='fw-bold fs-5'>
																						Bank Account Number :{' '}
																					</span>
																					<br />
																					{topUpDetails?.bankAccountNumber}
																					<br />
																					<span className='fw-bold fs-5'>
																						Amount :{' '}
																					</span>
																					<input
																						type='number'
																						defaultValue={topUpDetails?.amount}
																						className='form-control w-75'
																						onChange={(e) =>
																							setUpdateAmount(e.target.value)
																						}
																						disabled
																					/>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div class='modal-footer'>
																		<button
																			type='button'
																			class='btn btn-success'
																			data-bs-dismiss='modal'
																		>
																			Close
																		</button>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div
										class='tab-pane fade'
										id='pills-Selling'
										role='tabpanel'
										aria-labelledby='pills-Selling-tab'
									>
										<ul
											class='nav nav-pills d-flex '
											id='pills-tab'
											role='tablist'
											style={{ gap: '12px' }}
										>
											<li
												class='nav-item'
												role='presentation'
											>
												<button
													class='nav-link active'
													id='pills-sellingPending-tab'
													data-bs-toggle='pill'
													data-bs-target='#pills-sellingPending'
													type='button'
													role='tab'
													aria-controls='pills-sellingPending'
													aria-selected='true'
												>
													Pending
												</button>
											</li>
											<li
												class='nav-item'
												role='presentation'
											>
												<button
													class='nav-link'
													id='pills-sellingApprove-tab'
													data-bs-toggle='pill'
													data-bs-target='#pills-sellingApprove'
													type='button'
													role='tab'
													aria-controls='pills-sellingApprove'
													aria-selected='false'
												>
													Approve
												</button>
											</li>
										</ul>

										<div
											class='tab-content'
											id='pills-tabContent'
										>
											<div
												class='tab-pane fade show active'
												id='pills-sellingPending'
												role='tabpanel'
												aria-labelledby='pills-sellingPending-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle mt-5'
															style={{ borderColor: '#4f4f51' }}
															id='table3'
														>
															<thead className='bg-light'>
																<tr>
																	<th>Bank Holder Name</th>
																	<th>Bank Account Number</th>
																	<th>Amount</th>
																	<th>Status</th>
																	<th>Action</th>
																</tr>
															</thead>
															<tbody>
																{allrecycling &&
																	allrecycling?.map((item, index) => {
																		if (item?.status === 'pending') {
																			if (item?.sellerId.length > 0) {
																				return (
																					<>
																						<tr>
																							<td>{item?.name}</td>
																							<td>{item?.number}</td>
																							<td>{item?.amount}</td>
																							<td>{item?.status}</td>
																							<td>
																								<button
																									onClick={() => {
																										const params = {
																											id: item?._id,
																											status: 'approved',
																										};

																										axios
																											.put(
																												`${ApiUrl}/withdraw/updateById`,
																												params
																											)
																											.then((res) => {
																												console.log(res.data);
																												if (
																													res.data.status ===
																													404
																												) {
																													toast.error(
																														res.data.error
																													);
																												} else {
																													toast(
																														'status updated successfully!'
																													);
																												}
																											});
																									}}
																									target='_blank'
																									class='btn btn-sm btn-primary py-1'
																								>
																									Approve
																								</button>
																							</td>
																						</tr>
																					</>
																				);
																			}
																		}
																	})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
											<div
												class='tab-pane fade'
												id='pills-sellingApprove'
												role='tabpanel'
												aria-labelledby='pills-sellingApprove-tab'
											>
												<div className='min-box my-4'>
													<div className='products-table'>
														<table
															className='table border rounded align-middle mt-5'
															style={{ borderColor: '#4f4f51' }}
															id='table3'
														>
															<thead className='bg-light'>
																<tr>
																	<th>Bank Holder Name</th>
																	<th>Bank Account Number</th>
																	<th>Amount</th>
																	<th>Status</th>
																	{/* <th>Action</th> */}
																</tr>
															</thead>
															<tbody>
																{allrecycling &&
																	allrecycling?.map((item, index) => {
																		if (item?.status === 'approved') {
																			if (item?.sellerId.length > 0) {
																				return (
																					<>
																						<tr>
																							<td>{item?.name}</td>
																							<td>{item?.number}</td>
																							<td>{item?.amount}</td>
																							<td>{item?.status}</td>
																							{/* <td>
                                            <button
                                              onClick={() => {
                                                const params = {
                                                  id: item?._id,
                                                  status: "approved",
                                                };

                                                axios
                                                  .put(
                                                    `${ApiUrl}/productSelling/updateById`,
                                                    params
                                                  )
                                                  .then((res) => {
                                                    console.log(res.data);

                                                    toast(
                                                      "status updated successfully!"
                                                    );
                                                  });
                                              }}
                                              target="_blank"
                                              class="btn btn-sm btn-primary py-1"
                                            >
                                              Approve
                                            </button>
                                          </td> */}
																						</tr>
																					</>
																				);
																			}
																		}
																	})}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(UserPayment);
