import axios from 'axios';
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { toast } from 'react-toastify';
const BannerModal = ({
	actionLabel,
	title,
	singleStock,
	sellerid,
	ApiUrl,
	setStocks,
}) => {
	const [singleImages, setSingleImages] = useState([]);

	//functions
	const updateData = (values) => {};

	const sendData = (values) => {};
	//main function
	return (
		<div>
			<div className='j'>
				<div className='products-actions d-flex p-1'>
					<div className='imort-product '>
						<div className='btn-product d-flex'>
							<div className='imp-btn'></div>
							<div className='exp-btn px-3'></div>
						</div>
					</div>
					<div className='action-btn'>
						<div className='actions d-flex'>
							<div className='Bulk-btn'></div>
							<div className='Del-btn px-3'></div>
							<button
								className='add-btn'
								type='button'
								data-bs-toggle='offcanvas'
								data-bs-target='#offcanvasRight'
								aria-controls='offcanvasRight'
							>
								<AiOutlinePlus />
								<span className='px-1'>{actionLabel}</span>
							</button>

							<div
								className='offcanvas offcanvas-end'
								tabIndex={-1}
								id='offcanvasRight'
								aria-labelledby='offcanvasRightLabel'
							>
								<div className='offcanvas-header px-4'>
									<div className='content-header'>
										<h4>Create Banner</h4>
									</div>

									<button
										type='button'
										className='btn-close bg-white shadow rounded-5 text-reset'
										data-bs-dismiss='offcanvas'
										aria-label='Close'
										style={{
											width: '5px',
											height: '30px',
											opacity: '0.8',
											fontSize: '12px',
										}}
									/>
								</div>
								{/* Canvas */}
								<div className='offcanvas-body p-0'>
									<div className='form-data'>
										<div className='wrap-data p-5'>
											<form
												onSubmit={(e) => {
													e.preventDefault();
													sendData(e.target);
												}}
											>
												<div className='row pt-4 mb-2  align-items-center'>
													<div className='col-md-3'>
														<label
															for='inputPassword6'
															className='form-label'
														>
															Banner Title
														</label>
													</div>
													<div className='col-md-9'>
														<input
															type='text'
															name='title'
															className='form-control-input'
															placeholder='Banner Title'
														/>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-3'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Banner Image
															</label>
														</div>
														<div className='col-md-9'>
															<div className='col-span-8 sm:col-span-4 position-relative'>
																<div className=''>
																	{singleImages.length > 0 ? (
																		<>
																			<div className='col-md-2  position-relative'>
																				<img
																					src={URL?.createObjectURL(
																						singleImages[0]
																					)}
																					width='100%'
																					height='100%'
																					className='border rounded-3'
																					style={{ objectFit: 'cover' }}
																					alt=''
																				/>
																				<span
																					className='border  p-1 py-0 rounded-circle bg-dark text-white position-absolute'
																					style={{
																						left: '100%',
																						lineHeight: '15px',
																						fontSize: 'x-small',
																						cursor: 'pointer',
																					}}
																					onClick={() => setSingleImages([])}
																				>
																					x
																				</span>
																			</div>
																		</>
																	) : (
																		<div className='w-full text-center'>
																			<div
																				className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																				role='button'
																				tabIndex={0}
																				style={{
																					borderStyle: 'dotted',
																					borderColor: '#FFD700',
																				}}
																			>
																				<input
																					accept='image/*'
																					multiple=''
																					type='file'
																					name='image'
																					onChange={(e) =>
																						setSingleImages(e.target.files)
																					}
																				/>
																				<span className='mx-auto flex justify-center'>
																					<svg
																						stroke='currentColor'
																						fill='none'
																						strokeWidth={2}
																						viewBox='0 0 24 24'
																						strokeLinecap='round'
																						strokeLinejoin='round'
																						className='text-3xl text-green-500'
																						height='1em'
																						width='1em'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<polyline points='16 16 12 12 8 16' />
																						<line
																							x1={12}
																							y1={12}
																							x2={12}
																							y2={21}
																						/>
																						<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																						<polyline points='16 16 12 12 8 16' />
																					</svg>
																				</span>
																				<p className='text-sm mt-2'>
																					Drag your images here
																				</p>
																				<em className='text-xs text-gray-400'>
																					(Only *.jpeg, *.webp and *.png images
																					will be accepted)
																				</em>
																			</div>
																			<div className='text-green-500' />
																			<aside className='flex flex-row flex-wrap mt-4' />
																		</div>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>

												<div className='offcanvas-footer px-5 py-3'>
													<div className='content-footer'>
														<div className='row'>
															<div className='col-lg-6 col-md-12 py-3'>
																<button
																	type='button'
																	class='cancel-btn btn-lg'
																	data-bs-dismiss='offcanvas'
																>
																	Cancel
																</button>
															</div>
															<div className='col-lg-6 col-md-12 py-3'>
																<button
																	type='submit'
																	class='add-last-btn btn-lg'
																	data-bs-dismiss='offcanvas'
																>
																	{actionLabel}
																</button>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>

								{/* Canvas */}
							</div>
							<div
								className='offcanvas offcanvas-end'
								tabIndex={-1}
								id='offcanvasRightupdate'
								aria-labelledby='offcanvasRightLabel'
							>
								<div className='offcanvas-header px-4'>
									<div className='content-header'>
										<h4>Manage Product Category Update</h4>
										<p>Update products info, combinations and extras.</p>
									</div>

									<button
										type='button'
										className='btn-close bg-white shadow rounded-5 text-reset'
										data-bs-dismiss='offcanvas'
										aria-label='Close'
										style={{
											width: '5px',
											height: '30px',
											opacity: '0.8',
											fontSize: '12px',
										}}
									/>
								</div>
								{/* Canvas */}
								<div className='offcanvas-body p-0'>
									<div className='form-data'>
										<div className='wrap-data p-5'>
											<form
												onSubmit={(e) => {
													e.preventDefault();
													updateData(e.target);
												}}
											>
												<div className='row pt-4  align-items-center'>
													<div className='col-md-4'>
														<label
															for='inputPassword6'
															className='form-label'
														>
															{' '}
															Category Title
														</label>
													</div>
													<div className='col-md-8'>
														<input
															type='text'
															name='title'
															className='form-control-input'
															placeholder='Category Title'
															defaultValue={singleStock?.title}
														/>
													</div>
												</div>

												<div className='row pt-4  align-items-center'>
													<div className='col-md-4'>
														<label
															for='inputPassword6'
															className='form-label'
														>
															{' '}
															Admin Minimum Commission
														</label>
													</div>
													<div className='col-md-8'>
														<input
															type='text'
															id='inputPassword6'
															name='adminCommission'
															className='form-control-input'
															aria-labelledby='passwordHelpInline'
															placeholder='Enter Admin Minimum Commission'
															defaultValue={singleStock?.adminCommission}
														/>
													</div>
												</div>

												<div className='row  pt-4  align-items-center'>
													<div className='col-md-4'>
														<label
															for='inputPassword6'
															className='form-label'
														>
															Status
														</label>
													</div>
													<div className='col-md-8'>
														<select
															name='status'
															class='form-control-input-2'
															aria-label='Default select example'
															defaultValue={singleStock?.status}
														>
															<option selected>Active</option>
															<option value='Inactive'>Inactive</option>
														</select>
													</div>
												</div>

												<div className='offcanvas-footer px-5 py-3'>
													<div className='content-footer'>
														<div className='row'>
															<div className='col-lg-6 col-md-12 py-3'>
																<button
																	type='button'
																	class='cancel-btn btn-lg'
																>
																	Cancel
																</button>
															</div>
															<div className='col-lg-6 col-md-12 py-3'>
																<button
																	type='submit'
																	class='add-last-btn btn-lg'
																	data-bs-dismiss='abc'
																>
																	Update Category
																</button>
																<button
																	style={{ display: 'none' }}
																	type='submit'
																	class='add-last-btn btn-lg'
																	id='myclosebtn'
																	data-bs-dismiss='offcanvas'
																>
																	Update Category
																</button>
															</div>
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>

								{/* Canvas */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BannerModal;
