import React, { useState, useEffect } from 'react';
import ScrollableSection from '../Dashboard/Dashbord';
import Nav from '../../../components/Nav';

import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineZoomIn } from 'react-icons/ai';
import { message, Modal } from 'antd';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { ToastContainer, toast } from 'react-toastify';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import withAuth from '../../../hooks/withAuth';

function Bank() {
	const [checkValue, setCheckValue] = useState(false);
	const [newBrand, setnewBrand] = useState([]);
	const [singleStock, setSingleStock] = useState({});
	const [updateBrand, setupdateBrand] = useState();

	useEffect(() => {
		axios.get(`${ApiUrl}/bank/getAll`).then((res) => {
			console.log(res.data);
			setnewBrand(res.data);
			console.log(newBrand);
		});
	}, []);

	const sendData = (values) => {
		if (values?.bankName?.value?.length === 0) {
			toast('Please Enter Bank Name');
		} else if (values?.accountNumber?.value?.length === 0) {
			toast('please Enter your Account No');
		} else if (values?.accountName?.value?.length === 0) {
			toast('please Enter your Account Name');
		} else if (values?.country?.value?.length === 0) {
			toast('please enter your countery');
		} else if (values?.image?.value?.length === 0) {
			toast('please upload your logo');
		} else if (values?.status?.value?.length === 0) {
			toast('please select status');
		} else {
			const params = new FormData();

			params.append('bankName', values.bankName.value);
			params.append('accountNumber', values.accountNumber.value);
			params.append('accountName', values.accountName.value);
			params.append('country', values.country.value);
			params.append('image', values.image.files[0]);

			axios
				.post(`${ApiUrl}/bank/create`, params)

				.then((res) => {
					console.log(res.data);
					if (res.data.status === 'success') {
						document
							.querySelector('.offcanvas-backdrop')
							.classList.remove('show');
						setCheckValue(true);
						setTimeout(() => {
							setCheckValue(false);
						}, 1500);
						console.log('bank created');

						toast('Bank is created');
					}
				});
		}
	};

	const updateData = (values) => {
		const params = new FormData();
		params.append('id', updateBrand);
		params.append('bankName', values.bankName.value);
		params.append('accountNumber', values.accountNumber.value);
		params.append('accountName', values.accountName.value);
		params.append('country', values.country.value);
		params.append('image', values.image.files[0]);

		axios
			.put(`${ApiUrl}/bank/updateByID`, params)

			.then((res) => {
				console.log(res.data);
				if (res.data.status === 'success') {
					document
						.querySelector('.offcanvas-backdrop')
						.classList.remove('show');
					setCheckValue(true);
					setTimeout(() => {
						setCheckValue(false);
					}, 1500);
					console.log('bank updated');
					toast('Bank is updated');
				}
			});
	};

	const onDeleteStudent = (id) => {
		console.log('==========================', id);
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				// const params = {
				//   id:id,
				// };

				axios.delete(`${ApiUrl}/bank/deleteByID?id=` + id).then((res) => {
					console.log(res.data);

					axios.get(`${ApiUrl}/bank/getAll`).then((res) => {
						console.log(res.data);
						setnewBrand(res.data);
						console.log(newBrand);
					});

					console.log('bank Deleted successfully!');
					toast('Bank is deleted');
				});
			},
		});
	};

	const className = 'offcanvas offcanvas-end offcanvas-backdrop.show';
	const changeClassName =
		'offcanvas offcanvas-end d-none  offcanvas-backdrop.show2';

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<ToastContainer />
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage Brands</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'>
												<div className='imp-btn'></div>
												<div className='exp-btn px-3'></div>
											</div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Bank Create / Update</h4>
																<p>
																	Update Bank info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			sendData(e.target);
																		}}
																	>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Bank Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='bankName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Bank Name'
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Account No
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='accountNumber'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Account No'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Account Title
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='accountName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Account Title'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Country
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='country'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Country'
																				/>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Logo
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								type='file'
																								name='image'
																							/>
																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																							aria-label='Close'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Add
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRightupdate'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Brand Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			updateData(e.target);
																		}}
																	>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Bank Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='bankName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Bank Name'
																					defaultValue={singleStock?.bankName}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Account No
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='accountNumber'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Account No'
																					defaultValue={
																						singleStock?.accountNumber
																					}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Account Title
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='accountName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Account Title'
																					defaultValue={
																						singleStock?.accountName
																					}
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Country
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					name='country'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Country'
																					defaultValue={singleStock?.country}
																				/>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Logo
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								type='file'
																								name='image'
																								defaultValue={
																									singleStock?.image
																								}
																							/>
																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																							aria-label='Close'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Update
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Update
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													{/* Canvas */}
												</div>
											</div>

											{/* Modal-Del */}
											<button
												className='add-btn'
												type='button'
												data-bs-toggle='offcanvas'
												data-bs-target='#offcanvasRight'
												aria-controls='offcanvasRight'
											>
												<AiOutlinePlus />
												<span className='px-1'> Add Bank</span>
											</button>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>#ID</th>
													<th>logo</th>
													<th>Bank Name</th>
													<th>Account No</th>
													<th>Account Title</th>
													<th>Country</th>

													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{newBrand?.map((item, index) => {
													return (
														<>
															<tr>
																<td>{index + 1}</td>
																<td>
																	<div className='prod-img'>
																		<img
																			src={`${ApiUrl}/images/${item?.image}`}
																			alt=''
																		/>
																	</div>
																</td>
																<td>{item?.bankName}</td>
																<td>{item?.accountNumber}</td>

																<td>{item?.accountName}</td>
																<td>{item?.country}</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setupdateBrand(item?._id);

																				axios
																					.get(
																						`${ApiUrl}/bank/getById?id=` +
																							item?._id
																					)
																					.then((res) => {
																						console.log(
																							res.data,
																							'singleStock'
																						);

																						setSingleStock(res.data);
																					});
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate'
																			aria-controls='offcanvasRightupdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>
																		<span
																			style={{ fontSize: 21 }}
																			className='px-3'
																			onClick={() => {
																				onDeleteStudent(item?._id);
																			}}
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Bank);
