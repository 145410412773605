import { useEffect, useState } from 'react';
import './style.css';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import axios from 'axios';
import ApiUrl from '../BaseUrl';

const RentTable2 = () => {
	const [rentRack2, setRentRack2] = useState();

	return (
		<>
			<h5 className='p-1'>Rental Rack Charges</h5>
			<div className='min-box my-4'>
				<div className='products-table'>
					<table
						className='table border rounded align-middle mt-5'
						style={{ borderColor: '#4f4f51' }}
						id='table2'
					>
						<thead className='bg-light'>
							<tr>
								<th>id</th>
								<th>Name</th>
								<th>Amount</th>
								<th>Action</th>
							</tr>
						</thead>
						<tbody>
							{rentRack2?.map((item, index) => {
								return (
									<>
										<tr>
											<td>{index + 1}</td>
											<td>Per Rack Rental Commission</td>
											<td>${item.rentalRacksComission}</td>
											<td>
												<FiEdit />
												<RiDeleteBinLine />
											</td>
										</tr>
									</>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default RentTable2;
