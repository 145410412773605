import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { BsFillEyeFill } from 'react-icons/bs';
import { MdOutlineSystemUpdateAlt } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlinePlus } from 'react-icons/ai';
import AllCanvas from './AllCanvas';
import withAuth from '../../../hooks/withAuth';

function All() {
	const [allData, setAllData] = useState([]);
	const [newItems, setNewItems] = useState();

	const [show, setShow] = useState(false);
	const [orderId, setOrderId] = useState('');
	const [updateStatus, setUpdateStatus] = useState('');
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const fn_updateStatus = () => {};

	const fnFunction = (item) => {
		console.log(item);
		setNewItems(item);
	};

	return (
		<>
			<ul
				class='nav nav-pills mb-3 d-flex mt-3'
				id='pills-tab'
				role='tablist'
				style={{ gap: '12px', justifyContent: 'flex-start' }}
			>
				<li
					class='nav-item'
					role='presentation'
				>
					<button
						class='nav-link active'
						id='pills-Recycling-tab'
						data-bs-toggle='pill'
						data-bs-target='#pills-Recycling'
						type='button'
						role='tab'
						aria-controls='pills-Recycling'
						aria-selected='true'
					>
						Product
					</button>
				</li>
				<li
					class='nav-item'
					role='presentation'
				>
					<button
						class='nav-link'
						id='pills-Selling-tab'
						data-bs-toggle='pill'
						data-bs-target='#pills-Selling'
						type='button'
						role='tab'
						aria-controls='pills-Selling'
						aria-selected='false'
					>
						Racks
					</button>
				</li>

				<li
					class='nav-item'
					role='presentation'
				>
					<button
						class='nav-link '
						id='pills-Recycling-tab'
						data-bs-toggle='pill'
						data-bs-target='#pills-Recycling'
						type='button'
						role='tab'
						aria-controls='pills-Recycling'
						aria-selected='true'
					>
						Displays
					</button>
				</li>
				<li
					class='nav-item'
					role='presentation'
				>
					<button
						class='nav-link'
						id='pills-Selling-tab'
						data-bs-toggle='pill'
						data-bs-target='#pills-Selling'
						type='button'
						role='tab'
						aria-controls='pills-Selling'
						aria-selected='false'
					>
						Selling
					</button>
				</li>
			</ul>
			<div>
				<ToastContainer />
				<Modal
					show={show}
					onHide={handleClose}
				>
					<Modal.Header closeButton>
						<Modal.Title>Update Status</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<select
							className='form-select'
							onChange={(e) => setUpdateStatus(e?.target?.value)}
						>
							<option
								selected
								value={''}
							>
								---Select Status---
							</option>
							<option value={'pending'}>Pending</option>
							<option value={'confirmed'}>Confirmed</option>
							<option value={'out for delivery'}>Out for Delivery</option>
							<option value={'cancelled'}>Cancelled</option>
							<option value={'returned'}>Returned</option>
							<option value={'failed to deliver'}>Failed to Deliver</option>
						</select>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant='secondary'
							onClick={handleClose}
						>
							Close
						</Button>
						<Button
							variant='success'
							onClick={fn_updateStatus}
						>
							Save Changes
						</Button>
					</Modal.Footer>
				</Modal>
				{/* ..............OrderViewModal.............. */}
				<div
					class='offcanvas offcanvas-end'
					tabindex='-1'
					id='offcanvasRight'
					aria-labelledby='offcanvasRightLabel'
				>
					<div class='offcanvas-body'>
						<AllCanvas orderDetails={newItems} />
					</div>
				</div>

				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manage All Orders</h5>
								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle text-center'
											style={{ borderColor: '#4f4f51' }}
										>
											<thead className='bg-light'>
												<tr>
													<th>Sr #</th>
													<th>Order Date</th>
													<th>Order</th>
													<th>Customer Info</th>
													<th>Store</th>
													<th>Order Status</th>

													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{allData &&
													allData?.map((item, index) => {
														return (
															<tr>
																<td>{index + 1}</td>
																<td>{item?.createdAt}</td>
																<td>
																	{item?.ProductsOrder?.map((i) => {
																		return (
																			<>
																				{i?.name}
																				<br />
																			</>
																		);
																	})}
																</td>
																<td>{item?.userName}</td>
																<td>Store</td>
																<td>
																	<span
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																			textTransform: 'capitalize',
																		}}
																	>
																		{item?.state}
																	</span>
																</td>

																<td>
																	<button
																		className='btn btn-success'
																		style={{ fontSize: 21 }}
																		data-bs-toggle='offcanvas'
																		data-bs-target='#offcanvasRight'
																		aria-controls='offcanvasRight'
																		onClick={() => fnFunction(item)}
																	>
																		<BsFillEyeFill />
																	</button>
																</td>
															</tr>
														);
													})}
											</tbody>
											{/* <tbody>
                        {allData && allData?.filter((i=>i?.ProductsOrder?.map(i=>))).map((item, index) => {
                          item?.ProductsOrder?.map((i) => {
                            console.log(i?.sellerID === localStorage?.getItem('sellerId'), 'trues hy results')
                            if (i?.sellerID === localStorage?.getItem('sellerId')) {
                              return (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item?.createdAt}</td>
                                    <td>{i?.name}</td>
                                    <td>
                                      {item?.userName}
                                    </td>
                                    <td>Store</td>
                                    <td style={{ textTransform: "capitalize" }}>{item?.state}</td>
                                    <td>
                                      <button className="btn btn-success"><BsFillEyeFill /></button>
                                    </td>
                                  </tr>
                                </>
                              )
                            }
                          })
                        }
                        )}
                      </tbody> */}
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(All);
