import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ApiUrl from '../BaseUrl';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import withAuth from '../../../hooks/withAuth';

function SellerRequests() {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Request Sellers</h5>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>#Id</th>
													<th>Brand Logo</th>
													<th>Brand Name</th>
													<th>Email</th>
													<th>Number</th>
													<th>Country</th>
													<th>City</th>
													<th>Status</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{categories?.map((item, index) => {
													if (item.status === 'pending') {
														return (
															<>
																<tr>
																	<td>{index + 1}</td>
																	<td>
																		{' '}
																		<div className='prod-img'>
																			<img
																				src={`http://192.168.18.130:3003/images/${item?.brandLogo}`}
																				alt=''
																			/>
																		</div>
																	</td>
																	<td>{item?.brandName}</td>
																	<td>{item?.email}</td>
																	<td>{item?.country}</td>
																	<td>{item?.city}</td>
																	<td>{item?.phoneNo}</td>

																	<td>
																		<span
																			className='text-ligh  '
																			style={{
																				color: '#278a1a',
																				fontSize: '10px',
																				backgroundColor: '#41ff2848',
																				borderRadius: 20,
																				padding: '5px 10px',
																			}}
																		>
																			{item?.status}
																		</span>
																	</td>
																	<td>
																		<div className='actions d-flex'>
																			<button
																				onClick={() => {
																					const params = {
																						id: item?._id,
																						status: 'new',
																					};

																					axios
																						.put(
																							`${ApiUrl}/seller/updateById`,
																							params
																						)
																						.then((res) => {
																							console.log(res.data);

																							toast(
																								'status updated successfully'
																							);

																							axios
																								.get(`${ApiUrl}/seller/getAll`)
																								.then((res) => {
																									console.log(res.data);
																									setCategories(res.data);
																									console.log(categories);
																								});
																						});
																				}}
																				target='_blank'
																				class='btn btn-sm btn-primary py-1'
																			>
																				Approved
																			</button>
																		</div>
																	</td>
																</tr>
															</>
														);
													}
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(SellerRequests);
