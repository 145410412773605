// import React from 'react';
// import withAuth from '../../../hooks/withAuth';

// function Hmpage() {
// 	return (
// 		<>
// 			<div>
// 				<section>
// 					<div className='container py-4'>
// 						<h4>Manage Content </h4>
// 						<div className='row'>
// 							<div className='col-md-3'>
// 								<div
// 									class='nav  nav-pills bg-white h-100 border rounded me-3'
// 									id='v-pills-tab'
// 									role='tablist'
// 									aria-orientation='vertical'
// 								>
// 									<button
// 										class='nav-link active'
// 										id='v-pills-home-tab'
// 										data-bs-toggle='pill'
// 										data-bs-target='#v-pills-home'
// 										type='button'
// 										role='tab'
// 										aria-controls='v-pills-home'
// 										aria-selected='true'
// 									>
// 										header
// 									</button>
// 									<button
// 										class='nav-link'
// 										id='v-pills-profile-tab'
// 										data-bs-toggle='pill'
// 										data-bs-target='#v-pills-profile'
// 										type='button'
// 										role='tab'
// 										aria-controls='v-pills-profile'
// 										aria-selected='false'
// 									>
// 										Home Video
// 									</button>
// 									<button
// 										class='nav-link'
// 										id='v-pills-messages-tab'
// 										data-bs-toggle='pill'
// 										data-bs-target='#v-pills-messages'
// 										type='button'
// 										role='tab'
// 										aria-controls='v-pills-messages'
// 										aria-selected='false'
// 									>
// 										Home Ad Banner
// 									</button>
// 									<button
// 										class='nav-link'
// 										id='v-pills-settings-tab'
// 										data-bs-toggle='pill'
// 										data-bs-target='#v-pills-settings'
// 										type='button'
// 										role='tab'
// 										aria-controls='v-pills-settings'
// 										aria-selected='false'
// 									>
// 										Newsletter
// 									</button>
// 									<button
// 										class='nav-link'
// 										id='v-pills-footer-tab'
// 										data-bs-toggle='pill'
// 										data-bs-target='#v-pills-footer'
// 										type='button'
// 										role='tab'
// 										aria-controls='v-pills-footer'
// 										aria-selected='false'
// 									>
// 										Footer
// 									</button>
// 								</div>
// 							</div>
// 							<div className='col-md-9'>
// 								<div
// 									class='tab-content border bg-white px-5 py-4 rounded'
// 									id='v-pills-tabContent'
// 								>
// 									<div
// 										class='tab-pane fade show active'
// 										id='v-pills-home'
// 										role='tabpanel'
// 										aria-labelledby='v-pills-home-tab'
// 									>
// 										<form>
// 											<div className='row g-3'>
// 												<div className='col-md-12'>
// 													<label> Button 1 Text</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 													placeholder='Become an Ambassador'
// 													/>

// 												</div>
// 												<div className='col-md-12'>
// 													<label> Button 1 Link</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='registration'
// 													/>

// 												</div>
// 												<div className='col-md-12'>
// 													<label> Button 2 Text</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='Login'
// 													/>
// 												</div>
// 												<div className='col-md-12'>
// 													<label> Button 1 Link</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='portal/login'
// 													/>
// 												</div>
// 											</div>

// 											<div class='row mt-3'>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='cancel-btn btn-lg'
// 													>
// 														Cancel
// 													</button>
// 												</div>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='add-last-btn btn-lg'
// 													>
// 														Update
// 													</button>
// 												</div>
// 											</div>
// 										</form>
// 									</div>
// 									<div
// 										class='tab-pane fade'
// 										id='v-pills-profile'
// 										role='tabpanel'
// 										aria-labelledby='v-pills-profile-tab'
// 									>
// 										<form>
// 											<div className='row g-3'>
// 												<div className='col-md-12'>
// 													<label>Title</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='Featured Vedio'
// 													/>
// 												</div>
// 												<div className='col-md-12'>
// 													<label>Video Link</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='hLBkB_bhYzM'
// 													/>
// 												</div>
// 											</div>

// 											<div class='row mt-3'>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='cancel-btn btn-lg'
// 													>
// 														Cancel
// 													</button>
// 												</div>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='add-last-btn btn-lg'
// 													>
// 														Update
// 													</button>
// 												</div>
// 											</div>
// 										</form>
// 									</div>
// 									<div
// 										class='tab-pane fade'
// 										id='v-pills-messages'
// 										role='tabpanel'
// 										aria-labelledby='v-pills-messages-tab'
// 									>
// 										<form>
// 											<div className='row g-3'>
// 												<div className='col-md-12'>
// 													<label>Banner 1</label>
// 													<div className='col-span-8 sm:col-span-4'>
// 														<div className='w-full text-center'>
// 															<div
// 																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
// 																role='button'
// 																tabIndex={0}
// 																style={{
// 																	borderStyle: 'dotted',
// 																	borderColor: '#FFD700',
// 																}}
// 															>
// 																<input
// 																	accept='image/*'
// 																	multiple=''
// 																	type='file'
// 																	className=''
// 																/>
// 																<span className='mx-auto flex justify-center'>
// 																	<svg
// 																		stroke='currentColor'
// 																		fill='none'
// 																		strokeWidth={2}
// 																		viewBox='0 0 24 24'
// 																		strokeLinecap='round'
// 																		strokeLinejoin='round'
// 																		className='text-3xl text-green-500'
// 																		height='1em'
// 																		width='1em'
// 																		xmlns='http://www.w3.org/2000/svg'
// 																	>
// 																		<polyline points='16 16 12 12 8 16' />
// 																		<line
// 																			x1={12}
// 																			y1={12}
// 																			x2={12}
// 																			y2={21}
// 																		/>
// 																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
// 																		<polyline points='16 16 12 12 8 16' />
// 																	</svg>
// 																</span>
// 																<p className='text-sm mt-2'>
// 																	Drag your images here
// 																</p>
// 																<em className='text-xs text-gray-400'>
// 																	(Only *.jpeg, *.webp and *.png images will be
// 																	accepted)
// 																</em>
// 															</div>
// 															<div className='text-green-500' />
// 															<aside className='flex flex-row flex-wrap mt-4' />
// 														</div>
// 													</div>
// 												</div>
// 												<div className='col-md-12'>
// 													<label>URL 1</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='Featured Vedio'
// 													/>
// 												</div>
// 												<div className='col-md-12'>
// 													<label>Banner 2</label>
// 													<div className='col-span-8 sm:col-span-4'>
// 														<div className='w-full text-center'>
// 															<div
// 																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
// 																role='button'
// 																tabIndex={0}
// 																style={{
// 																	borderStyle: 'dotted',
// 																	borderColor: '#FFD700',
// 																}}
// 															>
// 																<input
// 																	accept='image/*'
// 																	multiple=''
// 																	type='file'
// 																	className=''
// 																/>
// 																<span className='mx-auto flex justify-center'>
// 																	<svg
// 																		stroke='currentColor'
// 																		fill='none'
// 																		strokeWidth={2}
// 																		viewBox='0 0 24 24'
// 																		strokeLinecap='round'
// 																		strokeLinejoin='round'
// 																		className='text-3xl text-green-500'
// 																		height='1em'
// 																		width='1em'
// 																		xmlns='http://www.w3.org/2000/svg'
// 																	>
// 																		<polyline points='16 16 12 12 8 16' />
// 																		<line
// 																			x1={12}
// 																			y1={12}
// 																			x2={12}
// 																			y2={21}
// 																		/>
// 																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
// 																		<polyline points='16 16 12 12 8 16' />
// 																	</svg>
// 																</span>
// 																<p className='text-sm mt-2'>
// 																	Drag your images here
// 																</p>
// 																<em className='text-xs text-gray-400'>
// 																	(Only *.jpeg, *.webp and *.png images will be
// 																	accepted)
// 																</em>
// 															</div>
// 															<div className='text-green-500' />
// 															<aside className='flex flex-row flex-wrap mt-4' />
// 														</div>
// 													</div>
// 												</div>
// 												<div className='col-md-12'>
// 													<label>URL 1</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='Featured Vedio'
// 													/>
// 												</div>
// 											</div>

// 											<div class='row mt-3'>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='cancel-btn btn-lg'
// 													>
// 														Cancel
// 													</button>
// 												</div>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='add-last-btn btn-lg'
// 													>
// 														Update
// 													</button>
// 												</div>
// 											</div>
// 										</form>
// 									</div>
// 									<div
// 										class='tab-pane fade'
// 										id='v-pills-settings'
// 										role='tabpanel'
// 										aria-labelledby='v-pills-settings-tab'
// 									>
// 										<form>
// 											<div className='row g-3'>
// 												<div className='col-md-12'>
// 													<label>News Letter News</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='News Letter News'
// 													/>
// 												</div>
// 												<div className='col-md-12'>
// 													<label> News Letter Image</label>
// 													<div className='col-span-8 sm:col-span-4'>
// 														<div className='w-full text-center'>
// 															<div
// 																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
// 																role='button'
// 																tabIndex={0}
// 																style={{
// 																	borderStyle: 'dotted',
// 																	borderColor: '#FFD700',
// 																}}
// 															>
// 																<input
// 																	accept='image/*'
// 																	multiple=''
// 																	type='file'
// 																	className=''
// 																/>
// 																<span className='mx-auto flex justify-center'>
// 																	<svg
// 																		stroke='currentColor'
// 																		fill='none'
// 																		strokeWidth={2}
// 																		viewBox='0 0 24 24'
// 																		strokeLinecap='round'
// 																		strokeLinejoin='round'
// 																		className='text-3xl text-green-500'
// 																		height='1em'
// 																		width='1em'
// 																		xmlns='http://www.w3.org/2000/svg'
// 																	>
// 																		<polyline points='16 16 12 12 8 16' />
// 																		<line
// 																			x1={12}
// 																			y1={12}
// 																			x2={12}
// 																			y2={21}
// 																		/>
// 																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
// 																		<polyline points='16 16 12 12 8 16' />
// 																	</svg>
// 																</span>
// 																<p className='text-sm mt-2'>
// 																	Drag your images here
// 																</p>
// 																<em className='text-xs text-gray-400'>
// 																	(Only *.jpeg, *.webp and *.png images will be
// 																	accepted)
// 																</em>
// 															</div>
// 															<div className='text-green-500' />
// 															<aside className='flex flex-row flex-wrap mt-4' />
// 														</div>
// 													</div>
// 												</div>
// 												<div className='col-md-12'>
// 													<label>Section Details</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder='Section Details'
// 													/>
// 												</div>
// 											</div>

// 											<div class='row mt-3'>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='cancel-btn btn-lg'
// 													>
// 														Cancel
// 													</button>
// 												</div>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='add-last-btn btn-lg'
// 													>
// 														Update
// 													</button>
// 												</div>
// 											</div>
// 										</form>
// 									</div>
// 									<div
// 										class='tab-pane fade'
// 										id='v-pills-footer'
// 										role='tabpanel'
// 										aria-labelledby='v-pills-footer-tab'
// 									>
// 										<form>
// 											<div className='row g-3'>
// 												<div className='col-md-12'>
// 													<label>Copyright</label>
// 													<input
// 														type='password'
// 														id='inputPassword6'
// 														className='form-control-input'
// 														aria-labelledby='passwordHelpInline'
// 														placeholder=' © Copyright 2022 E-Market, All Rights reserved.'
// 													/>
// 												</div>
// 											</div>

// 											<div class='row mt-3'>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='cancel-btn btn-lg'
// 													>
// 														Cancel
// 													</button>
// 												</div>
// 												<div class='col-lg-6 col-md-12 py-3'>
// 													<button
// 														type='button'
// 														class='add-last-btn btn-lg'
// 													>
// 														Update
// 													</button>
// 												</div>
// 											</div>
// 										</form>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</section>
// 			</div>
// 		</>
// 	);
// }

// export default withAuth(Hmpage);



import React from 'react';
import withAuth from '../../../hooks/withAuth';
import { useForm } from 'react-hook-form';
import { FiEdit2 } from 'react-icons/fi'; // Example icon, change as needed
import StyledInput from '../../../Input/Style';
import { BsGenderAmbiguous } from "react-icons/bs";
import { MdAppRegistration } from "react-icons/md";
import { AiOutlineLogin } from "react-icons/ai";
import { GiMagicPortal } from "react-icons/gi";
 // Adjust the path as necessary

function Hmpage() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
    <>
      <div>
        <section>
          <div className="container py-4">
            <h4>Manage Content</h4>
            <div className="row">
              <div className="col-md-3">
                <div className="nav nav-pills bg-white h-100 border rounded me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                  <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Header</button>
                  <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Home Video</button>
                  <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">Home Ad Banner</button>
                  <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">Newsletter</button>
                  <button className="nav-link" id="v-pills-footer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-footer" type="button" role="tab" aria-controls="v-pills-footer" aria-selected="false">Footer</button>
                </div>
              </div>
              <div className="col-md-9">
                <div className="tab-content border bg-white px-5 py-4 rounded" id="v-pills-tabContent">
                  <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <StyledInput
                            type="text"

                            placeholderText="Become an Ambassado"
                            Icon={BsGenderAmbiguous}
                            register={register('button1Text')}
                            error={errors.button1Text}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"							
                            placeholderText="registration"
                            Icon={MdAppRegistration}
                            register={register('button1Link')}
                            error={errors.button1Link}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="Login"
                            Icon={AiOutlineLogin}
                            register={register('button2Text')}
                            error={errors.button2Text}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
							
							
                            placeholderText="portal/login"
                            Icon={GiMagicPortal}
                            register={register('button2Link')}
                            error={errors.button2Link}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="button" className="cancel-btn btn-lg">Cancel</button>
                        </div>
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="submit" className="add-last-btn btn-lg">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row gy-5" style={{gap:"3rem"}} >
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="Title"
                            Icon={FiEdit2}
                            register={register('videoTitle')}
                            error={errors.videoTitle}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="Video Link"
                            Icon={FiEdit2}
                            register={register('videoLink')}
                            error={errors.videoLink}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="button" className="cancel-btn btn-lg">Cancel</button>
                        </div>
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="submit" className="add-last-btn btn-lg">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <label>Banner 1</label>
                          <div className="col-span-8 sm:col-span-4">
                            <div className="w-full text-center">
                              <div className="border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4" role="button" tabIndex={0} style={{ borderStyle: 'dotted', borderColor: '#FFD700' }}>
                                <input accept="image/*" multiple type="file" className="" />
                                <span className="mx-auto flex justify-center">
                                  <svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-3xl text-green-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="16 16 12 12 8 16" />
                                    <line x1={12} y1={12} x2={12} y2={21} />
                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" />
                                    <polyline points="16 16 12 12 8 16" />
                                  </svg>
                                </span>
                                <p className="text-sm mt-2">Drag your images here</p>
                                <em className="text-xs text-gray-400">(Only *.jpeg, *.webp and *.png images will be accepted)</em>
                              </div>
                              <div className="text-green-500" />
                              <aside className="flex flex-row flex-wrap mt-4" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="URL 1"
                            Icon={FiEdit2}
                            register={register('banner1Url')}
                            error={errors.banner1Url}
                          />
                        </div>
                        <div className="col-md-12">
                          <label>Banner 2</label>
                          <div className="col-span-8 sm:col-span-4">
                            <div className="w-full text-center">
                              <div className="border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4" role="button" tabIndex={0} style={{ borderStyle: 'dotted', borderColor: '#FFD700' }}>
                                <input accept="image/*" multiple type="file" className="" />
                                <span className="mx-auto flex justify-center">
                                  <svg stroke="currentColor" fill="none" strokeWidth={2} viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round" className="text-3xl text-green-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                    <polyline points="16 16 12 12 8 16" />
                                    <line x1={12} y1={12} x2={12} y2={21} />
                                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3" />
                                    <polyline points="16 16 12 12 8 16" />
                                  </svg>
                                </span>
                                <p className="text-sm mt-2">Drag your images here</p>
                                <em className="text-xs text-gray-400">(Only *.jpeg, *.webp and *.png images will be accepted)</em>
                              </div>
                              <div className="text-green-500" />
                              <aside className="flex flex-row flex-wrap mt-4" />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="URL 2"
                            Icon={FiEdit2}
                            register={register('banner2Url')}
                            error={errors.banner2Url}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="button" className="cancel-btn btn-lg">Cancel</button>
                        </div>
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="submit" className="add-last-btn btn-lg">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="Title"
                            Icon={FiEdit2}
                            register={register('newsletterTitle')}
                            error={errors.newsletterTitle}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="email"
                            placeholderText="Description"
                            Icon={FiEdit2}
                            register={register('newsletterDescription')}
                            error={errors.newsletterDescription}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="button" className="cancel-btn btn-lg">Cancel</button>
                        </div>
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="submit" className="add-last-btn btn-lg">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="tab-pane fade" id="v-pills-footer" role="tabpanel" aria-labelledby="v-pills-footer-tab">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row g-3">
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="About Us"
                            Icon={FiEdit2}
                            register={register('aboutUs')}
                            error={errors.aboutUs}
                          />
                        </div>
                        <div className="col-md-12">
                          <StyledInput
                            type="text"
                            placeholderText="Contacts"
                            Icon={FiEdit2}
                            register={register('contacts')}
                            error={errors.contacts}
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="button" className="cancel-btn btn-lg">Cancel</button>
                        </div>
                        <div className="col-lg-6 col-md-12 py-3">
                          <button type="submit" className="add-last-btn btn-lg">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default withAuth(Hmpage);

