import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ApiUrl from '../BaseUrl';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import BannerModal from './BannerModal/BannerModal';
import BannerTable from './BannerTable/BannerTable';
import withAuth from '../../../hooks/withAuth';

function Banner() {
	const [stocks, setStocks] = useState([]);
	const [sellerid, setSellerID] = useState(null);

	const [singleStock, setSingleStock] = useState();

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage Banners</h5>
								<BannerModal
									actionLabel='Add Banner'
									title='Banner Title'
									ApiUrl={ApiUrl}
									setStocks={setStocks}
									singleStock={singleStock}
									sellerid={sellerid}
								/>
								<BannerTable />
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Banner);
