import axios from 'axios';
import React, { useEffect } from 'react';
import ApiUrl from '../BaseUrl';

const AllCanvas = ({ orderDetails }) => {
	return (
		<div>
			{orderDetails && (
				<>
					{/* <MdArrowBackIosNew className="orderListBackBtn" onClick={fn_backBtn} /> */}
					<h4 className='my-4'>Order Details</h4>
					<div>
						<table className='orderDetailsTxt'>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Order ID</th>
								<td>{orderDetails?._id}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Order Date</th>
								<td>{new Date(orderDetails?.createdAt).toDateString()}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>User</th>
								<td>{orderDetails?.userName}</td>
							</tr>
							<tr className='orderDetailsTxttr'>
								<th className='detailOrdersTableData'>Shipping Address</th>
								<td>
									<p className='detailOrdersAddress fw-bold'>Current Address</p>
									<p className='detailOrdersAddress'>
										{orderDetails?.shippingDetails?.currentAddress}
									</p>
									<p className='detailOrdersAddress fw-bold'>
										Permanent Address
									</p>
									<p className='detailOrdersAddress'>
										{orderDetails?.shippingDetails?.permanentAddress}
									</p>
								</td>
							</tr>

							{orderDetails?.ProductsOrder?.map((item) => (
								<tr className='orderDetailsTxttr'>
									<td className='detailOrdersTableData'>
										<div>
											<img
												src={`${ApiUrl}/images/${item?.image}`}
												style={{ height: '80px' }}
											/>
										</div>
									</td>
									<td>
										<p className='detailOrdersAddress fw-bold'>{item?.name}</p>
										<p className='detailOrdersAddress'>
											Unit Price : <b>${item?.retailPrice}</b>
										</p>
										<p className='detailOrdersAddress'>
											Qty : <b>${item?.addToCartQty}</b>
										</p>
									</td>
								</tr>
							))}
						</table>
					</div>
				</>
			)}
		</div>
	);
};

export default AllCanvas;
