import { useState } from 'react';
import Modal from './Modal';
import Tabs from './Tabs';
import './style.css';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import RentTable from './RentTable';
import { Button } from 'bootstrap';
import RentTable2 from './RentTable2';
import withAuth from '../../../hooks/withAuth';

const Charges = () => {
	const [mainCharges, setMainStockcharges] = useState('');
	const [racksCharges, setRacksCharges] = useState('');
	const [displayCharges, setDisplayCharges] = useState('');
	const [rentalCharges, setRentalCharges] = useState('');
	const [newTable2, setNewTable2] = useState('rentalRackCharges');
	console.log(rentalCharges);

	return (
		<div className='p-1 m-1'>
			<h5 className='kk ps-3 my-4'>Charges Detail</h5>

			<ul
				class='nav mb-3 d-flex modal_wrap'
				id='modal-tab'
				role='tablist'
			>
				{/* <Modal
          title="Main Stock Charges"
          id="mainStock"
          label="mainStockLabel"
          setCharges={setMainStockcharges}
          name="setMainStockCharges"
          value={mainCharges}
        /> */}

				{/* <Modal
					title='Rental Racks Charges'
					id='rental'
					label='rentalLabel'
					setCharges={setRentalCharges}
					name='rentalRacksCharges'
					value={rentalCharges}
					style={{ backgroundColor: '#ffd700' }}
				/>

				<Modal
					title='Display Charges'
					id='display'
					label='displayLabel'
					setCharges={setDisplayCharges}
					name='rentalDisplaysCharges'
					value={displayCharges}
					style={{ backgroundColor: '#ffd700' }}
				/> */}

				{/* <Modal
          title="Racks Charges"
          id="racks"
          label="racksLabel"
          setCharges={setRacksCharges}
          name="setRackCharges"
          value={racksCharges}
        /> */}
			</ul>
			<ul
				class='nav mb-3 d-flex'
				id='pills-tab'
				role='tablist'
				style={{ gap: '12px', justifyContent: 'flex-end' }}
			>
				<button
					className='btn '
					onClick={() => setNewTable2('rentalRackCharges')}
					style={{ backgroundColor: '#ffd700' }}
				>
					Rental Rack Charges
				</button>
				<button
					className='btn'
					onClick={() => setNewTable2('displayCharges')}
					style={{ backgroundColor: '#ffd700' }}
				>
					Display Charges
				</button>
			</ul>

			{newTable2 === 'rentalRackCharges' && <RentTable2 />}
			{newTable2 === 'displayCharges' && <RentTable />}
		</div>
	);
};

export default withAuth(Charges);
