import React, { useEffect, useState } from 'react';
import './wallet.css';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import WalletHistory from './WalletHistory';
import withAuth from '../../../hooks/withAuth';

const Wallet = () => {
	const [adminData, setAdminData] = useState('');
	const handleAdmin = () => {};

	return (
		<>
			<div className='wallet_container'>
				<h2 className='text-success mt-5 text-center'>
					Available Balance: ${parseInt(adminData).toFixed(3)}
				</h2>
				<WalletHistory />
			</div>
		</>
	);
};

export default withAuth(Wallet);
