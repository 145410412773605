import React, { useState, useEffect } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { message, Modal } from "antd";
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { Modal } from 'react-bootstrap';
import withAuth from '../../../hooks/withAuth';
import { deleteStudent } from './delete';

// import { message, Modal } from 'antd';
function Ambass() {
	const [checkValue, setCheckValue] = useState(false);
	const [ambDetails, setAmbDetails] = useState();
	const [categories, setCategories] = useState([]);
	const [category, setCategory] = useState([]);
	const [singleStock, setSingleStock] = useState({});
	const [show, setShow] = useState(false);
	const [qc, setQc] = useState([]);

	const sendData = (values) => {};
	const updateData = (values) => {};
	// const onDeleteStudent = (id) => {
	// 	Modal.confirm({
	// 		title: 'Are you sure you want to delete?',
	// 		onOk: () => {},
	// 	});
	// };

	const onDeleteStudent = (id) => {
		console.log('==========================', id);
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`http://localhost:3000/admin/deleteBrand/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('brand deleted successful');
						toast.success('brand deleted successful');
					} else {
						console.error('brand deleted failed');
						toast.error('brand deleted failed');
					}
				});
			},
		});
	};
	useEffect(() => {
		const fetchData = async () => {
			const result = await fetch(
				'http://localhost:3000/admin/qualityControllers'
			);
			const body = await result.json();

			console.log('body', body.data);
			setQc(body.data);
		};

		fetchData();
	}, []);

	console.log(ambDetails);
	const fn_approveAmb = (id) => {};
	const fn_deleteAmb = (id) => {
		fetch(`http://localhost:3000/admin/deleteController/${id}`, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
		}).then((res) => {
			console.log('res', res);
			if (res.ok) {
				console.log('brand deleted successful');
				toast.success('Quality controller deleted successful');
			} else {
				console.error('brand deleted failed');
				toast.error('Quality controller deleted failed');
			}
		});
	};
	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<Modal
							show={show}
							onHide={() => setShow(false)}
							dialogClassName='modal-100w modal-lg'
							aria-labelledby='example-custom-modal-styling-title'
						>
							<Modal.Header closeButton>
								<Modal.Title id='example-custom-modal-styling-title'>
									Ambassador Details
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className='text-start'>
								<div className='row'>
									<div className='col-7'>
										<h4
											style={{
												textTransform: 'capitalize',
												marginBottom: '1rem',
											}}
										>
											{ambDetails?.username}
										</h4>
										<ul>
											<li>Reg ID : {ambDetails?._id}</li>
											<li>Email : {ambDetails?.email}</li>
											<li>
												Contact No. : {ambDetails?.code} {ambDetails?.phoneNo}
											</li>
											<li style={{ textTransform: 'capitalize' }}>
												Gender : {ambDetails?.gender}
											</li>
											<li>Country : {ambDetails?.country}</li>
											<li>Address : {ambDetails?.address}</li>
											<li>Youtube URL : {ambDetails?.video}</li>
										</ul>
									</div>
									<div className='col-5'>
										<img
											src={`${ApiUrl}/images/${ambDetails?.profileImg}`}
											alt='ambImg'
											style={{ width: '100%' }}
										/>
									</div>
								</div>
								<hr />
								<ul>
									<li>Selected package : {ambDetails?.package}</li>
									<li>Join As : {ambDetails?.joining}</li>
									<li>General Skill : {ambDetails?.generalSkills}</li>
									<li>Marketing Skill : {ambDetails?.marketingSkills}</li>
									<li>Experience : {ambDetails?.experience}</li>
									<li>Education Level : {ambDetails?.education}</li>
								</ul>
								<hr />
								<h5>Banking Details:</h5>
								<ul>
									<li>Bank Name : {ambDetails?.bankName}</li>
									<li>Bank Account No. : {ambDetails?.bankNo}</li>
									<li>Bank Account Name : {ambDetails?.bankTitle}</li>
								</ul>
								<hr />
								<h5>Id Card Information:</h5>
								<div className='row'>
									<div className='col-md-6'>
										<img
											src={`${ApiUrl}/images/${ambDetails?.frontCnic}`}
											style={{ width: '100%' }}
										/>
									</div>
									<div className='col-md-6'>
										<img
											src={`${ApiUrl}/images/${ambDetails?.backCnic}`}
											style={{ width: '100%' }}
										/>
									</div>
								</div>
								<hr />
								<h5>CV Image:</h5>
								<div>
									<img
										src={`${ApiUrl}/images/${ambDetails?.cvImg}`}
										style={{ width: '100%' }}
									/>
								</div>
								<hr />
								{ambDetails && ambDetails?.statusType === 'pending' ? (
									<button
										type='submit'
										className='btn btn-success'
										onClick={() => {
											fn_approveAmb(ambDetails?._id);
											setShow(false);
										}}
									>
										Approve
									</button>
								) : (
									<button
										type='submit'
										className='btn btn-success'
										onClick={() => setShow(false)}
									>
										Close
									</button>
								)}
								<button
									type='submit'
									className='btn btn-danger mx-3'
									onClick={() => {
										fn_deleteAmb(ambDetails?._id);
										setShow(false);
									}}
								>
									Delete
								</button>
							</Modal.Body>
						</Modal>
						<div className='main-wrapper'>
							<div className='container-fluid py-4'>
								<h5 className='kk ps-3'>Manage Quality controller</h5>
								<div className='row pb-4'>
									<div className='col-md-12'>
										<div className='counterbox'>
											Total Quality controller{' '}
											<span className='badge badge-primary'>
												{categories?.length}
											</span>
										</div>{' '}
										<div className='counterbox'>
											Under Training Quality controller{' '}
											<span className='badge badge-primary'>7</span>
										</div>{' '}
										<div className='counterbox'>
											Active Quality controller{' '}
											<span className='badge badge-primary'>
												{
													(categories?.filter(
														(cat) => cat?.statusType === 'approved'
													)).length
												}
											</span>
										</div>{' '}
										<div className='counterbox'>
											In Active Quality controller{' '}
											<span className='badge badge-primary'>
												{
													(categories?.filter(
														(cat) => cat?.statusType === 'pending'
													)).length
												}
											</span>
										</div>{' '}
									</div>
								</div>

								<ul
									class='nav nav-pills mb-3 d-flex '
									id='pills-tab'
									role='tablist'
									style={{ gap: '12px' }}
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-profile-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-profile'
											type='button'
											role='tab'
											aria-controls='pills-profile'
											aria-selected='false'
										>
											All Quality controller
										</button>
									</li>
								</ul>

								{/* <div
									class='modal fade'
									id='exampleModal'
									tabindex='-1'
									aria-labelledby='exampleModalLabel'
									aria-hidden='true'
								>
									<div class='modal-dialog'>
										<div class='modal-content py-4'>
											<div class='modal-body'>
												<div className='modal-icon'>
													<span
														style={{ fontSize: 35, color: '#f05252' }}
														className='px-3'
													>
														<RiDeleteBinLine />
													</span>
												</div>
												<h1 class='title py-3'>
													Are You Sure! Want to Delete{' '}
													<span style={{ color: '#f05252' }}>
														Selected Products?
													</span>
												</h1>
												<p className='para'>
													Do you really want to delete these records? You can't
													view this in <br /> your list anymore if you delete!
												</p>
												...
												<div className='last-btn'>
													<button
														type='button'
														class='btn  btn-light mx-4 py-2 px-4'
														data-bs-dismiss='modal'
													>
														No,Keep It
													</button>
													<button
														onClick={() => {
															const id = localStorage.getItem('sellerID');

															const params = {
																id: id,
															};

															axios
																.delete(
																	'http://192.168.18.130:3003/embassador/deleteByID?id=' +
																		id
																)
																.then((res) => {
																	console.log(res.data);

																	toast('ambassador updated successfully');
																});
														}}
														type='button'
														class='btn btn-success py-2 px-4'
													>
														Yes, Delete It
													</button>
												</div>
											</div>
										</div>
									</div>
								</div> */}

								<div
									className='offcanvas offcanvas-end'
									tabIndex={-1}
									id='offcanvasRight'
									aria-labelledby='offcanvasRightLabel'
								>
									<div className='offcanvas-header px-4'>
										<div className='content-header'>
											<h4> Manage Ambassador Create / Update</h4>
											<p>Update products info, combinations and extras.</p>
										</div>

										<button
											type='button'
											className='btn-close bg-white shadow rounded-5 text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											style={{
												width: '5px',
												height: '30px',
												opacity: '0.8',
												fontSize: '12px',
											}}
										/>
									</div>
									{/* Canvas */}

									<div className='offcanvas-body p-0'>
										<div className='form-data'>
											<div className='wrap-data p-5'>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														sendData(e.target);
													}}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Experience Status
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='experienceStatus'
																id='experience'
																class='form-control-input-2'
															>
																<option selected>
																	--select your experience status--
																</option>
																<option value='Under Training'>
																	Under Training
																</option>
																<option value='Verified'>Verified</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Service Post
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='servicePost'
																id='servicePost'
																class='form-control-input-2'
															>
																<option selected>
																	--Select your service post--
																</option>
																<option value='Trainer'>Trainer</option>
																<option value='City Head'>City Head</option>
																<option value='Chief Ambassador'>
																	Chief Ambassador
																</option>
																<option value='Expo Organiser'>
																	Expo Organiser
																</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Your Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='name'
																name='name'
																className='form-control-input'
																placeholder='Enter Your Name'
																defaultValue={singleStock?.name}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Your Email
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='email'
																name='email'
																className='form-control-input'
																placeholder='Enter Your Email'
																defaultValue={singleStock?.email}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Contact Number
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Enter Your Number'
																defaultValue={singleStock?.phoneNo}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Password
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Enter Your Password'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Profile Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			accept='image/*'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Youtube Video Id
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='youtubeVideoID'
																className='form-control-input'
																placeholder='Youtube Video Link'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Status
															</label>
														</div>
														<div className='col-md-8'>
															<select class='form-control-input-2'>
																<option value='1'>Active</option>
																<option value='1'>Inactive</option>
															</select>
														</div>
													</div>

													<h3 className='pt-5'>Additional Information</h3>
													<hr />
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Joining as
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='memberType'
																class='form-control-input-2'
															>
																<option value='Ambassador'>Ambassador</option>
																<option value='Participent'>Participent</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Highest Qualification
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='highestQualification'
																name='highestQualification'
																className='form-control-input'
																placeholder='highest qualification'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Gender Skills
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='generalSkills'
																className='form-control-input'
																placeholder=' Gender Skills'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Marketing Skills
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='marketingSkills'
																name='marketingSkills'
																className='form-control-input'
																placeholder=' Gender Skills'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Before Experience
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Before Experience'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Country
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='country'
																id='country'
																class='form-control-input-2'
															>
																<option selected>Pakistan</option>
																<option value='1'>China</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																City
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='city'
																id='city'
																class='form-control-input-2'
															>
																<option selected>Sargodha</option>
																<option value='1'>Lahore</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Categories
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='productCategories'
																id='productCategories'
																class='form-control-input-2'
															>
																<option
																	selected
																	disabled
																	value={''}
																>
																	---Select Category---
																</option>
																{category &&
																	category?.map((cat) => (
																		<option value={cat?._id}>
																			{cat?.title}
																		</option>
																	))}
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Id Card Front Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='idCardFrontImage'
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Id Card Back Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='idCardBackImage'
																			id='idCardBackImage'
																			accept='image/*'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																CV Upload
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='cvImage'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Account Title
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='bankAccoutTitle'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder=' 
                                        Bank Account Title'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Account Number
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='bankAccountNo'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='   
                                        Bank Account Number'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='bankName'
																name='bankName'
																className='form-control-input'
																placeholder='     Bank Name'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Branch Code
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='branchCode'
																name='branchCode'
																className='form-control-input'
																placeholder='    Bank Branch Code '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccount'
																className='form-control-input'
																placeholder='Mobile Account Title '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account Title
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccountTitle'
																className='form-control-input'
																placeholder='         Mobile Account Title '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account No
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccountNo'
																className='form-control-input'
																placeholder='          Mobile Account No '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Describe Yourself
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='discribeYourself'
																id='discribeYourself'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='          Mobile Account No '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																registration no
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='
                                        registration  no'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Chamber of commerce & Industry Registration no
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='
                                        '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Business Logo (pdf)
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='businessLogo'
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div
										className='offcanvas offcanvas-end'
										tabIndex={-1}
										id='offcanvasRight'
										aria-labelledby='offcanvasRightLabel'
									>
										<div className='offcanvas-header px-4'>
											<div className='content-header'>
												<h4>Manage Posts Create / Update</h4>
												<p>Update products info, combinations and extras.</p>
											</div>

											<button
												type='button'
												className='btn-close bg-white shadow rounded-5 text-reset'
												data-bs-dismiss='offcanvas'
												aria-label='Close'
												style={{
													width: '5px',
													height: '30px',
													opacity: '0.8',
													fontSize: '12px',
												}}
											/>
										</div>
										{/* Canvas */}
										<div className='offcanvas-body p-0'>
											<div className='form-data'>
												<div className='wrap-data p-5'>
													<form>
														<div className='row g-4 pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Post Title
																</label>
															</div>
															<div className='col-md-8'>
																<input
																	type='password'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='Title'
																/>
															</div>
														</div>

														<div className='row  pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Status
																</label>
															</div>
															<div className='col-md-8'>
																<select
																	class='form-control-input-2'
																	aria-label='Default select example'
																>
																	<option value='1'>Active</option>
																	<option value='1'>Inactive</option>
																</select>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
										<div className='offcanvas-footer px-5 py-3'>
											<div className='content-footer'>
												<div className='row'>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='cancel-btn btn-lg'
														>
															Cancel
														</button>
													</div>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														>
															Save
														</button>
													</div>
												</div>
											</div>
										</div>
										{/* Canvas */}
									</div>

									{/* Canvas */}
								</div>
								<div
									className='offcanvas offcanvas-end'
									tabIndex={-1}
									id='offcanvasRightupdate'
									aria-labelledby='offcanvasRightLabel'
								>
									<div className='offcanvas-header px-4'>
										<div className='content-header'>
											<h4>Manage Ambassador Create / Update</h4>
											<p>Update products info, combinations and extras.</p>
										</div>

										<button
											type='button'
											className='btn-close bg-white shadow rounded-5 text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											style={{
												width: '5px',
												height: '30px',
												opacity: '0.8',
												fontSize: '12px',
											}}
										/>
									</div>
									{/* Canvas */}

									<div className='offcanvas-body p-0'>
										<div className='form-data'>
											<div className='wrap-data p-5'>
												<form
													onSubmit={(e) => {
														e.preventDefault();
														updateData(e.target);
													}}
												>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Experience Status
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='experienceStatus'
																id='experience'
																class='form-control-input-2'
															>
																<option value='1'>Under Training</option>
																<option value='1'>Verified</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Service Post
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='servicePost'
																id='servicePost'
																class='form-control-input-2'
															>
																<option value='Trainer'>Trainer</option>
																<option value='City Head'>City Head</option>
																<option value='Chief Ambassador'>
																	Chief Ambassador
																</option>
																<option value='Expo Organiser'>
																	Expo Organiser
																</option>
															</select>
														</div>
													</div>

													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Your Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='name'
																name='name'
																className='form-control-input'
																placeholder='Enter Your Name'
																defaultValue={singleStock?.name}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Your Email
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='email'
																name='email'
																className='form-control-input'
																placeholder='Enter Your Email'
																defaultValue={singleStock?.email}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Contact Number
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Enter Your Number'
																defaultValue={singleStock?.phoneNo}
															/>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Password
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Enter Your Password'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Profile Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			accept='image/*'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Youtube Video Id
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='youtubeVideoID'
																className='form-control-input'
																placeholder='Youtube Video Link'
															/>
														</div>
													</div>

													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Status
															</label>
														</div>
														<div className='col-md-8'>
															<select class='form-control-input-2'>
																<option value='1'>Active</option>
																<option value='1'>Inactive</option>
															</select>
														</div>
													</div>

													<h3 className='pt-5'>Additional Information</h3>
													<hr />
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Joining as
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='memberType'
																class='form-control-input-2'
															>
																<option value='Ambassador'>Ambassador</option>
																<option value='Participent'>Participent</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Highest Qualification
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='highestQualification'
																name='highestQualification'
																className='form-control-input'
																placeholder='highest qualification'
																defaultValue={singleStock?.highestQualification}
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Gender Skills
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='generalSkills'
																className='form-control-input'
																placeholder=' Gender Skills'
																defaultValue={singleStock?.generalSkills}
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Marketing Skills
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='marketingSkills'
																name='marketingSkills'
																className='form-control-input'
																placeholder=' Gender Skills'
																defaultValue={singleStock?.marketingSkills}
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Before Experience
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																placeholder='Before Experience'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Country
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='country'
																id='country'
																class='form-control-input-2'
																defaultValue={singleStock?.country}
															>
																<option selected>Pakistan</option>
																<option value='1'>China</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																City
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='city'
																id='city'
																class='form-control-input-2'
																defaultChecked={singleStock?.city}
															>
																<option selected>Sargodha</option>
																<option value='1'>Lahore</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Product Categories
															</label>
														</div>
														<div className='col-md-8'>
															<select
																name='productCategories'
																id='productCategories'
																class='form-control-input-2'
																defaultValue={singleStock?.productCategories}
															>
																<option selected>All Categories</option>
																<option value='1'>Men Fashion</option>
															</select>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Id Card Front Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='idCardFrontImage'
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Id Card Back Image
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='idCardBackImage'
																			id='idCardBackImage'
																			accept='image/*'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																CV Upload
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='cvImage'
																			multiple=''
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Account Title
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='bankAccoutTitle'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder=' 
                                        Bank Account Title'
																defaultValue={singleStock?.bankAccoutTitle}
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Account Number
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='bankAccountNo'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='   
                                        Bank Account Number'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Name
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='bankName'
																name='bankName'
																className='form-control-input'
																placeholder='     Bank Name'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Bank Branch Code
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='branchCode'
																name='branchCode'
																className='form-control-input'
																placeholder='    Bank Branch Code '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccount'
																className='form-control-input'
																placeholder='Mobile Account Title '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account Title
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccountTitle'
																className='form-control-input'
																placeholder='         Mobile Account Title '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Mobile Account No
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='mobileAccountNo'
																className='form-control-input'
																placeholder='          Mobile Account No '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Describe Yourself
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																name='discribeYourself'
																id='discribeYourself'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='          Mobile Account No '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																registration no
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='
                                        registration  no'
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Chamber of commerce & Industry Registration no
															</label>
														</div>
														<div className='col-md-8'>
															<input
																type='text'
																id='inputPassword6'
																className='form-control-input'
																aria-labelledby='passwordHelpInline'
																placeholder='
                                        '
															/>
														</div>
													</div>
													<div className='row  pt-4  align-items-center'>
														<div className='col-md-4'>
															<label
																for='inputPassword6'
																className='form-label'
															>
																Business Logo (pdf)
															</label>
														</div>
														<div className='col-md-8'>
															<div className='col-span-8 sm:col-span-4'>
																<div className='w-full text-center'>
																	<div
																		className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																		role='button'
																		tabIndex={0}
																		style={{
																			borderStyle: 'dotted',
																			borderColor: '#FFD700',
																		}}
																	>
																		<input
																			name='businessLogo'
																			type='file'
																		/>
																		<span className='mx-auto flex justify-center'>
																			<svg
																				stroke='currentColor'
																				fill='none'
																				strokeWidth={2}
																				viewBox='0 0 24 24'
																				strokeLinecap='round'
																				strokeLinejoin='round'
																				className='text-3xl text-green-500'
																				height='1em'
																				width='1em'
																				xmlns='http://www.w3.org/2000/svg'
																			>
																				<polyline points='16 16 12 12 8 16' />
																				<line
																					x1={12}
																					y1={12}
																					x2={12}
																					y2={21}
																				/>
																				<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																				<polyline points='16 16 12 12 8 16' />
																			</svg>
																		</span>
																		<p className='text-sm mt-2'>
																			Drag your images here
																		</p>
																		<em className='text-xs text-gray-400'>
																			(Only *.jpeg, *.webp and *.png images will
																			be accepted)
																		</em>
																	</div>
																	<div className='text-green-500' />
																	<aside className='flex flex-row flex-wrap mt-4' />
																</div>
															</div>
														</div>
													</div>
													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='submit'
																		class='add-last-btn btn-lg'
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>

									<div
										className='offcanvas offcanvas-end'
										tabIndex={-1}
										id='offcanvasRight'
										aria-labelledby='offcanvasRightLabel'
									>
										<div className='offcanvas-header px-4'>
											<div className='content-header'>
												<h4>Manage Posts Create / Update</h4>
												<p>Update products info, combinations and extras.</p>
											</div>

											<button
												type='button'
												className='btn-close bg-white shadow rounded-5 text-reset'
												data-bs-dismiss='offcanvas'
												aria-label='Close'
												style={{
													width: '5px',
													height: '30px',
													opacity: '0.8',
													fontSize: '12px',
												}}
											/>
										</div>
										{/* Canvas */}
										<div className='offcanvas-body p-0'>
											<div className='form-data'>
												<div className='wrap-data p-5'>
													<form>
														<div className='row g-4 pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Post Title
																</label>
															</div>
															<div className='col-md-8'>
																<input
																	type='password'
																	id='inputPassword6'
																	className='form-control-input'
																	aria-labelledby='passwordHelpInline'
																	placeholder='Title'
																/>
															</div>
														</div>

														<div className='row  pt-4  align-items-center'>
															<div className='col-md-4'>
																<label
																	for='inputPassword6'
																	className='form-label'
																>
																	Status
																</label>
															</div>
															<div className='col-md-8'>
																<select
																	class='form-control-input-2'
																	aria-label='Default select example'
																>
																	<option value='1'>Active</option>
																	<option value='1'>Inactive</option>
																</select>
															</div>
														</div>
													</form>
												</div>
											</div>
										</div>
										<div className='offcanvas-footer px-5 py-3'>
											<div className='content-footer'>
												<div className='row'>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='cancel-btn btn-lg'
														>
															Cancel
														</button>
													</div>
													<div className='col-lg-6 col-md-12 py-3'>
														<button
															type='button'
															class='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														>
															Save
														</button>
													</div>
												</div>
											</div>
										</div>
										{/* Canvas */}
									</div>

									{/* Canvas */}
								</div>

								<div
									class='tab-content'
									id='pills-tabContent'
								>
									{/* <div
										class='tab-pane fade show active'
										id='pills-home'
										role='tabpanel'
										aria-labelledby='pills-home-tab'
									>
										<div className='min-box my-4'>
											<div className='products-table table-responsive'>
												<table
													className='table border rounded align-middle'
													style={{ borderColor: '#4f4f51' }}
												>
													<thead className='bg-light'>
														<tr>
															<th>#Id</th>
															<th>Image</th>
															<th>Name</th>
															<th>Email</th>
															<th>Gender</th>
															<th>Number</th>
															<th>Location</th>
															<th>Status</th>
															<th>ACTIONS</th>
														</tr>
													</thead>
													<tbody>
														{qc?.map((item, index) => {
															if (item?.statusType === 'pending') {
																return (
																	<>
																		<tr>
																			<td>{index + 1}</td>
																			<td>
																				<div className='prod-img'>
																					<img
																						src={`${ApiUrl}/images/${item?.profileImg}`}
																					/>
																				</div>
																			</td>
																			<td
																				style={{ textTransform: 'capitalize' }}
																			>
																				{item?.username}
																			</td>
																			<td>{item?.email}</td>
																			<td
																				style={{ textTransform: 'capitalize' }}
																			>
																				{item?.gender}
																			</td>
																			<td>{item?.phoneNo}</td>
																			<td>{item?.city}</td>
																			<td>
																				<span
																					className='text-ligh  '
																					style={{
																						color: '#278a1a',
																						fontSize: '10px',
																						backgroundColor: '#F7C600',
																						borderRadius: 20,
																						padding: '5px 10px',
																					}}
																				>
																					{item?.statusType}
																				</span>
																			</td>
																			<td>
																				{/* <div className="actions">
                                          <button
                                            onClick={() => {
                                              localStorage.setItem(
                                                "myUserId",
                                                item?._id
                                              );
                                              axios.get(`${ApiUrl}/embassador/getById?id=` + item?._id).then((res) => {
                                                console.log(res.data, 'singleStock');
                                                setSingleStock(res.data);
                                              })
                                            }}
                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRight"
                                            aria-controls="offcanvasRight"
                                            class="btn btn-sm btn-primary py-1"
                                          >
                                            <i class="fa fa-file-invoice"></i>{" "}
                                            View Details
                                          </button>
                                        </div> 
																				<div className='actions'>
																					<button
																						type='button'
																						class='btn btn-sm btn-primary py-1'
																						onClick={() => {
																							setAmbDetails(item);
																							setShow(true);
																						}}
																					>
																						<i class='fa fa-file-invoice'></i>
																						&nbsp;View Details
																					</button>
																				</div>
																			</td>
																		</tr>
																	</>
																);
															}
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div> */}
									<div
										class='tab-pane fade'
										id='pills-profile'
										role='tabpanel'
										aria-labelledby='pills-profile-tab'
									>
										<div className='min-box my-4'>
											<div className='products-table table-responsive'>
												<table
													className='table border rounded align-middle'
													style={{ borderColor: '#4f4f51' }}
												>
													<thead className='bg-light'>
														<tr>
															<th>#I23d</th>
															<th>Image</th>
															<th>Name</th>
															<th>Email</th>
															<th>Gender</th>
															<th>Number</th>
															<th>Location</th>
															<th>Status</th>
															<th>ACTIONS</th>
														</tr>
													</thead>
													<tbody>
														{qc?.map((item, index) => {
															if (item) {
																return (
																	<>
																		<tr>
																			<td>{index + 1}</td>
																			<td>
																				{' '}
																				<div className='prod-img'>
																					<img
																						src={`${ApiUrl}/images/${item?.profileImg}`}
																						alt=''
																					/>
																				</div>
																			</td>
																			<td
																				style={{ textTransform: 'capitalize' }}
																			>
																				{item?.yourName}
																			</td>
																			<td>{item?.yourEmail}</td>
																			<td
																				style={{ textTransform: 'capitalize' }}
																			>
																				{item?.gender}
																			</td>
																			<td>{item?.mobileAccount}</td>
																			<td>{item?.city}</td>
																			<td>
																				<span
																					className='text-ligh  '
																					style={{
																						color: '#278a1a',
																						fontSize: '10px',
																						backgroundColor: '#41ff2848',
																						borderRadius: 20,
																						padding: '5px 10px',
																					}}
																				>
																					{item?.statusType}
																				</span>
																			</td>

																			<td>
																				<div className='actions d-flex'>
																					<span
																						onClick={() => {
																							localStorage.setItem(
																								'mySellerId',
																								item?._id
																							);
																							axios
																								.get(
																									`${ApiUrl}/embassador/getById?id=` +
																										item?._id
																								)
																								.then((res) => {
																									console.log(
																										res.data,
																										'singleStock'
																									);

																									setSingleStock(res.data);
																								});
																						}}
																						data-bs-toggle='offcanvas'
																						data-bs-target='#offcanvasRightupdate'
																						aria-controls='offcanvasRightupdate'
																						style={{ fontSize: 21 }}
																					>
																						<FiEdit />
																					</span>
																					<span
																						style={{ fontSize: 21 }}
																						className='px-3'
																						onClick={() => {
																							fn_deleteAmb(item?._id);
																						}}
																					>
																						<RiDeleteBinLine />
																					</span>
																					{/* <deleteStudent id={item?._id} /> */}
																				</div>
																				<div className='actions'>
																					<button
																						type='button'
																						class='btn btn-sm btn-primary py-1'
																						onClick={() => {
																							setAmbDetails(item);
																							setShow(true);
																						}}
																					>
																						<i class='fa fa-file-invoice'></i>
																						&nbsp;View Details
																					</button>
																				</div>
																			</td>
																		</tr>
																	</>
																);
															}
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Ambass);
