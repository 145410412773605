import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { useState } from 'react';
import { toast } from 'react-toastify';
import withAuth from '../../../hooks/withAuth';

function Settings() {
	const [file, setFile] = useState(null);

	async function fn_handleLogoUpload(event) {
		event.preventDefault();
		try {
			const formData = new FormData();
			formData.append('logoImg', file);
			await axios.post(`${ApiUrl}/admin/addLogo`, formData);
			toast.success('Logo has been uploaded');
		} catch (error) {
			toast.error('Server Error');
		}
	}
	return (
		<>
			<div>
				<section>
					<div className='container py-4'>
						<h4>Manage Content </h4>
						<div className='row'>
							<div className='col-md-3'>
								<div
									class='nav  nav-pills bg-white h-100 border rounded me-3'
									id='v-pills-tab'
									role='tablist'
									aria-orientation='vertical'
								>
									<button
										class='nav-link active'
										id='v-pills-home-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-home'
										type='button'
										role='tab'
										aria-controls='v-pills-home'
										aria-selected='true'
									>
										Logo Settings
									</button>
									<button
										class='nav-link'
										id='v-pills-profile-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-profile'
										type='button'
										role='tab'
										aria-controls='v-pills-profile'
										aria-selected='false'
									>
										Home Video
									</button>
									<button
										class='nav-link'
										id='v-pills-messages-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-messages'
										type='button'
										role='tab'
										aria-controls='v-pills-messages'
										aria-selected='false'
									>
										Social Media
									</button>
									<button
										class='nav-link'
										id='v-pills-settings-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-settings'
										type='button'
										role='tab'
										aria-controls='v-pills-settings'
										aria-selected='false'
									>
										Content Info
									</button>
									<button
										class='nav-link'
										id='v-pills-footer-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-footer'
										type='button'
										role='tab'
										aria-controls='v-pills-footer'
										aria-selected='false'
									>
										Account Settings
									</button>
									<button
										class='nav-link'
										id='v-pills-SMTP-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-SMTP'
										type='button'
										role='tab'
										aria-controls='v-pills-SMTP'
										aria-selected='false'
									>
										SMTP Settings
									</button>
									<button
										class='nav-link'
										id='v-pills-Templates-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-Templates'
										type='button'
										role='tab'
										aria-controls='v-pills-Templates'
										aria-selected='false'
									>
										Email Templates
									</button>
									<button
										class='nav-link'
										id='v-pills-Templates-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-Templates'
										type='button'
										role='tab'
										aria-controls='v-pills-Templates'
										aria-selected='false'
									>
										Meta Tags
									</button>
									<button
										class='nav-link'
										id='v-pills-Settings-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-Settings'
										type='button'
										role='tab'
										aria-controls='v-pills-Settings'
										aria-selected='false'
									>
										Scripts Settings
									</button>
									<button
										class='nav-link'
										id='v-pills-Captcha-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-Captcha'
										type='button'
										role='tab'
										aria-controls='v-pills-Captcha'
										aria-selected='false'
									>
										Captcha
									</button>
									<button
										class='nav-link'
										id='v-pills-Backup-tab'
										data-bs-toggle='pill'
										data-bs-target='#v-pills-Backup'
										type='button'
										role='tab'
										aria-controls='v-pills-Backup'
										aria-selected='false'
									>
										Database Backup
									</button>
								</div>
							</div>
							<div className='col-md-9'>
								<div
									class='tab-content border bg-white px-5 py-4 rounded'
									id='v-pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='v-pills-home'
										role='tabpanel'
										aria-labelledby='v-pills-home-tab'
									>
										<form onSubmit={fn_handleLogoUpload}>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>Logo Image</label>
													<div className='col-span-8 sm:col-span-4'>
														<div className='w-full text-center'>
															<div
																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																role='button'
																tabIndex={0}
																style={{
																	borderStyle: 'dotted',
																	borderColor: '#FFD700',
																}}
															>
																<input
																	accept='image/*'
																	multiple=''
																	type='file'
																	className=''
																	onChange={(e) => setFile(e.target.files[0])}
																/>
																<span className='mx-auto flex justify-center'>
																	<svg
																		stroke='currentColor'
																		fill='none'
																		strokeWidth={2}
																		viewBox='0 0 24 24'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		className='text-3xl text-green-500'
																		height='1em'
																		width='1em'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<polyline points='16 16 12 12 8 16' />
																		<line
																			x1={12}
																			y1={12}
																			x2={12}
																			y2={21}
																		/>
																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																		<polyline points='16 16 12 12 8 16' />
																	</svg>
																</span>
																<p className='text-sm mt-2'>
																	Drag your images here
																</p>
																<em className='text-xs text-gray-400'>
																	(Only *.jpeg, *.webp and *.png images will be
																	accepted)
																</em>
															</div>
															<div className='text-green-500' />
															<aside className='flex flex-row flex-wrap mt-4' />
														</div>
													</div>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
														onClick={() => setFile(null)}
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='submit'
														class='add-last-btn btn-lg'
													>
														Updateee
													</button>
												</div>
											</div>
										</form>
									</div>
									<div
										class='tab-pane fade'
										id='v-pills-profile'
										role='tabpanel'
										aria-labelledby='v-pills-profile-tab'
									>
										<form>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>Title</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='Featured Vedio'
													/>
												</div>
												<div className='col-md-12'>
													<label>Video Link</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='hLBkB_bhYzM'
													/>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='add-last-btn btn-lg'
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
									<div
										class='tab-pane fade'
										id='v-pills-messages'
										role='tabpanel'
										aria-labelledby='v-pills-messages-tab'
									>
										<form>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>Banner 1</label>
													<div className='col-span-8 sm:col-span-4'>
														<div className='w-full text-center'>
															<div
																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																role='button'
																tabIndex={0}
																style={{
																	borderStyle: 'dotted',
																	borderColor: '#FFD700',
																}}
															>
																<input
																	accept='image/*'
																	multiple=''
																	type='file'
																	className=''
																/>
																<span className='mx-auto flex justify-center'>
																	<svg
																		stroke='currentColor'
																		fill='none'
																		strokeWidth={2}
																		viewBox='0 0 24 24'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		className='text-3xl text-green-500'
																		height='1em'
																		width='1em'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<polyline points='16 16 12 12 8 16' />
																		<line
																			x1={12}
																			y1={12}
																			x2={12}
																			y2={21}
																		/>
																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																		<polyline points='16 16 12 12 8 16' />
																	</svg>
																</span>
																<p className='text-sm mt-2'>
																	Drag your images here
																</p>
																<em className='text-xs text-gray-400'>
																	(Only *.jpeg, *.webp and *.png images will be
																	accepted)
																</em>
															</div>
															<div className='text-green-500' />
															<aside className='flex flex-row flex-wrap mt-4' />
														</div>
													</div>
												</div>
												<div className='col-md-12'>
													<label>URL 1</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='Featured Vedio'
													/>
												</div>
												<div className='col-md-12'>
													<label>Banner 2</label>
													<div className='col-span-8 sm:col-span-4'>
														<div className='w-full text-center'>
															<div
																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																role='button'
																tabIndex={0}
																style={{
																	borderStyle: 'dotted',
																	borderColor: '#FFD700',
																}}
															>
																<input
																	accept='image/*'
																	multiple=''
																	type='file'
																	className=''
																/>
																<span className='mx-auto flex justify-center'>
																	<svg
																		stroke='currentColor'
																		fill='none'
																		strokeWidth={2}
																		viewBox='0 0 24 24'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		className='text-3xl text-green-500'
																		height='1em'
																		width='1em'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<polyline points='16 16 12 12 8 16' />
																		<line
																			x1={12}
																			y1={12}
																			x2={12}
																			y2={21}
																		/>
																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																		<polyline points='16 16 12 12 8 16' />
																	</svg>
																</span>
																<p className='text-sm mt-2'>
																	Drag your images here
																</p>
																<em className='text-xs text-gray-400'>
																	(Only *.jpeg, *.webp and *.png images will be
																	accepted)
																</em>
															</div>
															<div className='text-green-500' />
															<aside className='flex flex-row flex-wrap mt-4' />
														</div>
													</div>
												</div>
												<div className='col-md-12'>
													<label>URL 1</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='Featured Vedio'
													/>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='add-last-btn btn-lg'
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
									<div
										class='tab-pane fade'
										id='v-pills-settings'
										role='tabpanel'
										aria-labelledby='v-pills-settings-tab'
									>
										<form>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>News Letter News</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='News Letter News'
													/>
												</div>
												<div className='col-md-12'>
													<label> News Letter Image</label>
													<div className='col-span-8 sm:col-span-4'>
														<div className='w-full text-center'>
															<div
																className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																role='button'
																tabIndex={0}
																style={{
																	borderStyle: 'dotted',
																	borderColor: '#FFD700',
																}}
															>
																<input
																	accept='image/*'
																	multiple=''
																	type='file'
																	className=''
																/>
																<span className='mx-auto flex justify-center'>
																	<svg
																		stroke='currentColor'
																		fill='none'
																		strokeWidth={2}
																		viewBox='0 0 24 24'
																		strokeLinecap='round'
																		strokeLinejoin='round'
																		className='text-3xl text-green-500'
																		height='1em'
																		width='1em'
																		xmlns='http://www.w3.org/2000/svg'
																	>
																		<polyline points='16 16 12 12 8 16' />
																		<line
																			x1={12}
																			y1={12}
																			x2={12}
																			y2={21}
																		/>
																		<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																		<polyline points='16 16 12 12 8 16' />
																	</svg>
																</span>
																<p className='text-sm mt-2'>
																	Drag your images here
																</p>
																<em className='text-xs text-gray-400'>
																	(Only *.jpeg, *.webp and *.png images will be
																	accepted)
																</em>
															</div>
															<div className='text-green-500' />
															<aside className='flex flex-row flex-wrap mt-4' />
														</div>
													</div>
												</div>
												<div className='col-md-12'>
													<label>Section Details</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='Section Details'
													/>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='add-last-btn btn-lg'
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
									<div
										class='tab-pane fade'
										id='v-pills-Captcha'
										role='tabpanel'
										aria-labelledby='v-pills-Captcha-tab'
									>
										<form>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>ADMIN KEY</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='6Lf_nWwUAAAAAN_QKbYWwwf0r6DDWct52DoYzETF'
													/>
												</div>
												<div className='col-md-12'>
													PUBLIC KEY
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder='6Lf_nWwUAAAAAOWsOO1Uc71lQo8aWGt1p93MlWym'
													/>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='add-last-btn btn-lg'
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
									<div
										class='tab-pane fade'
										id='v-pills-footer'
										role='tabpanel'
										aria-labelledby='v-pills-footer-tab'
									>
										<form>
											<div className='row g-3'>
												<div className='col-md-12'>
													<label>Copyright</label>
													<input
														type='password'
														id='inputPassword6'
														className='form-control-input'
														aria-labelledby='passwordHelpInline'
														placeholder=' © Copyright 2022 E-Market, All Rights reserved.'
													/>
												</div>
											</div>

											<div class='row mt-3'>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='cancel-btn btn-lg'
													>
														Cancel
													</button>
												</div>
												<div class='col-lg-6 col-md-12 py-3'>
													<button
														type='button'
														class='add-last-btn btn-lg'
														style={{ color: '#000' }}
													>
														Update
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

export default withAuth(Settings);
