import React, { useState, useEffect } from 'react';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Radio } from 'antd';

import { Modal } from 'antd';
import ApiUrl from '../BaseUrl';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import withAuth from '../../../hooks/withAuth';
import {
	addCategory,
	addRecCategory,
	getCategory,
	getRecCategory,
	delCategory,
	delRecCategory,
	editCategory,
	editRecCategory,
} from '../ApiEndpoint';

const RecycleSchema = yup.object().shape({
	categoryTitle: yup.string().required('Title is required'),
	adminMinimumCommision: yup.string().required('Admin Commission is required'),
	status: yup.string().required('Status is required'),
});

const SellingSchema = yup.object().shape({
	categoryTitle: yup.string().required('Title is required'),
	adminMinimumCommision: yup.string().required('Admin Commission is required'),
	status: yup.string().required('Status is required'),
});

function ProductCategory() {
	const [CategoryID, setCategoryID] = useState('');
	const [singleStock, setSingleStock] = useState({});
	const [categoryTitle, setCategoryTitle] = useState('');
	const [adminMinimumCommision, setAdminMinimumCommision] = useState('');
	const [status, setStatus] = useState('');
	const [Type, setType] = useState('');

	const handleCategoryTitle = (e) => {
		setCategoryTitle(e.target.value);
	};

	const handleAdminMinimumCommision = (e) => {
		setAdminMinimumCommision(e.target.value);
	};

	const handleStatus = (e) => {
		setStatus(e.target.value);
	};

	const updateData = (e) => {
		e.preventDefault();

		const data = {
			categoryTitle: categoryTitle,
			adminMinimumCommision: adminMinimumCommision,
			status: status,
		};

		console.log('data to update', data);

		if (Type === 'selling') {
			fetch(`${editCategory}/${CategoryID}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}).then((res) => {
				if (res.ok) {
					console.log('Category updated successful');
					setCategoryTitle('');
					setAdminMinimumCommision('');
					setStatus('');
					setCategoryID('');
					setType('');
					toast.success('Category updated');
				} else {
					console.error('Category updated failed');
					toast.error('update failed');
				}
			});
		} else {
			fetch(`${editRecCategory}/${CategoryID}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}).then((res) => {
				if (res.ok) {
					console.log('Category updated successful');
					setCategoryTitle('');
					setAdminMinimumCommision('');
					setStatus('');
					setCategoryID('');
					setType('');
					toast.success('Category updated');
				} else {
					console.error('Category updated failed');
					toast.error('update failed');
				}
			});
		}
	};

	const onDeleteSeller = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delCategory}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('Category deleted successful');
						toast.success('Category deleted successful');
						fetchData();
					} else {
						console.error('Category deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	const onDeleteRecycle = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delRecCategory}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('Category deleted successful');
						fetchData();
						toast.success('Category deleted successful');
					} else {
						console.error('Category deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};
	const {
		register: registerRecycle,
		handleSubmit: handleSubmitRecycle,
		formState: { errors: errorsRecycle },
		reset: resetRecycle,
	} = useForm({
		resolver: yupResolver(RecycleSchema),
	});

	const {
		register: registerSeller,
		handleSubmit: handleSubmitSeller,
		formState: { errors: errorsSeller },
		reset: resetSeller,
	} = useForm({
		resolver: yupResolver(SellingSchema),
	});

	const onSubmitRecycle = async (data) => {
		try {
			const response = await fetch(addRecCategory, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added productrecCategory succesfull ');
				const productData = await response.json();
				console.log('Product rec data:', productData);
				toast.success('Category Added');
				fetchData();
				resetRecycle();
			} else {
				console.error(' failed');
				toast.error('Category Added failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}
		// console.log('recycle data', data);
		// resetRecycle();
	};

	const onSubmitSeller = async (data) => {
		try {
			const response = await fetch(addCategory, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added product sellerCategory succesfull ');
				const productData = await response.json();
				console.log('Product seller data:', productData);
				toast.success('Category Added');
				fetchData();
				resetSeller();
			} else {
				console.error(' failed');
				toast.error('Category Added failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}

		// console.log('selling data', data);
		// resetSeller();
	};
	const [combinedCategory, setCombinedCategory] = useState([]);
	const fetchSellerData = async () => {
		const result = await fetch(getCategory);
		const catdata = await result.json();
		console.log('Product selling Category', catdata);
		return catdata;
	};

	const fetchRecycleData = async () => {
		const result = await fetch(getRecCategory);
		const catdata = await result.json();
		console.log('Product recycle Category', catdata);
		return catdata;
	};

	const fetchData = async () => {
		const sellerData = await fetchSellerData();
		const recycleData = await fetchRecycleData();
		const combinedData = [...sellerData, ...recycleData];
		setCombinedCategory(combinedData);
	};
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage Product Categories</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'>
												<div className='imp-btn'></div>
												<div className='exp-btn px-3'></div>
											</div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'></div>
												<div className='Del-btn px-3'></div>
												<button
													className='add-btn mx-3'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRightRecycle'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Recycling Category</span>
												</button>

												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Category</span>
												</button>

												{/* selling category */}
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>Manage Product Category Create / Update</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form
																	onSubmit={handleSubmitSeller(onSubmitSeller)}
																>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='categoryTitle'
																				className='form-control-input'
																				placeholder='Category Title'
																				{...registerSeller('categoryTitle')}
																			/>

																			{errorsSeller.categoryTitle && (
																				<p style={{ color: 'red' }}>
																					{errorsSeller.categoryTitle.message}
																				</p>
																			)}
																		</div>
																	</div>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Admin Minimum Commission
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				id='inputPassword6'
																				name='adminMinimumCommision'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Enter Admin Minimum Commission'
																				{...registerSeller(
																					'adminMinimumCommision'
																				)}
																			/>

																			{errorsSeller.adminMinimumCommision && (
																				<p style={{ color: 'red' }}>
																					{
																						errorsSeller.adminMinimumCommision
																							.message
																					}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Status
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				name='status'
																				class='form-control-input-2'
																				aria-label='Default select example'
																				{...registerSeller('status')}
																			>
																				<option value='active'>Active</option>
																				<option value='inactive'>
																					Inactive
																				</option>
																			</select>

																			{errorsSeller.status && (
																				<p style={{ color: 'red' }}>
																					{errorsSeller.status.message}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																					<button
																						style={{ display: 'none' }}
																						type='button'
																						class='add-last-btn btn-lg'
																						id='myclosebtn'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>

													{/* Canvas */}
												</div>
												{/* recycle category										 */}
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRightRecycle'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>
																Manage Product recycle Category Create / Update
															</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form
																	onSubmit={handleSubmitRecycle(
																		onSubmitRecycle
																	)}
																>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='categoryTitle'
																				className='form-control-input'
																				placeholder='Category Title'
																				{...registerRecycle('categoryTitle')}
																			/>

																			{errorsRecycle.categoryTitle && (
																				<p style={{ color: 'red' }}>
																					{errorsRecycle.categoryTitle.message}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Admin Minimum Commission
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				id='inputPassword6'
																				name='adminMinimumCommision'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Enter Admin Minimum Commission'
																				{...registerRecycle(
																					'adminMinimumCommision'
																				)}
																			/>

																			{errorsRecycle.adminMinimumCommision && (
																				<p style={{ color: 'red' }}>
																					{
																						errorsRecycle.adminMinimumCommision
																							.message
																					}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Status
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				name='status'
																				class='form-control-input-2'
																				aria-label='Default select example'
																				{...registerRecycle('status')}
																			>
																				<option value='active'>Active</option>
																				<option value='inactive'>
																					Inactive
																				</option>
																			</select>

																			{errorsRecycle.status && (
																				<p style={{ color: 'red' }}>
																					{errorsRecycle.status.message}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																					<button
																						style={{ display: 'none' }}
																						type='button'
																						class='add-last-btn btn-lg'
																						id='myclosebtn'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>

													{/* Canvas */}
												</div>
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRightupdate'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>Manage Product Category Update</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* update Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form onSubmit={updateData}>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='title'
																				className='form-control-input'
																				placeholder='Category Title'
																				value={categoryTitle}
																				onChange={handleCategoryTitle}
																			/>
																		</div>
																	</div>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Admin Minimum Commission
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				id='inputPassword6'
																				name='adminCommission'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Enter Admin Minimum Commission'
																				value={adminMinimumCommision}
																				onChange={handleAdminMinimumCommision}
																			/>
																		</div>
																	</div>

																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Status
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				name='status'
																				class='form-control-input-2'
																				aria-label='Default select example'
																				defaultValue={singleStock?.status}
																				value={status}
																				onChange={handleStatus}
																			>
																				<option value='active'>Active</option>
																				<option value='inactive'>
																					Inactive
																				</option>
																			</select>
																		</div>
																	</div>

																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='abc'
																					>
																						Update Category
																					</button>
																					<button
																						style={{ display: 'none' }}
																						type='submit'
																						class='add-last-btn btn-lg'
																						id='myclosebtn'
																						data-bs-dismiss='offcanvas'
																					>
																						Update Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>

													{/* Canvas */}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51', tableLayout: 'fixed' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>#Id</th>
													<th>Category Type</th>
													<th>Title</th>
													<th>Admin Share</th>
													<th>Status</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{combinedCategory?.map((item, index) => {
													return (
														<>
															<tr>
																<td>{index + 1}</td>
																<td>{item?.category}</td>
																<td>{item?.categoryTitle}</td>
																<td>{item?.adminMinimumCommision}</td>
																<td>
																	<span
																		className='text-ligh  '
																		style={{
																			color: '#278a1a',
																			fontSize: '10px',
																			backgroundColor: '#41ff2848',
																			borderRadius: 20,
																			padding: '5px 10px',
																		}}
																	>
																		{item?.status}
																	</span>
																</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setCategoryID(item?._id);
																				setSingleStock(item);
																				if (item?.category === 'selling') {
																					setType('selling');
																				} else {
																					setType('recycling');
																				}
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate'
																			aria-controls='offcanvasRightupdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>

																		<span
																			onClick={() => {
																				if (item?.category === 'selling') {
																					onDeleteSeller(item?._id);
																				} else {
																					onDeleteRecycle(item?._id);
																				}
																			}}
																			style={{ fontSize: 21 }}
																			className='px-3'
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(ProductCategory);
