import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { toast } from 'react-toastify';
import withAuth from '../../../hooks/withAuth';

function Dorders() {
	const [allCategories, setAllCategories] = useState([]);
	const [singleCategory, setSingleCategory] = useState(null);
	const [singleImages, setSingleImages] = useState([]);
	const descriptionRef = useRef();
	console.log(singleImages);
	const handleCategory = () => {};

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});

	const handleSubmitDOs = async (e) => {
		const formdata = new FormData();
		formdata.append('demandImg', singleImages[0]);
		formdata.append('category', singleCategory);
		formdata.append('description', descriptionRef.current?.value);
		try {
			e.preventDefault();
			const {
				data: { message },
			} = await axios.post(`${ApiUrl}/demandOrder/create`, formdata);
			setSingleImages([]);
			setSingleCategory('');
			toast.success(message);
		} catch (error) {
			toast.error('Failed');
		}
	};
	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'>Manages Demand Orders</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'>
												<div className='imp-btn'></div>
												<div className='exp-btn px-3'></div>
											</div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>
																	Manage Our Demand Orders Create / Update
																</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form onSubmit={handleSubmitDOs}>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					htmlFor='inputPassword6'
																					className='form-label'
																				>
																					Order Category
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					className='form-control-input'
																					name='category'
																					onChange={(e) =>
																						setSingleCategory(e.target.value)
																					}
																				>
																					<option
																						selected
																						disabled
																						value={''}
																					>
																						---Select Category---
																					</option>
																					{allCategories &&
																						allCategories.map((cat) => (
																							<option value={cat?.title}>
																								{cat?.title}
																							</option>
																						))}
																				</select>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Order Image
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4 position-relative'>
																					<div className=''>
																						{singleImages.length > 0 ? (
																							<>
																								<div className='col-md-2  position-relative'>
																									<img
																										src={URL?.createObjectURL(
																											singleImages[0]
																										)}
																										width='100%'
																										height='100%'
																										className='border rounded-3'
																										style={{
																											objectFit: 'cover',
																										}}
																									/>
																									<span
																										className='border  p-1 py-0 rounded-circle bg-dark text-white position-absolute'
																										style={{
																											left: '100%',
																											lineHeight: '15px',
																											fontSize: 'x-small',
																											cursor: 'pointer',
																										}}
																										onClick={() =>
																											setSingleImages([])
																										}
																									>
																										x
																									</span>
																								</div>
																							</>
																						) : (
																							<div className='w-full text-center'>
																								<div
																									className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																									role='button'
																									tabIndex={0}
																									style={{
																										borderStyle: 'dotted',
																										borderColor: '#FFD700',
																									}}
																								>
																									<input
																										accept='image/*'
																										multiple=''
																										type='file'
																										name='demandImg'
																										onChange={(e) =>
																											setSingleImages(
																												e.target.files
																											)
																										}
																									/>
																									<span className='mx-auto flex justify-center'>
																										<svg
																											stroke='currentColor'
																											fill='none'
																											strokeWidth={2}
																											viewBox='0 0 24 24'
																											strokeLinecap='round'
																											strokeLinejoin='round'
																											className='text-3xl text-green-500'
																											height='1em'
																											width='1em'
																											xmlns='http://www.w3.org/2000/svg'
																										>
																											<polyline points='16 16 12 12 8 16' />
																											<line
																												x1={12}
																												y1={12}
																												x2={12}
																												y2={21}
																											/>
																											<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																											<polyline points='16 16 12 12 8 16' />
																										</svg>
																									</span>
																									<p className='text-sm mt-2'>
																										Drag your images here
																									</p>
																									<em className='text-xs text-gray-400'>
																										(Only *.jpeg, *.webp and
																										*.png images will be
																										accepted)
																									</em>
																								</div>
																								<div className='text-green-500' />
																								<aside className='flex flex-row flex-wrap mt-4' />
																							</div>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Description
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<textarea
																					type='text'
																					className='form-control-input'
																					name='description'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Order Description'
																					ref={descriptionRef}
																				></textarea>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							style={{
																								width: '100%',
																								background: 'lightgreen',
																								color: '#111',
																								borderRadius: '0.3rem',
																								border: 'none',
																							}}
																							classname='add-last-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																						>
																							Save
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							className='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																						>
																							Cancel
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className='Del-btn px-3'></div>
												{/* Modal-Del */}
												<div
													class='modal fade'
													id='exampleModal'
													tabindex='-1'
													aria-labelledby='exampleModalLabel'
													aria-hidden='true'
												>
													<div class='modal-dialog'>
														<div class='modal-content py-4'>
															<div class='modal-body'>
																<div className='modal-icon'>
																	<span
																		style={{ fontSize: 35, color: '#f05252' }}
																		className='px-3'
																	>
																		<RiDeleteBinLine />
																	</span>
																</div>
																<h1 class='title py-3'>
																	Are You Sure! Want to Delete{' '}
																	<span style={{ color: '#f05252' }}>
																		Selected Products?
																	</span>
																</h1>
																<p className='para'>
																	Do you really want to delete these records?
																	You can't view this in <br /> your list
																	anymore if you delete!
																</p>
																...
																<div className='last-btn'>
																	<button
																		type='button'
																		class='btn  btn-light mx-4 py-2 px-4'
																		data-bs-dismiss='modal'
																	>
																		No,Keep It
																	</button>
																	<button
																		type='button'
																		class='btn btn-success py-2 px-4'
																	>
																		Yes, Delete It
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* Modal-Del */}
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'>Add Demand Orders</span>
												</button>
												<div
													className='offcanvas offcanvas-end w-75'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4> Add Products</h4>
															<p>Add products info, combinations and extras.</p>
														</div>

														<button
															type='button'
															className='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														/>
													</div>
													{/* Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form>
																	<div className='row  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Title/Name
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Title/Name'
																			/>
																		</div>
																	</div>
																	<div className='row py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Description
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div className='form-floating'>
																				<textarea
																					className='form-control-input'
																					placeholder='Product Description'
																					id='floatingTextarea2'
																					style={{ height: 100 }}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Images
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div className='form-floating'>
																				<input
																					className='form-control-input'
																					type='file'
																					id='formFile'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row py-3 align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product SKU
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product SKU'
																			/>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Barcode
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Barcode'
																			/>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Category
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option selected>
																					Select Category
																				</option>
																				<option value='1'>
																					No Option/ Available
																				</option>
																			</select>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Default Category
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option selected>
																					Default Category
																				</option>
																				<option value='1'>
																					No Option/ Available
																				</option>
																			</select>
																		</div>
																	</div>
																	<div className='row py-3   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Price
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div class='input-group flex-nowrap'>
																				<span
																					class='input-group-text'
																					id='addon-wrapping'
																				>
																					$
																				</span>
																				<input
																					type='number'
																					class='form-control-input-3'
																					placeholder='0'
																					aria-label='Username'
																					aria-describedby='addon-wrapping'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Sale Price
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div class='input-group flex-nowrap'>
																				<span
																					class='input-group-text'
																					id='addon-wrapping'
																				>
																					$
																				</span>
																				<input
																					type='number'
																					class='form-control-input-3'
																					placeholder='0'
																					aria-label='Username'
																					aria-describedby='addon-wrapping'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Quantity
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Quantity'
																			/>
																		</div>
																	</div>
																	<div className='row    align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Slug
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Slug'
																			/>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Tag
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Tag(Write then press enter to add new tag)'
																			/>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='add-last-btn btn-lg'
																	>
																		{' '}
																		Add Products
																	</button>
																</div>
															</div>
														</div>
													</div>
													{/* Canvas */}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>
														<input
															className='form-check-input'
															type='checkbox'
															value=''
															id='flexCheckDefault'
														/>
													</th>
													<th>#ID</th>
													<th>Image</th>
													<th>Title</th>
													<th>Features</th>

													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														{' '}
														<input
															className='form-check-input'
															type='checkbox'
															value=''
															id='flexCheckDefault'
														/>
													</td>
													<td>#12</td>
													<td>
														{' '}
														<div className='prod-img'>
															<img
																src={require('./p1.jpg')}
																alt=''
															/>
														</div>
													</td>
													<td>Trainer</td>
													<td>
														<span
															className='text-ligh  '
															style={{
																color: '#278a1a',
																fontSize: '10px',
																backgroundColor: '#41ff2848',
																borderRadius: 20,
																padding: '5px 10px',
															}}
														>
															Yes
														</span>
													</td>

													<td>
														<span
															className='text-ligh  '
															style={{
																color: '#278a1a',
																fontSize: '10px',
																backgroundColor: '#41ff2848',
																borderRadius: 20,
																padding: '5px 10px',
															}}
														>
															Active
														</span>
													</td>

													<td>
														<div className='actions d-flex'>
															<span style={{ fontSize: 21 }}>
																<FiEdit />
															</span>
															<span
																style={{ fontSize: 21 }}
																className='px-3'
															>
																<RiDeleteBinLine />
															</span>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Dorders);
