// export const endpoint = 'http://localhost:3000';
export const endpoint = 'http://165.22.215.169:80';

// quality controller  api

export const addPackageQC = `${endpoint}/admin/createAmbassadorPackage`;
export const getPackageQC = `${endpoint}/admin/ambassador-packages`;
export const delPackageQCById = `${endpoint}/admin/deleteAmbassadorPackage`;

// seller controller api
export const addPackageSeller = `${endpoint}/admin/createSellerPackage`;
export const getPackageSeller = `${endpoint}/admin/seller-package`;
export const delPackageSellerById = `${endpoint}/admin/deletesellerPackage`;

// events api
export const addEvent = `${endpoint}/admin/events`;
export const getEvent = `${endpoint}/admin/events`;
export const delEventById = `${endpoint}/admin/events`;

// expo
export const addExpo = `${endpoint}/admin/createExpo`;
export const getExpo = `${endpoint}/admin/expo`;
export const delExpo = `${endpoint}/admin/deleteExpo`;
export const updateExpo = `${endpoint}/admin/updateExpo`;

// category selling api
export const addCategory = `${endpoint}/productcategory/selling`;
export const getCategory = `${endpoint}/productcategory/selling`;
export const delCategory = `${endpoint}/productcategory/selling`;
export const editCategory = `${endpoint}/productcategory/selling`;

// category recycling api
export const addRecCategory = `${endpoint}/productcategory/recycling`;
export const getRecCategory = `${endpoint}/productcategory/recycling`;
export const delRecCategory = `${endpoint}/productcategory/recycling`;
export const editRecCategory = `${endpoint}/productcategory/recycling`;

// sub category api
export const addsubCategory = `${endpoint}/product/subcategoriesselling`;
export const getsubCategory = `${endpoint}/product/subcategoriesselling`;
export const delsubCategory = `${endpoint}/product/subcategoriesselling`;
export const editsubCategory = `${endpoint}/product/subcategoriesselling`;

// sub category recycling api
export const addRecsubCategory = `${endpoint}/product/subcategoriesrecycling`;
export const getRecsubCategory = `${endpoint}/product/subcategoriesrecycling`;
export const editRecsubCategory = `${endpoint}/product/subcategoriesrecycling`;
export const delRecsubCategory = `${endpoint}/product/subcategoriesrecycling`;

// selling product api

// export const addProduct = `${endpoint}/product/sellingss`;   for seller
export const editProduct = `${endpoint}/product/sellingss`;
export const delProduct = `${endpoint}/product/sellingss`;
export const getProduct = `${endpoint}/product/sellingss`;

// recycling product api

// export const addRecProduct = `${endpoint}/product/recycling`; for seller
export const editRecProduct = `${endpoint}/product/recycling`;
export const delRecProduct = `${endpoint}/product/recycling`;
export const getRecProduct = `${endpoint}/product/recycling`;
