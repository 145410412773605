import React, { useState, useEffect } from 'react';
import ScrollableSection from '../Dashboard/Dashbord';
import Nav from '../../../components/Nav';

import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineZoomIn } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import axios from 'axios';
import { message, Modal } from 'antd';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import withAuth from '../../../hooks/withAuth';

function Staff() {
	const [newTeam, setNewTeam] = useState([]);
	const [singleStock, setSingleStock] = useState({});
	const [updateTeam, setUpdateTeam] = useState();

	const sendData = (values) => {};

	const updateData = (values) => {};

	const onDeleteStudent = (id) => {
		console.log('==========================', id);
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {},
		});
	};

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage Staff</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'>
												<div className='imp-btn'></div>
												<div className='exp-btn px-3'></div>
											</div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Staff Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			sendData(e.target);
																		}}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Name'
																					name='name'
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Username
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Location'
																					name='location'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Password
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='paasword'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Permissions
																				</label>
																			</div>
																			<div className='col-md-8'>
																				{/* <div > */}
																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Dashboard</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Manage Page</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Home Page</label>
																					</div>
																				</div>

																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Products</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Expo Schedules</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Payments</label>
																					</div>
																				</div>

																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Ambassador</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Sellers</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Orders</label>
																					</div>
																				</div>

																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Demand Orders</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Payment Methods</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Brands</label>
																					</div>
																				</div>

																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Services</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Events</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Oppotunities</label>
																					</div>
																				</div>

																				<div
																					id='styled'
																					className='row'
																				>
																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>staff</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>New Arrival</label>
																					</div>

																					<div className='col-md-3'>
																						<input
																							className='form-check-input '
																							type='checkbox'
																							value=''
																							id='flexCheckDefault'
																						/>
																						<label>Subscriber</label>
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Image
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								name='image'
																								type='file'
																							/>
																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					aria-label='Default select example'
																					name='status'
																				>
																					<option value='Active'>Active</option>
																					<option value='InActive'>
																						Inactive
																					</option>
																				</select>
																			</div>
																		</div>
																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													<div
														className='offcanvas offcanvas-end'
														tabIndex={-1}
														id='offcanvasRightUpdate'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Team Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			updateData(e.target);
																		}}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='password'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Name'
																					name='name'
																				/>
																			</div>
																		</div>
																		<div>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Location
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Location'
																					name='location'
																				/>
																			</div>
																		</div>

																		<div>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Select Date
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='date'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Service Provider'
																					name='selectDate'
																				/>
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Description
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<textarea
																					type='text'
																					id='inputPassword6'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Seller detail'
																					name='description'
																				></textarea>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Image
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<div className='col-span-8 sm:col-span-4'>
																					<div className='w-full text-center'>
																						<div
																							className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																							role='button'
																							tabIndex={0}
																							style={{
																								borderStyle: 'dotted',
																								borderColor: '#FFD700',
																							}}
																						>
																							<input
																								name='image'
																								type='file'
																							/>
																							<span className='mx-auto flex justify-center'>
																								<svg
																									stroke='currentColor'
																									fill='none'
																									strokeWidth={2}
																									viewBox='0 0 24 24'
																									strokeLinecap='round'
																									strokeLinejoin='round'
																									className='text-3xl text-green-500'
																									height='1em'
																									width='1em'
																									xmlns='http://www.w3.org/2000/svg'
																								>
																									<polyline points='16 16 12 12 8 16' />
																									<line
																										x1={12}
																										y1={12}
																										x2={12}
																										y2={21}
																									/>
																									<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																									<polyline points='16 16 12 12 8 16' />
																								</svg>
																							</span>
																							<p className='text-sm mt-2'>
																								Drag your images here
																							</p>
																							<em className='text-xs text-gray-400'>
																								(Only *.jpeg, *.webp and *.png
																								images will be accepted)
																							</em>
																						</div>
																						<div className='text-green-500' />
																						<aside className='flex flex-row flex-wrap mt-4' />
																					</div>
																				</div>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Status
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					class='form-control-input-2'
																					aria-label='Default select example'
																					name='status'
																				>
																					<option value='Active'>Active</option>
																					<option value='InActive'>
																						Inactive
																					</option>
																				</select>
																			</div>
																		</div>
																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='submit'
																							class='add-last-btn btn-lg'
																						>
																							Save
																						</button>
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>
												</div>
												<div className='Del-btn px-3'></div>
												{/* Modal-Del */}
												<div
													class='modal fade'
													id='exampleModal'
													tabindex='-1'
													aria-labelledby='exampleModalLabel'
													aria-hidden='true'
												>
													<div class='modal-dialog'>
														<div class='modal-content py-4'>
															<div class='modal-body'>
																<div className='modal-icon'>
																	<span
																		style={{ fontSize: 35, color: '#f05252' }}
																		className='px-3'
																	>
																		<RiDeleteBinLine />
																	</span>
																</div>
																<h1 class='title py-3'>
																	Are You Sure! Want to Delete{' '}
																	<span style={{ color: '#f05252' }}>
																		Selected Products?
																	</span>
																</h1>
																<p className='para'>
																	Do you really want to delete these records?
																	You can't view this in <br /> your list
																	anymore if you delete!
																</p>
																...
																<div className='last-btn'>
																	<button
																		type='button'
																		class='btn  btn-light mx-4 py-2 px-4'
																		data-bs-dismiss='modal'
																	>
																		No,Keep It
																	</button>
																	<button
																		type='button'
																		class='btn btn-success py-2 px-4'
																	>
																		Yes, Delete It
																	</button>
																</div>
															</div>
														</div>
													</div>
												</div>
												{/* Modal-Del */}
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Staff</span>
												</button>

												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>Manage Seller / Create</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form>
																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Ambassador
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option value='1'>
																					Basheer Chattha
																				</option>
																				<option value='1'>
																					Ghulam Mustafa
																				</option>
																				<option value='1'>Ali Raza</option>
																				<option value='1'>Adnan</option>
																			</select>
																		</div>
																	</div>
																	<div className='row g-4 pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Name
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Seller Name'
																			/>
																		</div>
																	</div>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Email
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Email'
																			/>
																		</div>
																	</div>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Password
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Password'
																			/>
																		</div>
																	</div>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Seller Details
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<textarea
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Seller detail'
																			></textarea>
																		</div>
																	</div>

																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Image
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div className='col-span-8 sm:col-span-4'>
																				<div className='w-full text-center'>
																					<div
																						className='border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-3 cursor-pointer px-6 pt-5 pb-4'
																						role='button'
																						tabIndex={0}
																						style={{
																							borderStyle: 'dotted',
																							borderColor: '#FFD700',
																						}}
																					>
																						<input
																							accept='image/*'
																							multiple=''
																							type='file'
																						/>
																						<span className='mx-auto flex justify-center'>
																							<svg
																								stroke='currentColor'
																								fill='none'
																								strokeWidth={2}
																								viewBox='0 0 24 24'
																								strokeLinecap='round'
																								strokeLinejoin='round'
																								className='text-3xl text-green-500'
																								height='1em'
																								width='1em'
																								xmlns='http://www.w3.org/2000/svg'
																							>
																								<polyline points='16 16 12 12 8 16' />
																								<line
																									x1={12}
																									y1={12}
																									x2={12}
																									y2={21}
																								/>
																								<path d='M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3' />
																								<polyline points='16 16 12 12 8 16' />
																							</svg>
																						</span>
																						<p className='text-sm mt-2'>
																							Drag your images here
																						</p>
																						<em className='text-xs text-gray-400'>
																							(Only *.jpeg, *.webp and *.png
																							images will be accepted)
																						</em>
																					</div>
																					<div className='text-green-500' />
																					<aside className='flex flex-row flex-wrap mt-4' />
																				</div>
																			</div>
																		</div>
																	</div>

																	<div className='row  pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Status
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option value='1'>Active</option>
																				<option value='1'>Inactive</option>
																			</select>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancel
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='add-last-btn btn-lg'
																	>
																		Save
																	</button>
																</div>
															</div>
														</div>
													</div>
													{/* Canvas */}
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>
														<input
															className='form-check-input'
															type='checkbox'
															value=''
															id='flexCheckDefault'
														/>
													</th>
													<th>#ID</th>
													<th>Image</th>
													<th>Name</th>
													<th>Date</th>
													<th>Location</th>
													<th>Description</th>

													<th>Status</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												{newTeam?.map((item, index) => {
													return (
														<>
															<tr>
																<td>
																	{' '}
																	<input
																		className='form-check-input'
																		type='checkbox'
																		value=''
																		id='flexCheckDefault'
																	/>
																</td>
																<td>{index + 1}</td>
																<td>
																	<div className='prod-img'>
																		<img
																			src={`${ApiUrl}/images/${item?.image}`}
																			alt=''
																		/>
																	</div>
																</td>
																<td>{item?.name}</td>

																<td>{item?.SelectDate}</td>
																<td>{item?.location}</td>
																<td>{item?.description}</td>

																<td>{item?.status}</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setUpdateTeam(item?._id);

																				axios
																					.get(
																						`${ApiUrl}/teams/getById?id=` +
																							item?._id
																					)
																					.then((res) => {
																						console.log(
																							res.data,
																							'singleStock'
																						);

																						setSingleStock(res.data);
																					});
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightUpdate'
																			aria-controls='offcanvasRightUpdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>
																		<span
																			style={{ fontSize: 21 }}
																			className='px-3'
																			onClick={() => {
																				onDeleteStudent(item?._id);
																			}}
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Staff);
