import React, { useState, useEffect } from 'react';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import ProductCategory from '../ProductCategory/ProductCategory';
import { Modal } from 'antd';
import ApiUrl from '../BaseUrl';
import { Radio } from 'antd';

import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import withAuth from '../../../hooks/withAuth';
import {
	getCategory,
	getRecCategory,
	addRecsubCategory,
	addsubCategory,
	getsubCategory,
	getRecsubCategory,
	delRecsubCategory,
	delsubCategory,
	editsubCategory,
	editRecsubCategory,
} from '../ApiEndpoint';

const SellingSchema = yup.object().shape({
	category: yup.string().required('Title is required'),
	subCategoryTitle: yup.string().required('title is required'),
});

const RecycleSchema = yup.object().shape({
	category: yup.string().required('Title is required'),
	subCategoryTitle: yup.string().required('title is required'),
});

function SubCategory() {
	const [stocks, setStocks] = useState([]);
	const [subCategoryId, setsubCategoryId] = useState('');
	const [productCategory, setProductCategory] = useState([]);
	const [productRecCategory, setProductRecCategory] = useState([]);
	const [categoryTitle, setCategoryTitle] = useState('');
	const [Type, setType] = useState('');

	const handleTitleChange = (e) => {
		setCategoryTitle(e.target.value);
	};

	useEffect(() => {
		const fetchData = async () => {
			const result = await fetch(getCategory);
			const catdata = await result.json();

			console.log('Product Category for sub ', catdata);
			setProductCategory(catdata);
		};

		const fetchRecData = async () => {
			const result = await fetch(getRecCategory);
			const catdata = await result.json();

			console.log('Product recCategory for sub ', catdata);
			setProductRecCategory(catdata);
		};

		fetchData();
		fetchRecData();
	}, []);

	useEffect(() => {
		const fetchSellerData = async () => {
			const result = await fetch(getsubCategory);
			const catdata = await result.json();
			console.log('Product selling Category', catdata);
			return catdata;
		};
		const fetchRecycleData = async () => {
			const result = await fetch(getRecsubCategory);
			const catdata = await result.json();
			console.log('Product recycle Category', catdata);
			return catdata;
		};
		const fetchData = async () => {
			const sellerData = await fetchSellerData();
			const recycleData = await fetchRecycleData();
			const combinedData = [...sellerData, ...recycleData];
			setStocks(combinedData);
		};

		fetchData();
	}, []);

	const {
		register: registerSelling,
		handleSubmit: handleSubmitSelling,
		formState: { errors: errorsSelling },
		reset: resetSelling,
	} = useForm({
		resolver: yupResolver(SellingSchema),
	});

	const {
		register: registerRecycle,
		handleSubmit: handleSubmitRecycle,
		formState: { errors: errorsRecycle },
		reset: resetRecycle,
	} = useForm({
		resolver: yupResolver(RecycleSchema),
	});

	const updateData = (e) => {
		e.preventDefault();

		const data = {
			subCategoryTitle: categoryTitle,
		};
		if (Type === 'selling') {
			fetch(`${editsubCategory}/${subCategoryId}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}).then((res) => {
				console.log('res', res);
				if (res.ok) {
					console.log('Category updated successful');
					setCategoryTitle('');
					setsubCategoryId('');
					setType('');
					toast.success('Sub-Category updated');
				} else {
					console.error('Category updated failed');
					toast.error('update failed');
				}
			});
		} else {
			fetch(`${editRecsubCategory}/${subCategoryId}`, {
				method: 'PATCH',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}).then((res) => {
				console.log('res', res);
				if (res.ok) {
					console.log('Category updated successful');
					setCategoryTitle('');
					setsubCategoryId('');
					setType('');
					toast.success('Sub-Category updated');
				} else {
					console.error('Category updated failed');
					toast.error('update failed');
				}
			});
		}
	};

	const onDeleteSeller = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delsubCategory}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log(' subCategory deleted successful');
						toast.success('sub-Category deleted');
					} else {
						console.error('Category deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	const onDeleteRecycle = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delRecsubCategory}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log(' subCategory deleted successful');
						toast.success('sub-Category deleted');
					} else {
						console.error('Category deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	const onSubmitSelling = async (data) => {
		try {
			const response = await fetch(addsubCategory, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added seller subproductCategory succesfull ');
				const productData = await response.json();
				console.log('sub category data:', productData);
				toast.success('Sub Category Added');
				resetSelling();
			} else {
				console.error(' failed');
				toast.error('Sub Category failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}
	};
	const onSubmitRecycle = async (data) => {
		try {
			const response = await fetch(addRecsubCategory, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added subproductCategory succesfull ');
				const productData = await response.json();
				console.log('rec sub data:', productData);
				toast.success('Sub Category Added');
				resetRecycle();
			} else {
				console.error(' failed');
				toast.error('Sub Category failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}
	};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Manage Product Categories</h5>

								<div className='j'>
									<div className='products-actions d-flex p-1'>
										<div className='imort-product '>
											<div className='btn-product d-flex'>
												<div className='imp-btn'></div>
												<div className='exp-btn px-3'></div>
											</div>
										</div>
										<div className='action-btn'>
											<div className='actions d-flex '>
												<button
													className='add-btn mx-3'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRightRecycle'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'>
														{' '}
														Add Recycle Sub-Category
													</span>
												</button>
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'> Add Sub-Category</span>
												</button>
												{/* selling Canvas */}
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>
																Manage Product Sub-Category Create / Update
															</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* selling Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form
																	onSubmit={handleSubmitSelling(
																		onSubmitSelling
																	)}
																>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				className='title-color'
																				htmlFor='exampleFormControlSelect1'
																			>
																				Select Category
																				<span className='text-danger'>*</span>
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				name='category'
																				id='categoryId'
																				className='form-control form-select'
																				{...registerSelling('category')}
																			>
																				{productCategory.map((category) => (
																					<option
																						key={category._id}
																						value={category.categoryTitle}
																					>
																						{category.categoryTitle}
																					</option>
																				))}
																			</select>

																			{errorsSelling.category && (
																				<p style={{ color: 'red' }}>
																					{errorsSelling.category.message}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Sub Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='subCategoryTitle'
																				className='form-control-input'
																				placeholder='Sub Category Title'
																				{...registerSelling('subCategoryTitle')}
																			/>
																			{errorsSelling.subCategoryTitle && (
																				<p style={{ color: 'red' }}>
																					{
																						errorsSelling.subCategoryTitle
																							.message
																					}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																						data-bs-dismiss='offcanvas'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='abc'
																					>
																						Add Category
																					</button>
																					<button
																						style={{ display: 'none' }}
																						type='submit'
																						class='add-last-btn btn-lg'
																						id='myclosebtn'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>

													{/* update Canvas */}
												</div>
												{/* recycle Canvas */}
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRightRecycle'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>
																Manage Product Recycle Sub-Category Create /
																Update
															</h4>
															<p>
																Update products info, combinations and extras.
															</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>

													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form
																	onSubmit={handleSubmitRecycle(
																		onSubmitRecycle
																	)}
																>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				className='title-color'
																				htmlFor='exampleFormControlSelect1'
																			>
																				Select Category
																				<span className='text-danger'>*</span>
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				name='category'
																				id='categoryId'
																				className='form-control form-select'
																				{...registerRecycle('category')}
																			>
																				{productRecCategory.map((category) => (
																					<option
																						key={category._id}
																						value={category.categoryTitle}
																					>
																						{category.categoryTitle}
																					</option>
																				))}
																			</select>

																			{errorsRecycle.category && (
																				<p style={{ color: 'red' }}>
																					{errorsRecycle.category.message}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Sub Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='subCategoryTitle'
																				className='form-control-input'
																				placeholder='Sub Category Title'
																				{...registerRecycle('subCategoryTitle')}
																			/>
																			{errorsRecycle.subCategoryTitle && (
																				<p style={{ color: 'red' }}>
																					{
																						errorsRecycle.subCategoryTitle
																							.message
																					}
																				</p>
																			)}
																		</div>
																	</div>

																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																						data-bs-dismiss='offcanvas'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='abc'
																					>
																						Add Category
																					</button>
																					<button
																						style={{ display: 'none' }}
																						type='submit'
																						class='add-last-btn btn-lg'
																						id='myclosebtn'
																						data-bs-dismiss='offcanvas'
																					>
																						Add Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
												</div>
												{/* update Canvas */}
												<div
													className='offcanvas offcanvas-end'
													tabIndex={-1}
													id='offcanvasRightupdate'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>Manage Product Sub Category Update</h4>
															<p>Update SubCategory</p>
														</div>

														<button
															type='button'
															className='btn-close bg-white shadow rounded-5 text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
															style={{
																width: '5px',
																height: '30px',
																opacity: '0.8',
																fontSize: '12px',
															}}
														/>
													</div>
													{/* update Canvas */}
													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form onSubmit={updateData}>
																	<div className='row pt-4  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				{' '}
																				Category Title
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='text'
																				name='title'
																				className='form-control-input'
																				placeholder='Category Title'
																				value={categoryTitle}
																				onChange={handleTitleChange}
																			/>
																		</div>
																	</div>
																	<div className='offcanvas-footer px-5 py-3'>
																		<div className='content-footer'>
																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																						data-bs-dismiss='offcanvas'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																						data-bs-dismiss='abc'
																					>
																						Update Category
																					</button>
																				</div>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>

													{/* Canvas */}
												</div>
											</div>
										</div>
									</div>
								</div>

								{/*  view table */}

								<div className='min-box my-4'>
									<div className='products-table'>
										<table
											className='table border rounded align-middle mt-5'
											style={{ borderColor: '#4f4f51' }}
											id='table'
										>
											<thead className='bg-light'>
												<tr>
													<th>#Id</th>
													<th>Title</th>
													<th>Category Type</th>
													<th>Select Category</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{stocks?.map((item, index) => {
													return (
														<>
															<tr>
																<td>{index + 1}</td>
																<td>{item?.subCategoryTitle}</td>
																<td>{item?.categoryType}</td>
																<td>{item?.category}</td>

																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setsubCategoryId(item?._id);
																				if (item?.categoryType === 'selling') {
																					setType(item?.categoryType);
																				} else {
																					setType(item?.categoryType);
																				}
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate'
																			aria-controls='offcanvasRightupdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>

																		<span
																			onClick={() => {
																				// onDeleteSeller(item?._id);
																				if (item?.categoryType === 'selling') {
																					onDeleteSeller(item?._id);
																				} else {
																					onDeleteRecycle(item?._id);
																				}
																			}}
																			style={{ fontSize: 21 }}
																			className='px-3'
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														</>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(SubCategory);
