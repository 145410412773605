import React, { useState, useEffect } from 'react';
import { BiImport } from 'react-icons/bi';
import { BiExport } from 'react-icons/bi';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import axios from 'axios';
import ApiUrl from '../BaseUrl';
import { Modal } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import $ from "jquery";
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
function WalletHistory() {
	const [wallet, setWallet] = useState([]);
	const [cherity, setCherity] = useState([]);

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<div className='container py-4'>
								<h5 className='kk ps-3'> Wallet History</h5>
								<ul
									class='nav nav-pills mb-3 d-flex '
									id='pills-tab'
									role='tablist'
									style={{ gap: '12px', justifyContent: 'flex-start' }}
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link active'
											id='pills-Recycling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Recycling'
											type='button'
											role='tab'
											aria-controls='pills-Recycling'
											aria-selected='true'
										>
											Store Wallet
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-Selling-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Selling'
											type='button'
											role='tab'
											aria-controls='pills-Selling'
											aria-selected='false'
										>
											Product Wallet
										</button>
									</li>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-cherity-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-cherity'
											type='button'
											role='tab'
											aria-controls='pills-cherity'
											aria-selected='false'
										>
											Charity
										</button>
									</li>
								</ul>

								{/* Modal-Del */}
								<div
									className='offcanvas offcanvas-end'
									tabIndex={-1}
									id='offcanvasRight'
									aria-labelledby='offcanvasRightLabel'
								>
									<div className='offcanvas-header px-4'>
										<div className='content-header'>
											<h4>Manage Product Create / Update</h4>
											<p>Update products info, combinations and extras.</p>
										</div>

										<button
											type='button'
											className='btn-close bg-white shadow rounded-5 text-reset'
											data-bs-dismiss='offcanvas'
											aria-label='Close'
											style={{
												width: '5px',
												height: '30px',
												opacity: '0.8',
												fontSize: '12px',
											}}
										/>
									</div>
									{/* Canvas */}
									<div className='offcanvas-body all_categories p-0'>
										<div className='form-data'>
											<ul
												class='nav nav-pills'
												id='pills-tab'
												role='tablist'
											>
												<li
													class='nav-item'
													role='presentation'
												>
													<button
														class='nav-link active'
														id='pills-home-tab'
														data-bs-toggle='pill'
														data-bs-target='#pills-home'
														type='button'
														role='tab'
														aria-controls='pills-home'
														aria-selected='true'
													>
														Recycling
													</button>
												</li>
												<li
													class='nav-item'
													role='presentation'
												>
													<button
														class='nav-link'
														id='pills-profile-tab'
														data-bs-toggle='pill'
														data-bs-target='#pills-profile'
														type='button'
														role='tab'
														aria-controls='pills-profile'
														aria-selected='false'
													>
														Selling
													</button>
												</li>
											</ul>
											<div
												class='tab-content'
												id='pills-tabContent'
											>
												<div
													class='tab-pane fade show active'
													id='pills-home'
													role='tabpanel'
													aria-labelledby='pills-home-tab'
												>
													<div className='wrap-data p-5'></div>
												</div>
												<div
													class='tab-pane fade'
													id='pills-profile'
													role='tabpanel'
													aria-labelledby='pills-profile-tab'
												>
													<div className='text-end   me-3 pt-4'>
														<button
															id='All_Btn'
															onClick={() => {
																('New Product');
																document.getElementById(
																	'All_Btn'
																).style.backgroundColor = 'green';

																document.getElementById(
																	'all_btn'
																).style.backgroundColor = '#eee';
															}}
															className='  product_button '
															type='button'
														>
															<span className='px-1'>New Product</span>
														</button>

														<button
															id='all_btn'
															onClick={() => {
																('Second hand');

																document.getElementById(
																	'all_btn'
																).style.backgroundColor = 'green';

																document.getElementById(
																	'All_Btn'
																).style.backgroundColor = '#eee';
															}}
															className='ms-3 product_button '
															type='button'
														>
															<span className='px-1'>Second hand</span>
														</button>
													</div>

													<div className='wrap-data p-5'></div>
												</div>
											</div>
										</div>
									</div>

									{/* Canvas */}
								</div>

								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-Recycling'
										role='tabpanel'
										aria-labelledby='pills-Recycling-tab'
									>
										<div className='min-box my-4'>
											<div className='products-table'>
												<table
													className='table border rounded align-middle mt-5'
													style={{ borderColor: '#4f4f51' }}
													id='table2'
												>
													<thead className='bg-light'>
														<tr>
															<th>Id</th>
															<th>Stores</th>
															<th>Amount</th>
														</tr>
													</thead>
													<tbody>
														{wallet?.[0]?.storeComission?.map((item, index) => {
															return (
																<>
																	<tr>
																		<td>{index + 1}</td>
																		<td>{item.name}</td>
																		<td>${item.comission}</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div
										class='tab-pane fade'
										id='pills-Selling'
										role='tabpanel'
										aria-labelledby='pills-Selling-tab'
									>
										<div className='min-box my-4'>
											<div className='products-table'>
												<table
													className='table border rounded align-middle mt-5'
													style={{ borderColor: '#4f4f51' }}
													id='table'
												>
													<thead className='bg-light'>
														<tr>
															<th>Id</th>
															<th>Store</th>
															<th>Amount</th>
														</tr>
													</thead>
													<tbody>
														{wallet?.[0]?.storeComission?.map((item, index) => {
															return (
																<>
																	<tr>
																		<td>{index + 1}</td>
																		<td>{item.name}</td>
																		<td>${item.comission}</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>

									<div
										class='tab-pane fade'
										id='pills-cherity'
										role='tabpanel'
										aria-labelledby='pills-cherity-tab'
									>
										<div className='min-box my-4'>
											<div className='products-table'>
												<table
													className='table border rounded align-middle mt-5'
													style={{ borderColor: '#4f4f51' }}
													id='table'
												>
													<thead className='bg-light'>
														<tr>
															<th>SrNo</th>
															<th>Username</th>
															<th>Date</th>
															<th>Amount</th>
														</tr>
													</thead>
													<tbody>
														{cherity?.map((item, index) => {
															return (
																<>
																	<tr>
																		<td>{index + 1}</td>
																		<td>{item.name}</td>
																		<td>
																			{new Date(item?.createdAt).toDateString()}
																		</td>
																		<td>${item.amount}</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default WalletHistory;
