import React, { useState, useEffect, useRef } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { AiFillCloseCircle } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import withAuth from '../../../hooks/withAuth';
function MainStockRacks() {
	const { id } = useParams();

	const navigate = useNavigate();
	const [rackId, setRackId] = useState(null);
	const [stores, setStores] = useState([]);
	console.log('===========', rackId);
	const [racks, setRacks] = useState([]);

	const [storesid, setStoreId] = useState({});
	console.log(storesid);
	console.log(racks);

	const updateSend = (values) => {};

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<ToastContainer />
						<div className='main-wrapper'>
							<div className='container py-4'>
								<div className='d-flex justify-content-between align-items-center'>
									<div>
										<h5 className='kk ps-3'> Manage Main Stock Racks</h5>
									</div>
									<div></div>
								</div>

								<ul
									class='nav nav-pills mb-3 d-flex '
									id='pills-tab'
									role='tablist'
									style={{ gap: '12px', justifyContent: 'flex-start' }}
								>
									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link active'
											id='pills-home-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-home'
											type='button'
											role='tab'
											aria-controls='pills-home'
											aria-selected='true'
										>
											E-racks
										</button>
									</li>

									<li
										class='nav-item'
										role='presentation'
									>
										<button
											class='nav-link'
											id='pills-Display-tab'
											data-bs-toggle='pill'
											data-bs-target='#pills-Display'
											type='button'
											role='tab'
											aria-controls='pills-Display'
											aria-selected='false'
										>
											E-Display
										</button>
									</li>
								</ul>
								<div
									class='tab-content'
									id='pills-tabContent'
								>
									<div
										class='tab-pane fade show active'
										id='pills-home'
										role='tabpanel'
										aria-labelledby='pills-home-tab'
									>
										<div className='all_categories'>
											<ul
												class='nav nav-pills mb-3'
												id='pills-tab'
												role='tablist'
											>
												<li
													class='nav-item'
													role='presentation'
												>
													<button
														class='nav-link active'
														id='pills-Owned-tab'
														data-bs-toggle='pill'
														data-bs-target='#pills-Owned'
														type='button'
														role='tab'
														aria-controls='pills-Owned'
														aria-selected='true'
													>
														Owned
													</button>
												</li>
												<li
													class='nav-item'
													role='presentation'
												>
													<button
														class='nav-link'
														id='pills-My-tab'
														data-bs-toggle='pill'
														data-bs-target='#pills-My'
														type='button'
														role='tab'
														aria-controls='pills-My'
														aria-selected='false'
													>
														My Rental
													</button>
												</li>
												<li
													class='nav-item'
													role='presentation'
												>
													<button
														class='nav-link'
														id='pills-Rent-tab'
														data-bs-toggle='pill'
														data-bs-target='#pills-Rent'
														type='button'
														role='tab'
														aria-controls='pills-Rent'
														aria-selected='false'
													>
														On Rent
													</button>
												</li>
											</ul>
										</div>

										<div
											class='tab-content'
											id='pills-tabContent2'
										>
											<div
												class='tab-pane fade show active'
												id='pills-Owned'
												role='tabpanel'
												aria-labelledby='pills-Owned-tab'
											>
												<h3 className='py-4'>
													Total Number of Racks: {racks?.length}
												</h3>
												<div className='row g-4'>
													{racks.map((item, index) => {
														return (
															<div className='col-md-3'>
																<div
																	className='p-2'
																	style={{
																		backgroundColor: '#fff',
																		border: ' 1px solid rgb(243, 239, 239)',

																		borderRadius: '10px',
																	}}
																>
																	<div
																		className='order'
																		style={{
																			display: 'flex',
																			justifyContent: 'space-between',
																		}}
																	>
																		<div
																			className=''
																			data-bs-toggle='modal'
																			data-bs-target='#exampleModal2'
																			onClick={() => setRackId(item?._id)}
																		>
																			<h4>{`Ei${index + 1}`}</h4>
																		</div>

																		<div style={{ zIndex: '999' }}></div>
																	</div>

																	<div className='d-flex justify-content-between pt-3'>
																		<p>{item?.state}</p>
																		{item?.state === 'empty' ? (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<AiFillCloseCircle
																					size={20}
																					color='red'
																				/>
																			</div>
																		) : (
																			<div
																				className=''
																				style={{
																					background: '#FEECDC',
																					width: '30px',
																					height: '30px',
																					lineHeight: '30px',
																					borderRadius: '50%',
																					justifyContent: 'center',
																					alignItems: 'center',
																					display: 'flex',
																				}}
																			>
																				<i
																					class='fa-solid fa-check'
																					style={{
																						fontSize: '18px',

																						color: ' #FFD700',
																					}}
																				></i>
																			</div>
																		)}
																	</div>
																	<div>
																		{item?.state === 'empty' ? (
																			<button
																				onClick={() =>
																					navigate(
																						`/viewProduct/${item?.product}`
																					)
																				}
																				class='btn  w-100 text-right btn-primary'
																				disabled
																			>
																				View Product
																			</button>
																		) : (
																			<button
																				onClick={() =>
																					navigate(
																						`/viewProduct/${item?.product}`
																					)
																				}
																				class='btn  w-100 text-right btn-primary'
																			>
																				View Product
																			</button>
																		)}
																	</div>
																</div>

																<div
																	class='modal fade'
																	id='exampleModal2'
																	tabindex='-1'
																	aria-labelledby='exampleModalLabel'
																	aria-hidden='true'
																>
																	<div class='modal-dialog w-100'>
																		<div class='modal-content w-100'>
																			<div class='modal-body w-100'>
																				<form
																					onSubmit={(e) => {
																						e.preventDefault();
																						updateSend(e.target);
																					}}
																				>
																					<div className='row  pt-4  align-items-center'>
																						<div className='col-md-2'>
																							<label
																								for='inputPassword6'
																								className='form-label'
																							>
																								width
																							</label>
																						</div>
																						<div className='col-md-10'>
																							<input
																								className='form-control'
																								name='width'
																								placeholder='Enter Size'
																							/>
																						</div>
																					</div>
																					<div className='row  pt-4  align-items-center'>
																						<div className='col-md-2'>
																							<label
																								for='inputPassword6'
																								className='form-label'
																							>
																								Height
																							</label>
																						</div>
																						<div className='col-md-10'>
																							<input
																								className='form-control'
																								name='height'
																								placeholder='Enter Size'
																							/>
																						</div>
																					</div>
																					<div className='row  pt-4  align-items-center'>
																						<div className='col-md-2'>
																							<label
																								for='inputPassword6'
																								className='form-label'
																							>
																								State
																							</label>
																						</div>
																						<div className='col-md-10'>
																							<select
																								class='form-control-input-2'
																								name='state'
																							>
																								<option
																									value=''
																									selected
																									disabled
																								>
																									---Select---
																								</option>
																								<option value='Occupied'>
																									Occupied
																								</option>
																								<option value='Empty'>
																									Empty
																								</option>
																							</select>
																						</div>
																					</div>

																					<div className='row'>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='button'
																								class='cancel-btn btn-lg'
																							>
																								Cancel
																							</button>
																						</div>
																						<div className='col-lg-6 col-md-12 py-3'>
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Save
																							</button>
																						</div>
																					</div>
																				</form>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>

									<div
										class='tab-pane fade'
										id='pills-Display'
										role='tabpanel'
										aria-labelledby='pills-Display-tab'
									>
										<h3 className='py-4'>
											Total Number of Racks: {racks?.length}
										</h3>
										<div className='row g-4'>
											{racks.map((item, index) => {
												return (
													<div className='col-md-3'>
														<div
															className='p-2'
															style={{
																backgroundColor: '#fff',
																border: ' 1px solid rgb(243, 239, 239)',

																borderRadius: '10px',
															}}
														>
															<div
																className='order'
																style={{
																	display: 'flex',
																	justifyContent: 'space-between',
																}}
															>
																<div
																	className=''
																	data-bs-toggle='modal'
																	data-bs-target='#exampleModal2'
																	onClick={() => setRackId(item?._id)}
																>
																	<h4>{`Ei${index + 1}`}</h4>
																</div>

																<div style={{ zIndex: '999' }}></div>
															</div>

															<div className='d-flex justify-content-between pt-3'>
																<p>{item?.state}</p>
																{item?.state === 'empty' ? (
																	<div
																		className=''
																		style={{
																			background: '#FEECDC',
																			width: '30px',
																			height: '30px',
																			lineHeight: '30px',
																			borderRadius: '50%',
																			justifyContent: 'center',
																			alignItems: 'center',
																			display: 'flex',
																		}}
																	>
																		<AiFillCloseCircle
																			size={20}
																			color='red'
																		/>
																	</div>
																) : (
																	<div
																		className=''
																		style={{
																			background: '#FEECDC',
																			width: '30px',
																			height: '30px',
																			lineHeight: '30px',
																			borderRadius: '50%',
																			justifyContent: 'center',
																			alignItems: 'center',
																			display: 'flex',
																		}}
																	>
																		<i
																			class='fa-solid fa-check'
																			style={{
																				fontSize: '18px',

																				color: ' #FFD700',
																			}}
																		></i>
																	</div>
																)}
															</div>
															<div>
																{item?.state === 'empty' ? (
																	<button
																		onClick={() =>
																			navigate(`/viewProduct/${item?.product}`)
																		}
																		class='btn  w-100 text-right btn-primary'
																		disabled
																	>
																		View Product
																	</button>
																) : (
																	<button
																		onClick={() =>
																			navigate(`/viewProduct/${item?.product}`)
																		}
																		class='btn  w-100 text-right btn-primary'
																	>
																		View Product
																	</button>
																)}
															</div>
														</div>

														<div
															class='modal fade'
															id='exampleModal2'
															tabindex='-1'
															aria-labelledby='exampleModalLabel'
															aria-hidden='true'
														>
															<div class='modal-dialog w-100'>
																<div class='modal-content w-100'>
																	<div class='modal-body w-100'>
																		<form
																			onSubmit={(e) => {
																				e.preventDefault();
																				updateSend(e.target);
																			}}
																		>
																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-2'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						width
																					</label>
																				</div>
																				<div className='col-md-10'>
																					<input
																						className='form-control'
																						name='width'
																						placeholder='Enter Size'
																					/>
																				</div>
																			</div>
																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-2'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						Height
																					</label>
																				</div>
																				<div className='col-md-10'>
																					<input
																						className='form-control'
																						name='height'
																						placeholder='Enter Size'
																					/>
																				</div>
																			</div>
																			<div className='row  pt-4  align-items-center'>
																				<div className='col-md-2'>
																					<label
																						for='inputPassword6'
																						className='form-label'
																					>
																						State
																					</label>
																				</div>
																				<div className='col-md-10'>
																					<select
																						class='form-control-input-2'
																						name='state'
																					>
																						<option value='Occupied'>
																							Occupied
																						</option>
																						<option value='Empty'>Empty</option>
																					</select>
																				</div>
																			</div>

																			<div className='row'>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='button'
																						class='cancel-btn btn-lg'
																					>
																						Cancel
																					</button>
																				</div>
																				<div className='col-lg-6 col-md-12 py-3'>
																					<button
																						type='submit'
																						class='add-last-btn btn-lg'
																					>
																						Save
																					</button>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(MainStockRacks);
