import React from 'react';
import Main4 from '../../../components/Main4';
import Main5 from '../../../components/Main5';
import withAuth from '../../../hooks/withAuth';
function DashMain() {
	return (
		<>
			<div
				className='inner p-3'
				style={{ transition: '0.5s !important' }}
			>
				<h5 className='text-bold'>Dashboard Overview</h5>
				<div className='row'>
					<div className='col-md-3'>
						<div
							style={{
								backgroundColor: '#FFFFE3',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: 'white',
								fontWeight: '500',
								borderRadius: '10px',
								height: '180px',
								marginTop: '10px',
								justifyContent: 'center',
								color: '#000000',
							}}
						>
							<h6>Total orders</h6>
							<h4>$0.0</h4>

							<p>Cash: Card: Credit:</p>
							<p>$0.0: $0.0: $0.0:</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							style={{
								backgroundColor: '#FFFFE3',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: '#fff',
								fontWeight: '500',
								borderRadius: '10px',
								height: '180px',
								marginTop: '10px',
								justifyContent: 'center',
								color: '#000000',
							}}
						>
							<h6>Orders Pending</h6>
							<h4>$0.0</h4>

							<p>Cash: Card: Credit:</p>
							<p>$0.0: $0.0: $0.0:</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							style={{
								backgroundColor: '#FFFFE3',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: 'white',
								fontWeight: '500',
								borderRadius: '10px',
								height: '180px',
								marginTop: '10px',
								justifyContent: 'center',
								color: '#000000',
							}}
						>
							<h6>Orders Processing</h6>
							<h4>$0.0</h4>

							<p>Cash: Card: Credit:</p>
							<p>$0.0: $0.0: $0.0:</p>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							style={{
								backgroundColor: '#FFFFE3',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								color: 'white',
								fontWeight: '500',
								borderRadius: '10px',
								height: '180px',
								marginTop: '10px',
								justifyContent: 'center',
								color: '#000000',
							}}
						>
							<h6>Orders Delivery</h6>
							<h4>$0.0</h4>

							<p>Cash: Card: Credit:</p>
							<p>$0.00: $0.00: $0.00:</p>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-md-3'>
						<div
							className='order py-3'
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: '#fff',
								alignItems: 'center',

								border: ' 1px solid rgb(243, 239, 239)',
								marginTop: '20px',
								borderRadius: '10px',
								color: '#000000',
							}}
						>
							<div
								className=''
								style={{
									background: '#FEECDC',
									width: '50px',
									height: '50px',
									lineHeight: '50px',
									borderRadius: '50%',
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<i
									class='fa-solid fa-cart-shopping'
									style={{
										fontSize: '15px',

										color: '#D03801',
									}}
								></i>
							</div>

							<div className='ps-3'>
								<p className='m-0'>Total order</p>
								<h4>39</h4>
							</div>
						</div>
					</div>
					<div className='col-md-3'>
						<div
							className='order py-3'
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: '#fff',
								alignItems: 'center',

								border: ' 1px solid rgb(243, 239, 239)',
								marginTop: '20px',
								borderRadius: '10px',
							}}
						>
							<div
								className=''
								style={{
									background: '#FEECDC',
									width: '50px',
									height: '50px',
									lineHeight: '50px',
									borderRadius: '50%',
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<i
									class='fa-solid fa-arrow-rotate-left'
									style={{
										fontSize: '20px',

										color: ' #FFD700',
									}}
								></i>
							</div>

							<div className='ps-3'>
								<p className='m-0'>Total order</p>
								<h4>39</h4>
							</div>
						</div>
					</div>

					<div className='col-md-3'>
						<div
							className='order py-3'
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: '#fff',
								alignItems: 'center',

								border: ' 1px solid rgb(243, 239, 239)',
								marginTop: '20px',
								borderRadius: '10px',
							}}
						>
							<div
								className=''
								style={{
									background: '#FEECDC',
									width: '50px',
									height: '50px',
									lineHeight: '50px',
									borderRadius: '50%',
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<i
									class='fa-solid fa-truck-moving'
									style={{
										fontSize: '20px',

										color: ' #FFD700',
									}}
								></i>
							</div>

							<div className='ps-3'>
								<p className='m-0'>Total order</p>
								<h4>39</h4>
							</div>
						</div>
					</div>
					<div className='col-md-3'>
						<div
							className='order py-3'
							style={{
								display: 'flex',
								justifyContent: 'center',
								backgroundColor: '#fff',
								alignItems: 'center',

								border: ' 1px solid rgb(243, 239, 239)',
								marginTop: '20px',
								borderRadius: '10px',
							}}
						>
							<div
								className=''
								style={{
									background: '#FEECDC',
									width: '50px',
									height: '50px',
									lineHeight: '50px',
									borderRadius: '50%',
									justifyContent: 'center',
									alignItems: 'center',
									display: 'flex',
								}}
							>
								<i
									class='fa-solid fa-check'
									style={{
										fontSize: '20px',

										color: ' #FFD700',
									}}
								></i>
							</div>

							<div className='ps-3'>
								<p className='m-0'>Total order</p>
								<h4>39</h4>
							</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-md-6'>
						<div style={{ marginTop: '40px' }}>
							<h6>Weekly Activity</h6>
							<Main4 />
						</div>
					</div>

					<div className='col-md-6'>
						<div style={{ marginTop: '40px' }}>
							<Main5 />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default withAuth(DashMain);
