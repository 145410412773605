import React, { useEffect, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import { AiOutlinePlus } from 'react-icons/ai';
import ApiUrl from '../BaseUrl';

import {
	addPackageQC,
	addPackageSeller,
	getPackageSeller,
	getPackageQC,
	delPackageQCById,
	delPackageSellerById,
} from '../ApiEndpoint';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Modal, Radio } from 'antd';
import $ from 'jquery';
import 'jquery/dist/jquery.min.js';
import 'datatables.net-dt/js/dataTables.dataTables';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons/js/dataTables.buttons.js';
import 'datatables.net-buttons/js/buttons.colVis.js';
import 'datatables.net-buttons/js/buttons.flash.js';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import withAuth from '../../../hooks/withAuth';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schemaQualityController = yup.object().shape({
	title: yup.string().required('Title is required'),
	storePrice: yup.string().required('Store Price is required'),
	mainStockPrice: yup.string().required('Main Stock Price is required'),
	packageName: yup.string().required('Package Name is required'),
	minimumStore: yup.string().required('Minimum Store Created is required'),
	type: yup.string().required('Type is required'),
});

const schemaSeller = yup.object().shape({
	title: yup.string().required('Title is required'),
	storePrice: yup.string().required('Store Price is required'),
	packageName: yup.string().required('Package Name is required'),
	minimumStore: yup.string().required('Minimum Store Created is required'),
	type: yup.string().required('Type is required'),
});

function Packages() {
	const {
		register: registerQualityController,
		handleSubmit: handleQualityControllerSubmit,
		formState: { errors: errorsQualityController },
		reset: resetQualityController,
	} = useForm({
		resolver: yupResolver(schemaQualityController),
	});

	const {
		register: registerSeller,
		handleSubmit: handleSellerSubmit,
		formState: { errors: errorsSeller },
		reset: resetSeller,
	} = useForm({
		resolver: yupResolver(schemaSeller),
	});

	const [sellerPackage, setSellerPackage] = useState([]);
	const [qualityControllerPackage, setQualityControllerPackage] = useState([]);

	const fetchData = async () => {
		const result = await fetch(getPackageSeller);
		const sellerPackage = await result.json();

		console.log('P', sellerPackage);
		setSellerPackage(sellerPackage);
	};
	const fetchQCData = async () => {
		const result = await fetch(getPackageQC);
		const QCPackage = await result.json();

		setQualityControllerPackage(QCPackage);
	};

	useEffect(() => {
		fetchData();
		fetchQCData();
	}, []);

	const onSubmitQC = async (data) => {
		// console.log('endpoint', addPackageQC);
		// console.log('QC Data:', data);
		// resetQualityController();

		try {
			const response = await fetch(addPackageQC, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added packege for QC succesfull ');
				const productData = await response.json();
				console.log('Product data:', productData);
				toast.success('Package Added');
				resetQualityController();
				fetchQCData();
			} else {
				console.error(' failed');
				toast.error(' Addition failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}
	};
	const onSubmitSeller = async (data) => {
		try {
			const response = await fetch(addPackageSeller, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			});
			if (response.ok) {
				console.log('Added packege for seller succesfull ');
				const productData = await response.json();
				console.log('Product data:', productData);
				toast.success('Package Added');
				resetSeller();
				fetchData();
			} else {
				console.error(' failed');
				toast.error(' Addition failed');
			}
		} catch (error) {
			console.error('Error during product category:', error);
		}
	};
	const [checkValue, setCheckValue] = useState(false);
	const className = 'offcanvas offcanvas-end offcanvas-backdrop.show';
	const changeClassName =
		'offcanvas offcanvas-end d-none  offcanvas-backdrop.show2';
	const [packagesSingle, setPackagesSingle] = useState({});
	const [sellerid, setSellerID] = useState([]);
	const updateData = (values) => {};
	const onDeleteSeller = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delPackageSellerById}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('seller deleted successful');
						toast.success('deleted successful');
						fetchData();
					} else {
						console.log('seller deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};

	const onDeleteQC = (id) => {
		Modal.confirm({
			title: 'Are you sure you want to delete?',
			onOk: () => {
				fetch(`${delPackageQCById}/${id}`, {
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
					},
				}).then((res) => {
					console.log('res', res);
					if (res.ok) {
						console.log('QC deleted successful');
						toast.success('deleted successful');
						fetchQCData();
					} else {
						console.log('QC deleted failed');
						toast.error(' deletetion failed');
					}
				});
			},
		});
	};
	useEffect(() => {
		if (!$.fn.DataTable.isDataTable('#table')) {
			$(document).ready(function () {
				setTimeout(function () {
					$('#table').DataTable({
						pagingType: 'full_numbers',
						bDestroy: true,
						pageLength: 20,
						processing: true,
						dom: 'Bfrtip',
						select: {
							style: 'single',
						},

						buttons: [
							{
								extend: 'csv',
								className: 'btn btn-warning bg-warning',
							},
						],

						fnRowCallback: function (
							nRow,
							aData,
							iDisplayIndex,
							iDisplayIndexFull
						) {
							var index = iDisplayIndexFull + 1;
							$('td:first', nRow).html(index);
							return nRow;
						},

						lengthMenu: [
							[10, 20, 30, 50, -1],
							[10, 20, 30, 50, 'All'],
						],
						columnDefs: [
							{
								targets: 0,
								render: function (data, type, row, meta) {
									return type === 'export' ? meta.row + 1 : data;
								},
							},
						],
					});
				}, 1000);
			});
		}
	});
	const [toggle, setToggle] = useState({
		id: '',
	});

	return (
		<>
			<div>
				<header>
					<div className='bg-theme'>
						<div className='main-wrapper'>
							<ToastContainer />
							<div className='container-fluid py-4'>
								<h5 className='kk ps-3'>Manage Our Packages</h5>

								<div className='min-box  border'>
									<div className='products-actions d-flex p-4'>
										<div className='imort-product '>
											<div className='btn-product d-flex'></div>
										</div>

										<div className='action-btn'>
											<div className='actions d-flex'>
												<div className='Bulk-btn'>
													{/* Add QC packages */}
													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Our Packages Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={handleQualityControllerSubmit(
																			onSubmitQC
																		)}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Title
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='title'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Title'
																					{...registerQualityController(
																						'title'
																					)}
																				/>
																				{errorsQualityController.title && (
																					<p className='text-danger'>
																						{
																							errorsQualityController.title
																								.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Store Charges
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='storePrice'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Store Charges'
																					{...registerQualityController(
																						'storePrice'
																					)}
																				/>
																				{errorsQualityController.storePrice && (
																					<p className='text-danger'>
																						{
																							errorsQualityController.storePrice
																								.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Main Stock Charges
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='mainStockPrice'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Main Stock Charges'
																					{...registerQualityController(
																						'mainStockPrice'
																					)}
																				/>
																				{errorsQualityController.mainStockPrice && (
																					<p className='text-danger'>
																						{
																							errorsQualityController
																								.mainStockPrice.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Package Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='packageName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Package Name'
																					{...registerQualityController(
																						'packageName'
																					)}
																				/>
																				{errorsQualityController.packageName && (
																					<p className='text-danger'>
																						{
																							errorsQualityController
																								.packageName.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Minimum Store Created
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='minimumStore'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Minimum Store Cretaed by Quality Controller'
																					{...registerQualityController(
																						'minimumStore'
																					)}
																				/>
																				{errorsQualityController.minimumStore && (
																					<p className='text-danger'>
																						{
																							errorsQualityController
																								.minimumStore.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Type
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					name='type'
																					class='form-control-input-2'
																					aria-label='Default select example'
																					{...registerQualityController('type')}
																				>
																					<option value='In City'>
																						In City
																					</option>
																					<option value='National'>
																						National
																					</option>

																					<option value='International'>
																						International
																					</option>
																				</select>

																				{errorsQualityController.type && (
																					<p className='text-danger'>
																						{
																							errorsQualityController.type
																								.message
																						}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add Package
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add Package
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													{/* Add seller package */}
													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='selleroffcanvasRight'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Our Packages Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={handleSellerSubmit(
																			onSubmitSeller
																		)}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Title
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='title'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Title'
																					{...registerSeller('title')}
																				/>
																				{errorsSeller.title && (
																					<p className='text-danger'>
																						{errorsSeller.title.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Store Charges
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='storePrice'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Store Charges'
																					{...registerSeller('storePrice')}
																				/>
																				{errorsSeller.storePrice && (
																					<p className='text-danger'>
																						{errorsSeller.storePrice.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Package Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='packageName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Package Name'
																					{...registerSeller('packageName')}
																				/>
																				{errorsSeller.packageName && (
																					<p className='text-danger'>
																						{errorsSeller.packageName.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Minimum Store Created
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='minimumStore'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Minimum Store Cretaed by Seller'
																					{...registerSeller('minimumStore')}
																				/>
																				{errorsSeller.minimumStore && (
																					<p className='text-danger'>
																						{errorsSeller.minimumStore.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Type
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					name='type'
																					class='form-control-input-2'
																					aria-label='Default select example'
																					{...registerSeller('type')}
																				>
																					<option value='In City'>
																						In City
																					</option>
																					<option value='National'>
																						National
																					</option>

																					<option value='International'>
																						International
																					</option>
																				</select>
																				{errorsSeller.type && (
																					<p className='text-danger'>
																						{errorsSeller.type.message}
																					</p>
																				)}
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																							data-bs-dismiss='offcanvas'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add Package
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Add Package
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>

														{/* Canvas */}
													</div>

													<div
														className={
															!checkValue ? className : changeClassName
														}
														tabIndex={-1}
														id='offcanvasRightupdate'
														aria-labelledby='offcanvasRightLabel'
													>
														<div className='offcanvas-header px-4'>
															<div className='content-header'>
																<h4>Manage Our Packages Create / Update</h4>
																<p>
																	Update products info, combinations and extras.
																</p>
															</div>

															<button
																type='button'
																className='btn-close bg-white shadow rounded-5 text-reset'
																data-bs-dismiss='offcanvas'
																aria-label='Close'
																style={{
																	width: '5px',
																	height: '30px',
																	opacity: '0.8',
																	fontSize: '12px',
																}}
															/>
														</div>
														{/* Canvas */}
														<div className='offcanvas-body p-0'>
															<div className='form-data'>
																<div className='wrap-data p-5'>
																	<form
																		onSubmit={(e) => {
																			e.preventDefault();
																			updateData(e.target);
																		}}
																	>
																		<div className='row g-4 pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Title
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='title'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Title'
																					defaultValue={packagesSingle?.title}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					store Price
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='minimumStore'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter store price'
																					defaultValue={
																						packagesSingle?.storePrice
																					}
																				/>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Package for
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					name='packageFor'
																					class='form-control-input-2'
																					aria-label='Default select example'
																					defaultValue={
																						packagesSingle?.packageFor
																					}
																				>
																					<option value='Ambassador'>
																						Ambassador
																					</option>
																					<option value='Seller'>Seller</option>
																				</select>
																			</div>
																		</div>
																		<div className='row pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Package Name
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<input
																					type='text'
																					name='packageName'
																					className='form-control-input'
																					aria-labelledby='passwordHelpInline'
																					placeholder='Enter Package Name'
																					defaultValue={
																						packagesSingle?.packageName
																					}
																				/>
																			</div>
																		</div>

																		<div className='row  pt-4  align-items-center'>
																			<div className='col-md-4'>
																				<label
																					for='inputPassword6'
																					className='form-label'
																				>
																					Type
																				</label>
																			</div>
																			<div className='col-md-8'>
																				<select
																					name='type'
																					class='form-control-input-2'
																					aria-label='Default select example'
																					defaultValue={packagesSingle?.type}
																				>
																					<option value='In City'>
																						In City
																					</option>
																					<option value='National'>
																						National
																					</option>

																					<option value='International'>
																						International
																					</option>
																				</select>
																			</div>
																		</div>

																		<div className='offcanvas-footer px-5 py-3'>
																			<div className='content-footer'>
																				<div className='row'>
																					<div className='col-lg-6 col-md-12 py-3'>
																						<button
																							type='button'
																							class='cancel-btn btn-lg'
																						>
																							Cancel
																						</button>
																					</div>
																					<div className='col-lg-6 col-md-12 py-3'>
																						{checkValue ? (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																								data-bs-dismiss='offcanvas'
																							>
																								Save
																							</button>
																						) : (
																							<button
																								type='submit'
																								class='add-last-btn btn-lg'
																							>
																								Save
																							</button>
																						)}
																					</div>
																				</div>
																			</div>
																		</div>
																	</form>
																</div>
															</div>
														</div>
														{/* Canvas */}
													</div>
												</div>

												{/* Modal-Del */}

												<button
													className='add-btn mx-3'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'>Add QC Packages</span>
												</button>
												<button
													className='add-btn'
													type='button'
													data-bs-toggle='offcanvas'
													data-bs-target='#selleroffcanvasRight'
													aria-controls='offcanvasRight'
												>
													<AiOutlinePlus />
													<span className='px-1'>Add seller package</span>
												</button>

												<div
													className='offcanvas offcanvas-end w-75'
													tabIndex={-1}
													id='offcanvasRight'
													aria-labelledby='offcanvasRightLabel'
												>
													<div className='offcanvas-header px-4'>
														<div className='content-header'>
															<h4>Add Products</h4>
															<p>Add products info, combinations and extras.</p>
														</div>

														<button
															type='button'
															className='btn-close text-reset'
															data-bs-dismiss='offcanvas'
															aria-label='Close'
														/>
													</div>

													<div className='offcanvas-body p-0'>
														<div className='form-data'>
															<div className='wrap-data p-5'>
																<form>
																	<div className='row  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Title/Name
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Title/Name'
																			/>
																		</div>
																	</div>
																	<div className='row py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Description
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div className='form-floating'>
																				<textarea
																					className='form-control-input'
																					placeholder='Product Description'
																					id='floatingTextarea2'
																					style={{ height: 100 }}
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Images
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div className='form-floating'>
																				<input
																					className='form-control-input'
																					type='file'
																					id='formFile'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row py-3 align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product SKU
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product SKU'
																			/>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Barcode
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Barcode'
																			/>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Category
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option selected>
																					Select Category
																				</option>
																				<option value='1'>
																					No Option/ Available
																				</option>
																			</select>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Default Category
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<select
																				class='form-control-input-2'
																				aria-label='Default select example'
																			>
																				<option selected>
																					Default Category
																				</option>
																				<option value='1'>
																					No Option/ Available
																				</option>
																			</select>
																		</div>
																	</div>
																	<div className='row py-3   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Price
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div class='input-group flex-nowrap'>
																				<span
																					class='input-group-text'
																					id='addon-wrapping'
																				>
																					$
																				</span>
																				<input
																					type='number'
																					class='form-control-input-3'
																					placeholder='0'
																					aria-label='Username'
																					aria-describedby='addon-wrapping'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row   align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Sale Price
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<div class='input-group flex-nowrap'>
																				<span
																					class='input-group-text'
																					id='addon-wrapping'
																				>
																					$
																				</span>
																				<input
																					type='number'
																					class='form-control-input-3'
																					placeholder='0'
																					aria-label='Username'
																					aria-describedby='addon-wrapping'
																				/>
																			</div>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Quantity
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Quantity'
																			/>
																		</div>
																	</div>
																	<div className='row    align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Slug
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Slug'
																			/>
																		</div>
																	</div>
																	<div className='row  py-3  align-items-center'>
																		<div className='col-md-4'>
																			<label
																				for='inputPassword6'
																				className='form-label'
																			>
																				Product Tag
																			</label>
																		</div>
																		<div className='col-md-8'>
																			<input
																				type='password'
																				id='inputPassword6'
																				className='form-control-input'
																				aria-labelledby='passwordHelpInline'
																				placeholder='Product Tag(Write then press enter to add new tag)'
																			/>
																		</div>
																	</div>
																</form>
															</div>
														</div>
													</div>
													<div className='offcanvas-footer px-5 py-3'>
														<div className='content-footer'>
															<div className='row'>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='cancel-btn btn-lg'
																	>
																		Cancelllllll
																	</button>
																</div>
																<div className='col-lg-6 col-md-12 py-3'>
																	<button
																		type='button'
																		class='add-last-btn btn-lg'
																	>
																		{' '}
																		Add Products
																	</button>
																</div>
															</div>
														</div>
													</div>
													{/* Canvas */}
												</div>
											</div>
										</div>
									</div>
								</div>

								<ul
									className='nav nav-pills mb-3 d-flex justify-content-start'
									id='pills-tab'
									role='tablist'
								>
									<li
										className='nav-item mx-1'
										role='presentation'
									>
										<button
											className='nav-link active'
											id='pills-home-tab'
											data-bs-toggle='pill'
											data-bs-target='#ambassadorPkgList'
											type='button'
											role='tab'
											aria-controls='pills-home'
											aria-selected='true'
										>
											Quality Controller Packages
										</button>
									</li>
									<li
										className='nav-item mx-1'
										role='presentation'
									>
										<button
											className='nav-link'
											id='pills-profile-tab'
											data-bs-toggle='pill'
											data-bs-target='#sellerPkgList'
											type='button'
											role='tab'
											aria-controls='pills-profile'
											aria-selected='false'
										>
											Seller Packages
										</button>
									</li>
								</ul>

								<div
									className='tab-content'
									id='pills-tabContent'
								>
									<div
										className='tab-pane fade show active'
										id='ambassadorPkgList'
										role='tabpanel'
										aria-labelledby='pills-home-tab'
										tabindex='0'
									>
										<div className='min-box my-4'>
											<div className='products-table'>
												<table
													className='table border rounded align-middle mt-5'
													style={{
														borderColor: '#4f4f51',
													}}
													id='table'
												>
													<thead className='bg-light'>
														<tr>
															<th style={{ width: '50px' }}>#ID</th>
															<th style={{ width: '140px' }}>Title</th>
															<th style={{ width: '130px' }}>Store Charges</th>
															<th style={{ width: '180px' }}>
																Main Stock Charges
															</th>
															<th style={{ width: '80px' }}>Type</th>

															<th style={{ width: '130px' }}>Minimum Store</th>
															<th>Action</th>
														</tr>
													</thead>

													<tbody>
														{qualityControllerPackage?.map((item, index) => {
															return (
																<>
																	<tr>
																		<td>{index + 1}</td>
																		<td>{item?.title}</td>
																		<td>${item?.storeCharges}</td>
																		<td>${item?.mainStockCharges}</td>
																		<td>{item?.type}</td>
																		<td>{item?.minimumStoreCreated}</td>
																		<td>
																			<div className='actions d-flex'>
																				<span
																					onClick={() => {
																						setSellerID(item?._id);

																						axios
																							.get(
																								`${ApiUrl}/packages/getById?id=` +
																									item?._id
																							)
																							.then((res) => {
																								console.log(
																									res.data,
																									'packages'
																								);
																								setPackagesSingle(res.data);
																							});
																					}}
																					data-bs-toggle='offcanvas'
																					data-bs-target='#offcanvasRightupdate'
																					aria-controls='offcanvasRightupdate'
																					style={{ fontSize: 21 }}
																				>
																					<FiEdit />
																				</span>
																				<span
																					onClick={() => {
																						onDeleteQC(item?._id);
																					}}
																					style={{ fontSize: 21 }}
																					className='px-3'
																				>
																					<RiDeleteBinLine />
																				</span>
																			</div>
																		</td>
																	</tr>
																</>
															);
														})}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div
										className='tab-pane fade'
										id='sellerPkgList'
										role='tabpanel'
										aria-labelledby='pills-profile-tab'
										tabindex='0'
									>
										<div className='min-box my-4'>
											<div className='products-table'>
												<table
													className='table border rounded align-middle mt-5'
													style={{
														borderColor: '#4f4f51',
													}}
													id='table'
												>
													<thead className='bg-light'>
														<tr>
															<th>#ID</th>
															<th>Title</th>
															<th>Type</th>
															<th>Store Charges</th>
															<th>Minimum Store</th>
															<th>Action</th>
														</tr>
													</thead>

													<tbody>
														{sellerPackage?.map((item, index) => (
															<tr key={index}>
																<td>{index + 1}</td>
																<td>{item?.title}</td>
																<td>{item?.type}</td>
																<td>${item?.storeCharges}</td>
																<td>{item?.minimumStoreCreated}</td>
																<td>
																	<div className='actions d-flex'>
																		<span
																			onClick={() => {
																				setSellerID(item?._id);
																				axios
																					.get(
																						`${ApiUrl}/packages/getById?id=${item?._id}`
																					)
																					.then((res) => {
																						console.log(res.data, 'packages');
																						setPackagesSingle(res.data);
																					});
																			}}
																			data-bs-toggle='offcanvas'
																			data-bs-target='#offcanvasRightupdate'
																			aria-controls='offcanvasRightupdate'
																			style={{ fontSize: 21 }}
																		>
																			<FiEdit />
																		</span>
																		<span
																			onClick={() => {
																				onDeleteSeller(item?._id);
																			}}
																			style={{ fontSize: 21 }}
																			className='px-3'
																		>
																			<RiDeleteBinLine />
																		</span>
																	</div>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</header>
			</div>
		</>
	);
}

export default withAuth(Packages);
