// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import ApiUrl from '../Pages/BaseUrl';
// import { ToastContainer, toast } from 'react-toastify';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';

// const schema = yup.object().shape({
// 	email: yup
// 		.string()
// 		.required('Email is required')
// 		.email('Invalid email address'),
// 	password: yup.string().required('Password is required'),
// });

// function Login() {
// 	const navigate = useNavigate();
// 	const {
// 		register,
// 		handleSubmit,
// 		formState: { errors },
// 		reset,
// 	} = useForm({
// 		resolver: yupResolver(schema),
// 	});

// 	const onSubmit = async (data) => {
// 		try {
// 			const response = await fetch('http://165.22.215.169:80/admin', {
// 				method: 'POST',
// 				headers: {
// 					'Content-Type': 'application/json',
// 				},
// 				body: JSON.stringify(data),
// 			});
// 			if (response.ok) {
// 				console.log('Login successful');
// 				const user = await response.json();
// 				localStorage.setItem('adminuser', JSON.stringify(user));
// 				console.log('User:', user);
// 				window.location.replace('/#/dashboard');
// 			} else {
// 				console.error('Login failed');
// 				toast.error('Login failed');
// 			}
// 			reset(); // Reset the form after successful submission (optional)
// 		} catch (error) {
// 			console.error('Error during login:', error);
// 		}
// 		// console.log('Data:', data);
// 		// reset();
// 	};

// 	return (
// 		<>
// 			<div className='container'>
// 				<ToastContainer />
// 				<div className='row'>
// 					<div className='col-md-6'>
// 						<div className='login3'>
// 							<img
// 								src={require('../../assets/images/jack.jpeg')}
// 								style={{
// 									width: '100%',
// 									height: '100%',
// 									width: 'auto',
// 									height: 'auto',
// 									borderTopLeftRadius: '10px',
// 									borderBottomLeftRadius: '10px',
// 								}}
// 							/>
// 						</div>
// 					</div>
// 					<div className='col-md-6'>
// 						<section className='login2'>
// 							<form
// 								className='login'
// 								onSubmit={handleSubmit(onSubmit)}
// 							>
// 								<h5 style={{ marginBottom: '20px' }}>Login</h5>
// 								<div className='form'>
// 									<label style={{ fontSize: '12px' }}>Email</label>
// 									<br />
// 									<input
// 										type='email'
// 										id='email'
// 										className='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '10px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='john@due.com'
// 										{...register('email')}
// 									/>
// 									{errors.email && (
// 										<p style={{ color: 'red' }}>{errors.email.message}</p>
// 									)}

// 									<label style={{ fontSize: '12px' }}>Password</label>
// 									<br />
// 									<input
// 										type='password'
// 										id='password'
// 										className='form-control no-focus'
// 										style={{
// 											width: '95%',
// 											marginBottom: '30px',
// 											backgroundColor: '#fff',
// 										}}
// 										placeholder='********'
// 										{...register('password')}
// 									/>
// 									{errors.password && (
// 										<p style={{ color: 'red' }}>{errors.password.message}</p>
// 									)}

// 									<button
// 										class='btn btn-success'
// 										type='submit'
// 										style={{
// 											fontSize: '13px',
// 											width: '95%',
// 											backgroundColor: '#FFD700',
// 											borderColor: '#FFD700',
// 											marginTop: '-20px',
// 											color: '#000',
// 										}}
// 									>
// 										Login
// 									</button>
// 								</div>
// 							</form>
// 						</section>
// 					</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// }

// export default Login;



// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { ToastContainer, toast } from 'react-toastify';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { FiMail, FiLock } from 'react-icons/fi';
// import { FaAsymmetrik } from "react-icons/fa";
// import { MdOutlineCompare } from "react-icons/md";
// import StyledInput from '../../Input/Style'; // Import StyledInput component
// import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS
// import { GiEvilComet } from 'react-icons/gi';

// const schema = yup.object().shape({
//   email: yup.string().required('Email is required').email('Invalid email address'),
//   password: yup.string().required('Password is required'),
// });

// function Login() {
//   const navigate = useNavigate();
//   const {
//     register,
//     handleSubmit,
//     formState: { errors },
//     reset,
//   } = useForm({
//     resolver: yupResolver(schema),
//   });

//   const onSubmit = async (data) => {
//     try {
//       const response = await fetch('http://165.22.215.169:80/admin', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(data),
//       });
//       if (response.ok) {
//         console.log('Login successful');
//         const user = await response.json();
//         localStorage.setItem('adminuser', JSON.stringify(user));
//         console.log('User:', user);
//         window.location.replace('/#/dashboard');
//       } else {
//         console.error('Login failed');
//         toast.error('Login failed');
//       }
//       reset(); // Reset the form after successful submission (optional)
//     } catch (error) {
//       console.error('Error during login:', error);
//     }
//   };

//   return (
//     <>
//       <div className="container">
//         <ToastContainer />
//         <div className="row">
//           <div className="col-md-6">
//             <div className="login3">
//               <img
//                 src={require('../../assets/images/jack.jpeg')}
//                 style={{
//                   width: '100%',
//                   height: '100%',
//                   width: 'auto',
//                   height: 'auto',
//                   borderTopLeftRadius: '10px',
//                   borderBottomLeftRadius: '10px',
//                 }}
//               />
//             </div>
//           </div>
//           <div className="col-md-6">
//             <section className="login2"  style={{ width:"90%" , height:"100vh"}}>
//               <form className="login" onSubmit={handleSubmit(onSubmit)}  style={{padding:"1rem"}}>
//                 <h1 style={{transform:"rotate(180deg)", textAlign:"right", fontSize:"3.8rem", fontWeight:"700", marginBottom:"3rem"}}>L</h1>
//                 <h5 style={{ marginBottom: '2px' }}>Welecome back,</h5>
//                 <p style={{color:"black"}}>A few step to communicate with us </p>
//                 <div className="form" style={{display:"flex",flexDirection:"column", gap:"2rem", marginTop:"1rem"}}>
//                   <StyledInput
//                     type="email"
//                     placeholderText="E-Mail"
//                     // Icon={FaAsymmetrik}
//                     Icon={GiEvilComet            }
//                     register={register('email')}
//                     error={errors.email}
//                   />
//                   <StyledInput
//                     type="password"
//                     placeholderText="Password"
//                     Icon={MdOutlineCompare}
//                     register={register('password')}
//                     error={errors.password}
//                   />
//                   <button
//                     className="btn btn-success"
//                     type="submit"
//                     style={{
//                       fontSize: '13px',
//                       width: '95%',
//                       backgroundColor: '#FFD700',
//                       borderColor: '#FFD700',
//                       marginTop: '0px',
//                       color: '#000',
//                     }}
//                   >
//                     Login
//                   </button>
//                 </div>
//               </form>
//             </section>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Login;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { MdOutlineCompare } from "react-icons/md";
import StyledInput from '../../Input/Style'; // Import StyledInput component
import 'react-toastify/dist/ReactToastify.css'; // Import React Toastify CSS

import { TbArrowAutofitRight } from "react-icons/tb";

import './Login.css'; // Import the CSS file

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email address'),
  password: yup.string().required('Password is required'),
});

function Login() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const response = await fetch('http://165.22.215.169:80/admin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        console.log('Login successful');
        const user = await response.json();
        localStorage.setItem('adminuser', JSON.stringify(user));
        console.log('User:', user);
        window.location.replace('/#/dashboard');
      } else {
        console.error('Login failed');
        toast.error('Login failed');
      }
      reset(); // Reset the form after successful submission (optional)
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <>
      <div className="containers">
        <ToastContainer />
        <div className="rows">
          <div className="col-md-6">
            <div className="login3">
              <img
                src='login2.avif'
                alt="Login Illustration"
              />
            </div>
          </div>
          <div className="col-md-6">
            <section className="login2" style={{boxShadow:"0 !important", marginLeft:"-20%"}}>
              {/* <div className="circle">
                <h1>L</h1>
              </div> */}
              <form className="login" onSubmit={handleSubmit(onSubmit)}>
                <h1>L</h1>
                <h5>Welcome back,</h5>
                <p >A few steps to communicate with us</p>
                <div className="form">
                  <StyledInput
                    type="email"
                    id ="email"
                    placeholderText="E-Mail"
                    Icon={TbArrowAutofitRight}
                    register={register('email')}
                    error={errors.email}
                  />
                  <StyledInput
                    type="password"
                    id = "password"
                    placeholderText="Password"
                    Icon={MdOutlineCompare}
                    register={register('password')}
                    error={errors.password}
                  />
                  <button className="btn btn-success" type="submit">
                    Login
                  </button>
                </div>
              </form>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;

