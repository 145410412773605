import { FiEdit } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';
import axios from 'axios';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import ApiUrl from '../../BaseUrl';
import { useEffect, useState } from 'react';

//main function
const BannerTable = () => {
	const [banners, setBanners] = useState();

	// const onDeleteStudent = (id) => {
	//   Modal.confirm({
	//     title: "Are you sure you want to delete?",
	//     onOk: () => {
	//       axios.delete(`${ApiUrl}/category/deleteByID?id=` + id).then((res) => {
	//         toast("Category deleted successfully!");

	//         axios.get(`${ApiUrl}/category/getAll`).then((res) => {
	//           setStocks([...res.data]);
	//         });
	//       });
	//     },
	//   });
	// };
	return (
		<div className='min-box my-4'>
			<div className='products-table'>
				<table
					className='table border rounded align-middle mt-5'
					style={{ borderColor: '#4f4f51' }}
					id='table'
				>
					<thead className='bg-light'>
						<tr>
							<th>Id #</th>
							<th>Title</th>
							<th>Banner Image</th>
							<th>ACTIONS</th>
						</tr>
					</thead>
					<tbody>
						{banners?.map((item, index) => {
							return (
								<>
									<tr key={index}>
										<td>{index + 1}</td>
										<td>{item?.title}</td>
										<td>
											<div className='prod-img-banner'>
												<img src={`${ApiUrl}/images/${item?.img}`} />
											</div>
										</td>

										<td>
											<div className='actions d-flex'>
												<span
													// onClick={() => {
													//   setSellerID(item?._id);
													//   axios.get(`${ApiUrl}/category/getById?id=` + item?._id).then((res) => {
													//     console.log(res.data, "singleStock");

													//     setSingleStock(res.data);
													//   });
													// }}
													data-bs-toggle='offcanvas'
													data-bs-target='#offcanvasRightupdate'
													aria-controls='offcanvasRightupdate'
													style={{ fontSize: 21 }}
												>
													<FiEdit />
												</span>

												<span
													// onClick={() => {
													//   onDeleteStudent(item?._id);
													// }}
													style={{ fontSize: 21 }}
													className='px-3'
												>
													<RiDeleteBinLine />
												</span>
											</div>
										</td>
									</tr>
								</>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default BannerTable;
